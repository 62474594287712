import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AnimalTemporaryBalanceRoutingModule } from "./animal-temporary-balance-routing.module";
import { AnimalTemporaryBalanceIndexComponent } from "./animal-temporary-balance-index/animal-temporary-balance-index.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { NgxDropzoneModule } from "ngx-dropzone";
import { SharedModule } from "src/app/components/shared/shared.module";
import { DirectivesModule } from "src/app/directives/Directives.module";
import { AnimalTemporaryBalanceFormsComponent } from "./components/animal-temporary-balance-forms/animal-temporary-balance-forms.component";
import { AnimalTemporaryBalanceCreateComponent } from "./components/animal-temporary-balance-create/animal-temporary-balance-create.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AnimalTemporaryBalanceListComponent } from "./components/animal-temporary-balance-list/animal-temporary-balance-list.component";
import { AnimalTemporaryBalanceAgeGroupsComponent } from "./components/animal-temporary-balance-age-groups/animal-temporary-balance-age-groups.component";
import { AnimalTemporaryBalanceEditComponent } from "./components/animal-temporary-balance-edit/animal-temporary-balance-edit.component";
import { MatSortModule } from "@angular/material/sort";

@NgModule({
  declarations: [
    AnimalTemporaryBalanceIndexComponent,
    AnimalTemporaryBalanceFormsComponent,
    AnimalTemporaryBalanceCreateComponent,
    AnimalTemporaryBalanceListComponent,
    AnimalTemporaryBalanceAgeGroupsComponent,
    AnimalTemporaryBalanceEditComponent,
  ],
  imports: [
    CommonModule,
    AnimalTemporaryBalanceRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    NgxDropzoneModule,
    MatTabsModule,
    MatChipsModule,
    MatPaginatorModule,
    MatDividerModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatSortModule,
  ],
})
export class AnimalTemporaryBalanceModule {}
