import { Component } from "@angular/core";

@Component({
  selector: "app-integration-index",
  templateUrl: "./integration-index.component.html",
  styleUrls: ["./integration-index.component.scss"],
})
export class IntegrationIndexComponent {
  constructor() {}
}
