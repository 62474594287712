import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductionValuesModule } from './pages/commercial/production-values/production-values.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommercielSellingModule } from './pages/commercial/commerciel-selling/commerciel-selling.module';
import { OperationIntegrationModule } from './pages/operation-integration/operation-integration.module';
import { ReportsModule } from './pages/reports/reports.module';
import { CepPipe } from './pipes/masks/cep/cep.pipe';
import { AnimalTemporaryBalanceModule } from './pages/animal-temporary-balance/animal-temporary-balance.module';
import { PastureManegementModule } from './pages/pasture-manegement/pasture-manegement.module';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

registerLocaleData(localePt, "pt");

@NgModule({
  declarations: [AppComponent, CepPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMomentDateModule,
    OperationIntegrationModule,
    ReportsModule,
    CommercielSellingModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ProductionValuesModule,
    AnimalTemporaryBalanceModule,
    PastureManegementModule,
    MatChipsModule

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
  ],
  exports: [  MatNativeDateModule,
    MatDatepickerModule,],
  bootstrap: [AppComponent],
})
export class AppModule {}
