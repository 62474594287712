<!-- <mat-card>
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="variableCost">
      <th mat-header-cell *matHeaderCellDef> Custos Variáveis</th>
      <td mat-cell *matCellDef="let element"> {{element.variableCost | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="aquisitionCost">
      <th mat-header-cell *matHeaderCellDef> Custos de Compras</th>
      <td mat-cell *matCellDef="let element"> {{element.aquisitionCost | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="revenueSelling">
      <th mat-header-cell *matHeaderCellDef> Receita Venda</th>
      <td mat-cell *matCellDef="let element"> {{element.revenueSelling | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="revenueStock">
      <th mat-header-cell *matHeaderCellDef> Receita Estoque</th>
      <td mat-cell *matCellDef="let element"> {{element.revenueStock | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="MC1">
      <th mat-header-cell *matHeaderCellDef> Margem de Contribuição 1</th>
      <td mat-cell *matCellDef="let element"> {{element.MC1 | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="fixedCost">
      <th mat-header-cell *matHeaderCellDef> Custos Fixos</th>
      <td mat-cell *matCellDef="let element"> {{element.fixedCost | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="MC2">
      <th mat-header-cell *matHeaderCellDef> Margem de Contribuição 2</th>
      <td mat-cell *matCellDef="let element"> {{element.MC2 | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="financialExpense">
      <th mat-header-cell *matHeaderCellDef> Despesas Financeiras</th>
      <td mat-cell *matCellDef="let element"> {{element.financialExpense | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="MC3">
      <th mat-header-cell *matHeaderCellDef> Margem de Contribuição 3</th>
      <td mat-cell *matCellDef="let element"> {{element.MC3 | currency: 'BRL':true}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedProcessDatas"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedProcessDatas;"></tr>
  </table>
</mat-card> -->

<mat-card class="aj-tb">
  <table>
    <tr>
      <th> Custos Variáveis </th>
      <th> Custos de Compras </th>
      <th> Receita Venda </th>
      <th> Receita Estoque </th>
      <th> Margem de Contribuição 1 </th>
      <th> Custos Fixos </th>
      <th> Margem de Contribuição 2 </th>
      <th> Despesas Financeiras </th>
      <th> Margem de Contribuição 3 </th>
    </tr>

    <tr>
      <th class="th-child">{{ dsObj.variableCost.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.aquisitionCost.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.revenueSelling.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.revenueStock.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.MC1.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.fixedCost.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.MC2.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.financialExpense.value1 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.MC3.value1 | currency: 'BRL':true}} </th>
    </tr>

    <tr>
      <th class="th-child">{{ dsObj.variableCost.value2 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.aquisitionCost.value2 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.revenueSelling.value2 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.revenueStock.value2 | currency: 'BRL':true}} </th>
      <th class="th-child">{{ dsObj.MC1.value2 | number:'1.2-2'}}% </th>
      <th class="th-child">{{ dsObj.fixedCost.value2 }} </th>
      <th class="th-child">{{ dsObj.MC2.value2 | number:'1.2-2'}}% </th>
      <th class="th-child">{{ dsObj.financialExpense.value2 | number:'1.2-2'}}% </th>
      <th class="th-child">{{ dsObj.MC3.value2 | number:'1.2-2'}}% </th>
    </tr>
  </table>
</mat-card>

<div class="align-col">
<mat-card>
  <table mat-table [dataSource]="dataSourceCosts">
    <ng-container matColumnDef="variableCostArroba">
      <th mat-header-cell *matHeaderCellDef> Custos Variável por Arroba</th>
      <td mat-cell *matCellDef="let element"> {{element.variableCostArroba | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="totalCostArroba">
      <th mat-header-cell *matHeaderCellDef> Custos Total por Arroba</th>
      <td mat-cell *matCellDef="let element"> {{element.totalCostArroba | currency: 'BRL':true}} </td>
    </ng-container>

    <ng-container matColumnDef="totalCostAndFinancialArroba">
      <th mat-header-cell *matHeaderCellDef> Custo Total + Financeiro por Arroba</th>
      <td mat-cell *matCellDef="let element"> {{element.totalCostAndFinancialArroba | currency: 'BRL':true}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedArrobaCost"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedArrobaCost;"></tr>
  </table>
</mat-card>

<mat-card>
  <table>
    <tr>
      <th>MC3/hectare: </th>
      <td> {{mc3/area | currency: 'BRL':true}} </td>
    </tr>

    <tr>
      <th>MC2/Arroba: </th>
      <td> {{mc2/production | currency: 'BRL':true}} </td>
    </tr>

    <tr>
      <th>MC3/Arroba: </th>
      <td> {{mc3/production | currency: 'BRL':true}} </td>
    </tr>

    <tr>
      <th>MC3/Rebanho Total: </th>
      <td> {{(mc3/(atualCattle*arrobaCost))*100 | number:'0.0-2'}}%</td>
    </tr>

    <tr>
      <th>MC2/Rebanho Total: </th>
      <td> {{(mc2/(atualCattle*arrobaCost))*100 | number:'0.0-2'}}%</td>
    </tr>

  </table>
</mat-card>
</div>
