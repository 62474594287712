<section class="flex center">
  <button mat-button  [routerLink]="'../../'" class="voltar">
    <mat-icon>keyboard_backspace</mat-icon>
    Voltar
  </button>
  <span class="spacer-left"></span>
  <h3>Visualizar Operações</h3>
  <span class="spacer"></span>
</section>

<section class="container">
<table mat-table [dataSource]="dataSource">
  <!-- <ng-container matColumnDef="_id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element._id }}</td>
  </ng-container> -->

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon class="iconSync" *ngIf="!element.status" alt="Na fila"
        >sync</mat-icon
      >
      <mat-icon
        class="iconclosed"
        *ngIf="element.status"
        alt="Operação Finalizada"
        >check_circle</mat-icon
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="tipoMovimento">
    <th mat-header-cell *matHeaderCellDef>Tipo Movimento</th>
    <td mat-cell *matCellDef="let element">{{ element.tipoMovimento }}</td>
  </ng-container>

  <ng-container matColumnDef="documento">
    <th mat-header-cell *matHeaderCellDef>Documento</th>
    <td mat-cell *matCellDef="let element">{{ element.documento }}</td>
  </ng-container>

  <ng-container matColumnDef="armazem">
    <th mat-header-cell *matHeaderCellDef>Armazem</th>
    <td mat-cell *matCellDef="let element">{{ element.armazem }}</td>
  </ng-container>

  <ng-container matColumnDef="centrocusto">
    <th mat-header-cell *matHeaderCellDef>Centro de Custo</th>
    <td mat-cell *matCellDef="let element">{{ element.centrocusto }}</td>
  </ng-container>

  <ng-container matColumnDef="classevalor">
    <th mat-header-cell *matHeaderCellDef>Classe de Valor</th>
    <td mat-cell *matCellDef="let element">{{ element.classevalor }}</td>
  </ng-container>

  <ng-container matColumnDef="conta">
    <th mat-header-cell *matHeaderCellDef>Conta</th>
    <td mat-cell *matCellDef="let element">{{ element.conta }}</td>
  </ng-container>

  <ng-container matColumnDef="emissao">
    <th mat-header-cell *matHeaderCellDef>Emissao</th>
    <td mat-cell *matCellDef="let element">{{ element.emissao }}</td>
  </ng-container>

  <ng-container matColumnDef="empresa">
    <th mat-header-cell *matHeaderCellDef>Empresa</th>
    <td mat-cell *matCellDef="let element">{{ element.empresa }}</td>
  </ng-container>

  <ng-container matColumnDef="filial">
    <th mat-header-cell *matHeaderCellDef>Filial</th>
    <td mat-cell *matCellDef="let element">{{ element.filial }}</td>
  </ng-container>

  <ng-container matColumnDef="produto">
    <th mat-header-cell *matHeaderCellDef>Produto</th>
    <td mat-cell *matCellDef="let element">{{ element.produto }}</td>
  </ng-container>

  <ng-container matColumnDef="quantidade">
    <th mat-header-cell *matHeaderCellDef>Quantidade</th>
    <td mat-cell *matCellDef="let element">{{ element.quantidade }}</td>
  </ng-container>

  <ng-container matColumnDef="tipoMovimentoEstorno">
    <th mat-header-cell *matHeaderCellDef>Estorno</th>
    <td mat-cell *matCellDef="let element">
      {{ element.tipoMovimentoEstorno }}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Tipo</th>
    <td mat-cell *matCellDef="let element">{{ element.type }}</td>
  </ng-container>

  <ng-container matColumnDef="valor">
    <th mat-header-cell *matHeaderCellDef>Valor</th>
    <td mat-cell *matCellDef="let element">{{ element.valor }}</td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Reenviar item</th>
    <td mat-cell *matCellDef="let element">
      <button
        class="ajMenu"
        mat-menu-item
        (click)="revertOperation(element._id)"
      >
        <mat-icon>reply</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="disclaimer">
    <td class="legBot" mat-footer-cell *matFooterCellDef colspan="9">
      <mat-grid-list rowHeight="30px" cols="9">
        <mat-grid-tile colspan="3"> Legendas: </mat-grid-tile>
        <mat-grid-tile colspan="3">
          Na fila
          <mat-icon class="iconSync" alt="Na fila">sync</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          Operações Finalizadas
          <mat-icon class="iconclosed" alt="Operações Finalizadas"
            >check_circle</mat-icon
          >
        </mat-grid-tile>
      </mat-grid-list>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
  <tr
    mat-footer-row
    *matFooterRowDef="['disclaimer']"
    class="example-first-footer-row"
  ></tr>
</table>

</section>
