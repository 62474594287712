<div class="org">
  <section class="header-reports">
    <form class="search">
      <mat-icon class="icon">search_outline</mat-icon>
      <input
        class="input"
        matInput
        (input)="onSearch($event)"
        placeholder="Pesquisar em relatórios"
      />
    </form>
    <span class="section-spacer"></span>
    <mat-button-toggle-group
      class="toggle-button-container"
      (valueChange)="selectCategory($event)"
    >
      <mat-button-toggle>Todos</mat-button-toggle>
      <mat-button-toggle
        *ngFor="let category of reportCategories"
        [value]="category.id"
        >{{ category.description }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </section>

  <div class="grid-reports">
    <div *ngFor="let report of reports">
      <mat-card class="card-reports">
        <mat-card-header>
          <mat-card-title> {{ report.title }} </mat-card-title>
          <mat-card-subtitle ng-repeat="report in reports | filter:search">{{
            report.description
          }}</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-actions class="actions-btn">
          <button
            class="button-report"
            mat-raised-button
            (click)="setReport(report, exec)"
          >
            <mat-icon>get_app</mat-icon>
            Gerar Relatório
          </button>
          <button
            class="button-report-info"
            mat-icon-button
            matTooltip="Informações relatório"
            (click)="setReport(report, info)"
          >
            <mat-icon>info</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
