import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

export type TYieldSlaughterReport = {
  initialDate: string;
  finalDate: string;
  farmId: number;
}

@Injectable({
  providedIn: 'root'
})
export class YieldSlaughterReportService implements ReportInterface<TYieldSlaughterReport, any> {
  farmId!: number;
  reportFilters: TYieldSlaughterReport
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data",
      fieldValue: "",
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data",
      fieldValue: "",
    },
  ];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
  ) { }

  openSmartReport(): void {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      initialDate: '',
      finalDate: '',
      farmId: this.farmId
    }

    const data: TDataSmartReport<TYieldSlaughterReport> = {
      title: 'Venda para Abate',
      description: 'Selecione as opções para gerar o relatório',
      requiredFilters: true,
      reportFilterFields: this.reportFilterFields,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel]
    }
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: 'Venda de animais para abate',
      subtitle: 'Detalhes sobre vendas e pesos de animais por negócio, lotes e animais negociados',
      description: [
        'Informações Contidas no relatório: ',
        '-> Pesos de entrada, saída e abate em quilogramas e arroba',
        '-> Identificação (SISBOV)',
        '-> Área de origem dos animais',
        '-> Data de Embarque',
        '-> Dias no confinamento',
        '-> GMD e GMD líquido',
        '-> Rendimentos de carcaça',
        '-> Arrobas produzidas',
        '-> Preço da arroba',
        '-> Valor líquido do boi e do lote',
        '-> Frigorífico'
      ]
    }
    this._bottomSheet.open(BottomSheetComponent, { data })
  }

  getReport(datas: TYieldSlaughterReport): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/report/commercial`,
      datas,
      { responseType: "blob", headers: PecControlHeaderRequest.getHeader(this.authService.token) }
    );
  }

  getSlaughterPerOperationReport(outputOperationid: any, id: any, farmId: number): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/report/commercial/yieldSlaughterPerOperation`,
      { outputOperationid, id, farmId },
      { responseType: "blob", headers: PecControlHeaderRequest.getHeader(this.authService.token) }
    );
  }
}
