import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SlaughterService } from 'src/app/services/commercial/slaughter.service';



export interface SlaughteredAnimals {
  sisbov: number;
  inputDate?: Date;
  inputWeight?: number;
  inputWeightArroba: number;
  outputWeight: number;
  estimatedOutputWeight?: number;
  outputDate?: Date;
  days: number;
  gmdConf: number;
  gmdLiquidity: number;
  deadWeight: number;
  yield: number;
  daysForOneArroba: number;
  arrobaQuantity: number;
  arrobaPrice: number;
  arrobaCost?: number;
  animalLiquidValue: number;
  arrobaReceived?: number;
}

@Component({
  selector: 'app-slaughtered-animals',
  templateUrl: './slaughtered-animals.component.html',
  styleUrls: ['./slaughtered-animals.component.scss']
})
export class SlaughteredAnimalsComponent implements OnInit {
  @Input () outputOperationId: string

  columns: string[] = [
    "sisbov",
    "inputWeightArroba",
    "outputWeight",
    "gmdConf",
    "gmdLiquid",
    "days",
    "cost",
    "daysForOneArroba",
    "arrobaQuantity",
    "yield",
    "deadWeightArrobaValue",
    "arrobaPrice",
    "animalLiquidValue",
    "margem"
  ]
  dataSource = new MatTableDataSource<SlaughteredAnimals>();
  totalRecords = 0;
  loading = false;
  allDatas: SlaughteredAnimals[];
  amountAnimals: number[] = [];
  slaughterId: number;


  constructor(
    private slaughterService: SlaughterService,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {
    const datas = this.route.paramMap.pipe(
      switchMap((params) => {

        const id = Number(params.get("id"));
        this.slaughterId = id;
        return this.slaughterService.getById(id);
      })
    );

    datas.subscribe((value) => {

      this.slaughterService.getSlaughteredAnimals(value.items.outputOperationId, this.slaughterId).subscribe((res) => {
      this.dataSource = res.items.modelSlaughterDatas;

      }, (err) => {
      })
    });
  }


}
