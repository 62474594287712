import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmCancelModalComponent {
  title: string;
  description: string;
}

@Component({
  selector: 'pec-confirm-cancel-modal',
  templateUrl: './confirm-cancel-modal.component.html',
  styleUrls: ['./confirm-cancel-modal.component.scss']
})
export class ConfirmCancelModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmCancelModalComponent
  ) { }

  ngOnInit(): void {
  }

  save(): void {
    this.dialogRef.close(true)
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
