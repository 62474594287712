import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

export type TNutritionalLotMNConsumptionReport = {
  farmId: number;
};

@Injectable({
  providedIn: "root",
})
export class NutritionalLotMNConsumptionReportService
  implements ReportInterface<TNutritionalLotMNConsumptionReport, Blob>
{
  farmId!: number;
  reportFilters: TNutritionalLotMNConsumptionReport;
  title!: string;

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private dialog: MatDialog,
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.title = "Consumo Matéria Natural";
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
    };

    const data: TDataSmartReport<TNutritionalLotMNConsumptionReport> = {
      title: `${
        this.title
      }-${this.contextService.getCurrentDescriptionFarm()}.xlsx`,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: false,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Informações Contidas no relatório",
      description: [
        "-> Descrição do Lote",
        "-> Quantidade de animais",
        "-> Data de Entrada",
        "-> Quantidade de dias",
        "-> Peso Entrada",
        "-> Peso Estimado",
        "-> Dieta Ativa",
        "-> Data da Dieta Ativa",
        "-- Últimos 5 dias --",
        "-> Notas de Cocho",
        "-> Consumo Planejado",
        "-> Consumo Realizado",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(
    datas: TNutritionalLotMNConsumptionReport,
    seletecReportType: string
  ): Observable<any> {
    if (seletecReportType === SMART_TYPE_REPORT.excel) {
      return this.http.post(
        `${this.authService.getUrlBackEnd()}/api/nutritional/report/nutritional-lot-mn-consumption`,
        { farmId: datas.farmId },
        {
          headers: PecControlHeaderRequest.getHeader(this.authService.token),
          responseType: "blob",
        }
      );
    }
  }
}
