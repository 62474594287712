import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GenericFilterResponse } from "../../../components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { PecServiceInterface } from "../../../interfaces/core/PecServiceInterface";
import { FarmInterface } from "../../../interfaces/farm/FarmInterface";
import PecControlHeaderRequest from "../../../utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

@Injectable({
  providedIn: "root",
})
export class FarmService implements PecServiceInterface<FarmInterface> {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) {}

  farmId = this.contextService.getFarm();

  create(farm: FarmInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/farm`,
      {
        farm: {
          ...farm,
          farmType: Number(farm.farmType),
        },
      },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(
    page: number,
    limit: number,
    filter?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/farm?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAllFarms(
    page: number,
    limit: number,
    filter?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }

    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/farm/all/?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(farmId: number): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/farm/${farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  update(farm: FarmInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/farm`,
      { farm },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  // ---- Config Farm
  getFarmConfigById(farmId: number): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/farmConfig/${farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
