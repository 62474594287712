import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PastureManegementRoutingModule } from './pasture-manegement-routing.module';
import { PastureManegementIndexComponent } from './pasture-manegement-index/pasture-manegement-index.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, _MatMenuDirectivesModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { PastureManegementListComponent } from './components/pasture-manegement-list/pasture-manegement-list.component';


@NgModule({
  declarations: [PastureManegementIndexComponent, PastureManegementListComponent],
  imports: [
    CommonModule,
    PastureManegementRoutingModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatSelectModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    _MatMenuDirectivesModule,
    NgxDropzoneModule,
    MatStepperModule,
    MatChipsModule,
  ]
})
export class PastureManegementModule { }
