import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

export type TAnimalTransferReport = {
  farmId: number;
  initialDate: string;
  finalDate: string;
};

@Injectable({
  providedIn: "root",
})
export class AnimalTransferReportService
  implements ReportInterface<TAnimalTransferReport, Blob>
{
  farmId!: number;
  reportFilters: TAnimalTransferReport;
  title!: string;
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "startDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Digite a data inicial  EX: 05/05/2005",
      fieldValue: "",
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Digite a data final",
      fieldValue: "",
    },
  ];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.title = "Saldo Temporário Animais";
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      initialDate: "",
      finalDate: "",
    };

    const data: TDataSmartReport<TAnimalTransferReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Informações Contidas no relatório",
      description: [],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TAnimalTransferReport): Observable<any> {
    let config: any = {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    };
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animaltransfers`,
      datas,
      config
    );
  }
}
