import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { FormTypeEnum } from "src/app/enums/FormTypeEnum";
import { ParamsInterface } from "src/app/interfaces/params/ParamsInterface";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";

@Component({
  selector: "app-integration-edit",
  templateUrl: "./integration-edit.component.html",
  styleUrls: ["./integration-edit.component.scss"],
})
export class IntegrationEditComponent implements OnInit {
  integrationType: ParamsInterface;
  integrationId: number;

  formType = FormTypeEnum.EDIT;
  isFormValid = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private integrationService: FarmIntegrationService,
    private snackBar: SnackbarComponent
  ) {}

  ngOnInit() {
    const integrationTypeGetById = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        return this.integrationService.getById(id);
      })
    );
    integrationTypeGetById.subscribe((value) => {
      this.integrationType = value.items;
      this.integrationId = this.integrationType.id;
    });
  }

  setFormValid(valid: boolean) {
    this.isFormValid = valid;
  }

  setIntegration(integration: ParamsInterface) {
    this.integrationType = integration;
    this.integrationType.id = this.integrationId;
  }

  save() {
    this.integrationService.update(this.integrationType).subscribe(
      (res) => {
        this.snackBar.snackbarSuccess("Integração editada com sucesso!");
        this.router.navigate(["../.."], { relativeTo: this.route });
      },
      (error) => {
        this.snackBar.snackbarError("Erro ao editar integração");
      }
    );
  }
}
