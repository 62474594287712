<section class="container scroll">
  <section class="flex center">
    <span class="spacer-left"></span>
    <h3>Valor Bruto de Produção (VBP)</h3>
    <!-- <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
    >
      <mat-icon class="icon">more_vert</mat-icon>
    </button> -->
    <!-- <mat-menu class="toolbar-title" #menu="matMenu"> -->
      <!-- <button mat-menu-item (click)="positionCalculous()">
        <mat-icon>description</mat-icon>
        <span>Cálculo Posição de Rebanho</span>
      </button> -->
    <!-- </mat-menu> -->
    <span class="spacer"></span>
  </section>
  <div class="container">
    <mat-card class="height-card">
      <mat-card-title> Calcular Valor Bruto </mat-card-title>
      <mat-card-content>
        <form [formGroup]="productionValueFormGroup">
          <div class="grid-date">
            <mat-form-field>
              <mat-label>Data Início</mat-label>
              <input
                matInput
                type="date"
                placeholder="Ex. Ano-Mês-Dia"
                formControlName="initialDate"
                [(ngModel)]="initialDate"
                maxlength="18"
                autocomplete="off"
                [value]="initialDate"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data Final</mat-label>
              <input
                matInput
                type="date"
                placeholder="Ex. Ano-Mês-Dia"
                formControlName="finalDate"
                [(ngModel)]="finalDate"
                maxlength="18"
                autocomplete="off"
                [value]="finalDate"
              />
            </mat-form-field>
          </div>
          <div class="grid-cvb">
            <mat-form-field>
              <mat-label>Área Produtiva (ha)</mat-label>
              <input
                matInput
                [(ngModel)]="datas.productionArea"
                formControlName="productionArea"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Custos Variáveis (R$)</mat-label>
              <input
                matInput
                [(ngModel)]="datas.variableCost"
                formControlName="variableCost"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Custos Compras (R$)</mat-label>
              <input
                matInput
                [(ngModel)]="datas.aquisitionCost"
                formControlName="aquisitionCost"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Receita de Vendas (R$)</mat-label>
              <input
                matInput
                [(ngModel)]="datas.sellRevenue"
                formControlName="sellRevenue"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>R$/@ Estoque</mat-label>
              <input
                matInput
                [(ngModel)]="datas.arrobaCostStock"
                formControlName="arrobaCostStock"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Custos Fixos (R$)</mat-label>
              <input
                matInput
                [(ngModel)]="datas.fixedCost"
                formControlName="fixedCost"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Despesas Financeiras (%)</mat-label>
              <input
                matInput
                [(ngModel)]="datas.financialExpense"
                formControlName="financialExpense"
                maxlength="18"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions align="end">
        <div class="button-container">
          <button
            class="add"
            *ngIf="isGenerateExcel"
            mat-raised-button
            color="primary"
            (click)="generateExcel()"
          >
            <mat-icon [style.display]="loading ? 'none' : 'inline-block'"
              >print</mat-icon
            >
            <span [style.display]="loading ? 'none' : 'inline-block'"
              >Exportar Excel</span
            >
            <div class="spinner-container-case" *ngIf="loading">
              <mat-spinner diameter="26" color="warn"></mat-spinner>
            </div>
          </button>
        </div>
        <button mat-raised-button color="primary" (click)="getParams()">
          <mat-icon>cached</mat-icon>
          Calcular
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div class="container">
    <app-production-values-tables
      [initialDate]="initialDate"
      [finalDate]="finalDate"
      (positionDatas)="receivePositionDatas($event)"
    ></app-production-values-tables>
    <app-production-values-buy-and-sell
      [initialDate]="initialDate"
      [finalDate]="finalDate"
      (buyAndSellingDatas)="receiveBuyAndSellingDatas($event)"
    ></app-production-values-buy-and-sell>
    <app-praduction-values-production-datas
      [area]="productionArea"
      [buyWeightInArroba]="buyWeightInArroba"
      [sellWeightInArroba]="sellWeightInArroba"
      [positionAnimalTotalWeightDifference]="
        positionAnimalTotalWeightDifference
      "
    >
    </app-praduction-values-production-datas>
    <app-production-values-contribuition-margin
      *ngIf="isShowContribution"
      [params]="params"
      [initialDate]="initialDate"
      [finalDate]="finalDate"
      [positionAnimalTotalWeightDifference]="
        positionAnimalTotalWeightDifference
      "
      [atualCattle]="atualCattle"
      [sellWeightInArroba]="sellWeightInArroba"
      [buyWeightInArroba]="buyWeightInArroba"
      [revenueSelling]="revenueSelling"
    ></app-production-values-contribuition-margin>
  </div>
</section>
