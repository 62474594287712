import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-animal-temporary-balance-index',
  templateUrl: './animal-temporary-balance-index.component.html',
  styleUrls: ['./animal-temporary-balance-index.component.scss']
})
export class AnimalTemporaryBalanceIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
