import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AnimalIndexComponent} from './animal-index/animal-index.component';
import {AnimalCreateComponent} from './animal-create/animal-create.component';
import {AnimalViewComponent} from './animal-view/animal-view.component';
import {AnimalEditComponent} from './animal-edit/animal-edit.component';
import {AnimalImportXlsxComponent} from './animal-import-xlsx/animal-import-xlsx.component';
import { AnimalAbstractComponent } from './components/animal-abstract/animal-abstract.component';

const routes: Routes = [
  {
    path: '',
    component: AnimalIndexComponent
  },
  {
    path: 'import-xlsx',
    component: AnimalImportXlsxComponent
  },
  {
    path: 'create',
    component: AnimalCreateComponent
  },
  {
    path: ':id',
    component: AnimalViewComponent
  },
  {
    path: 'edit/:id',
    component: AnimalEditComponent
  },
  {
    path: 'abstract/:id',
    component: AnimalAbstractComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnimalRoutingModule {
}
