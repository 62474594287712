import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_URL } from '../../config/env';
import { Observable, Subject } from 'rxjs';
import { UserAuthInterface } from '../../interfaces/user-auth-interface';
import { User } from '../../interfaces/context/User';
import PecControlHeaderRequest from '../../../app/utils/PecControlHeaderRequest';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  user: User = {} as User;
  scope = '';
  token = '';

  constructor(
    private http: HttpClient,

  ) {
    this.checkSession();
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${SERVER_URL}/auth`, { email, password });
  }

  checkSession(): any {
    const session = this.getSession();
    if (session) {
      this.isLoggedIn = true;
      // @ts-ignore
      this.user = session.user;
      this.token = session.token;
      this.scope = session.scope;
    }
  }

  setSession(userAuth: any): any {
    localStorage.setItem('P3cC0nTrolJWT', JSON.stringify(userAuth));
    this.checkSession();
    this.setUrlBackEnd(environment.serverUrl);
  }

  setUrlBackEnd(urlBackEnd: any) {
    localStorage.setItem('urlBackEnd', urlBackEnd);
  }

  getSession(): UserAuthInterface {
    return JSON.parse(localStorage.getItem('P3cC0nTrolJWT'));
  }

  getUrlBackEnd(): string {
    return localStorage.getItem('urlBackEnd');
  }

  clearSession(): void {
    this.isLoggedIn = false;
    this.user = null;
    this.scope = null;
    this.token = null;
    localStorage.removeItem('P3cC0nTrolJWT');
    localStorage.removeItem('P3cC0nTrolFarm');
    localStorage.removeItem('urlBackEnd');
  }

  checkAuthIsValid() {
    return this.http.get(`${this.getUrlBackEnd()}/api/validToken`, {
      headers: PecControlHeaderRequest.getHeader(this.token)
    });
  }

}
