import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { FormTypeEnum } from "src/app/enums/FormTypeEnum";
import { ParamsInterface } from "src/app/interfaces/params/ParamsInterface";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";
import { FixedCostsInterface } from "../../../../interfaces/stock/FixedCostsInterface";

@Component({
  selector: "app-integration-view",
  templateUrl: "./integration-view.component.html",
  styleUrls: ["./integration-view.component.scss"],
})
export class IntegrationViewComponent implements OnInit {
  integrationType: ParamsInterface;
  isFormValid = false;
  formType = FormTypeEnum.VIEW;
  integrationSoon = false;
  totalRecords = 0;
  integrationId: number;
  loading: boolean;

  columns: string[] = [
    "conta",
    "description",
    "typeCost",
    "releaseDate",
    "value",
    "origin",
    "phase",
  ];
  dataSource = new MatTableDataSource<FixedCostsInterface>();

  constructor(
    private route: ActivatedRoute,
    private integrationService: FarmIntegrationService,
    private snack: SnackbarComponent
  ) {}

  ngOnInit() {
    this.loading = false;
    const integrationGetById = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        this.integrationId = id;
        return this.integrationService.getById(id);
      })
    );

    integrationGetById.subscribe((value) => {
      this.integrationType = value.items;
      if (this.integrationType?.status === "2" || "3") {
        this.integrationSoon = true;
        this.getPecCostInformations(this.integrationType.id);
      }
    });
  }

  setFormValid(valid: boolean) {
    this.isFormValid = valid;
  }

  setIntegration(integration: ParamsInterface) {
    this.integrationType = integration;
  }

  getPecCostInformations(integrationId: number) {
    return this.integrationService.getParamsId(integrationId).subscribe(
      (res) => {
        this.dataSource = res.items;
      },
      (err) => {
      }
    );
  }

  getAssessment(integration: ParamsInterface) {
    this.loading = true;
    Object.defineProperty(integration, "status", {
      value: "3",
      writable: false,
    });

    this.integrationService.assessmentPecCost(this.integrationId).subscribe(
      (res) => {
        this.loading = false;
        this.snack.snackbarSuccess("Rateio feito com sucesso!");
      },
      (err) => {
        this.loading = false;
        this.snack.snackbarError("Erro ao fazer rateio");
      }
    );
  }
}
