<mat-form-field>
  <mat-chip-list #chipList aria-label="Fruit selection" [disabled]="disabled">
    <mat-chip *ngFor="let d of lookupResponse" [selectable]="selectable"
              [removable]="removable" (removed)="remove(d)" [disabled]="disabled">
      {{d.label}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      [disabled]="disabled"
      [placeholder]="label"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [formControl]="lookupFormControl"
      (keydown)="onKeyDown($event.preventDefault())"
    >
    <button mat-icon-button [disabled]="disabled">
      <mat-icon matSuffix aria-hidden="false" color="primary" (click)="openDialog()">search</mat-icon>
    </button>
  </mat-chip-list>
</mat-form-field>

