import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import * as moment from "moment";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { PecCommonFilterModalComponent } from "../pec-common-filter-modal/pec-common-filter-modal.component";
import { SnackbarComponent } from "../snackbar/snackbar.component";

export const SMART_TYPE_REPORT = {
  pdf: "PDF",
  excel: "EXCEL",
};

export type TDataSmartReport<T> = {
  title: string;
  description: string;
  requiredFilters: boolean;
  reportFilters: T;
  service: ReportInterface<T, any>;
  reportFilterFields?: PecGenericFilterFields[];
  order?: any;
  reportTypes: Array<string>;
};

export const CONFIG_SMART_REPORT = {
  width: "400px",
  height: "400px",
};

@Component({
  selector: "app-smart-report-modal",
  templateUrl: "./smart-report-modal.component.html",
  styleUrls: ["./smart-report-modal.component.scss"],
})
export class SmartReportModalComponent implements OnInit {
  selectedResportType: string;
  reportTypes: Array<string>;
  disabledConfirm: boolean;
  isLoading: boolean;
  disabledButtonFilter: boolean;
  disabledButtonOrder: boolean;
  selectedType: string;

  constructor(
    public dialogRef: MatDialogRef<SmartReportModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: TDataSmartReport<any>,
    private openSnack: SnackbarComponent
  ) {}

  ngOnInit(): void {
    this.disabledButtonOrder = true;
    this.reportTypes = this.data.reportTypes;
    if (this.data.requiredFilters) {
      this.disabledConfirm = true;
    } else {
      this.disabledButtonFilter = true;
    }
  }
  selectedReportType(event: MatRadioChange) {
    this.selectedType = event.value;
  }
  clickFilter() {
    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: "400px",
      data: this.data.reportFilterFields,
    });
    dialogRef.afterClosed().subscribe((fields) => {
      if (fields) {
        if (fields.length > 0) {
          this.disabledConfirm = false;

          fields.forEach((field: any) => {
            if (field.fieldValue) {
              this.data.reportFilterFields.forEach((filterField) => {
                if (field["fieldName"] == filterField.fieldName) {
                  if (filterField.type === GenericFilterTypeEnum.DATE) {
                    field.fieldValue = moment(field.fieldValue).format(
                      "YYYY-MM-DD"
                    );
                  }
                }
              });
              this.data.reportFilters[field.fieldName] = field.fieldValue;
            }
          });
        }
      }
    });
  }

  clickOrder() {}

  confirm(): void {
    if (!this.selectedType) {
      this.openSnack.snackbarAlert(
        "Selecione o tipo do relatório a ser gerado"
      );
      return;
    }

    this.isLoading = true;

    this.data.service
      .getReport(this.data.reportFilters, this.selectedType)
      .subscribe(
        (result: any) => {
          if (result.message) {
            this.openSnack.snackbarSuccess(result.message);
            this.isLoading = false;
            this.dialogRef.close(true);
          } else {
            if (!result) {
              this.openSnack.snackbarError(
                "Erro ao gerar relatório. Verifique com administrador do sistema."
              );
              this.dialogRef.close(false);
              return;
            }
            const url = window.URL.createObjectURL(result);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = url;
            a.download = `${moment(new Date()).format("DD/MM/YYYY")}-${
              this.data.title
            }`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            this.isLoading = false;
            this.dialogRef.close(true);
          }
        },
        (error) => {
          this.openSnack.snackbarError(
            "Erro ao gerar relatório. Verifique o filtro usado"
          );
          this.dialogRef.close(false);
          return;
        }
      );
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
