import { Injectable } from "@angular/core";
import { AnimalAgeGroupReportService } from "src/app/services/reports/animal/animal-age-group-report.service";
import { AnimalAreaReportService } from "src/app/services/reports/animal/animal-area-report.service";
import { AnimalBalanceReportService } from "src/app/services/reports/animal/animal-balance-report.service";
import { AnimalDeathReportService } from "src/app/services/reports/animal/animal-death-report.service";
import { AnimalReportService } from "src/app/services/reports/animal/animal-report.service";
import { AnimalTemporaryBalanceReportService } from "src/app/services/reports/animal/animal-temporary-balance-report.service";
import {
  REPORT_BUTTON_EVENT,
  REPORT_CATEGORY,
  TReportIndex,
  TReportsIndex,
} from "./report-index.service";
import {AnimalSisbovChangeReportService} from '../../../services/reports/animal/animal-sisbov-change-report.service';

const ANIMAL_REPORT_ID = {
  areaAnimal: 1,
  temporaryBalance: 2,
  animalsBalance: 3,
  animals: 4,
  animalDeath: 5,
  animalAgeGroup: 6,
  animalSisbovChange: 7,
};

@Injectable({
  providedIn: "root",
})
export class ReportCategoryAnimalService {
  private animalReports: TReportsIndex;

  constructor(
    private readonly aniAreaReportService: AnimalAreaReportService,
    private readonly aniTempBalanceReportService: AnimalTemporaryBalanceReportService,
    private readonly animalBalanceReportService: AnimalBalanceReportService,
    private readonly animalReportService: AnimalReportService,
    private readonly animalDeathReportService: AnimalDeathReportService,
    private readonly animalGroupReportService: AnimalAgeGroupReportService,
    private readonly animalSisbovChangeService: AnimalSisbovChangeReportService
  ) {}

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    if (reportButtonEvent === REPORT_BUTTON_EVENT.EXEC) {
      this.setExec(report);
    } else if (reportButtonEvent === REPORT_BUTTON_EVENT.INFO) {
      this.setInfo(report);
    }
  }

  setExec(report: TReportIndex) {
    if (report.id === ANIMAL_REPORT_ID.areaAnimal) {
      this.aniAreaReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.temporaryBalance) {
      this.aniTempBalanceReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalsBalance) {
      this.animalBalanceReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animals) {
      this.animalReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalDeath) {
      this.animalDeathReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalAgeGroup) {
      this.animalGroupReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalSisbovChange) {
      this.animalSisbovChangeService.openSmartReport();
    }
  }

  setInfo(report: TReportIndex) {
    if (report.id === ANIMAL_REPORT_ID.areaAnimal) {
      this.aniAreaReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.temporaryBalance) {
      this.aniTempBalanceReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalsBalance) {
      this.animalBalanceReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animals) {
      this.animalReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalDeath) {
      this.animalDeathReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalAgeGroup) {
      this.animalGroupReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.animalSisbovChange) {
      this.animalSisbovChangeService.openInfoReport();
    }
  }

  getReportsAnimals(): TReportsIndex {
    this.animalReports = [
      {
        id: ANIMAL_REPORT_ID.areaAnimal,
        title: "Animais por área",
        description: "Gera dados dos animais por área(Médias).",
        category: REPORT_CATEGORY.ANIMAL,
      },
      {
        id: ANIMAL_REPORT_ID.temporaryBalance,
        title: "Saldo temporário de Animais",
        description: "Gera dados temporário dos animais sem identificação.",
        category: REPORT_CATEGORY.ANIMAL,
      },
      {
        id: ANIMAL_REPORT_ID.animalsBalance,
        title: "Saldo Animal por Período",
        description: "Gera os dados do saldo de animais no período informado.",
        category: REPORT_CATEGORY.ANIMAL,
      },
      {
        id: ANIMAL_REPORT_ID.animals,
        title: "Informações Animais",
        description:
          "Gera os dados Informações dos animais. Conforme parâmetros.",
        category: REPORT_CATEGORY.ANIMAL,
      },
      {
        id: ANIMAL_REPORT_ID.animalDeath,
        title: "Mortalidade Animal",
        description: "Impressão -  Mortalidade animal. Campos para assinaturas",
        category: REPORT_CATEGORY.ANIMAL,
      },
      {
        id: ANIMAL_REPORT_ID.animalAgeGroup,
        title: "Animais por Faixa Etária",
        description:
          "Impressão - Faixa etária dos animais. Campos para assinaturas",
        category: REPORT_CATEGORY.ANIMAL,
      },
      {
        id: ANIMAL_REPORT_ID.animalSisbovChange,
        title: "Reidentificação SISBOV",
        description:
          "Gera informações dos animais que tiveram reidentificação SISBOV",
        category: REPORT_CATEGORY.ANIMAL,
      },
    ];

    return this.animalReports;
  }
}
