import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingStatusService } from 'src/app/pages/nutrition/daily/components/ComplementarInformations/loading-status.service';
import { SlaughterInputParamsService } from 'src/app/services/commercial/slaughter-input-params.service';

@Component({
  selector: 'app-areas-modal',
  templateUrl: './areas-modal.component.html',
  styleUrls: ['./areas-modal.component.scss']
})
export class AreasModalComponent {

  constructor(
    public dialogRef: MatDialogRef<any>,
    private loadingService: LoadingStatusService,
    private snack: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private slaughterInputParamsService: SlaughterInputParamsService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { }

  save(): void {
    this.dialogRef.close(true);
    this.loadingService.changeMessage(true)

    let models = this.data

    for(let model of models){
      this.slaughterInputParamsService.updateOrCreate(model).subscribe(
        (value) => {
          this.loadingService.changeMessage(false)
          this.snack.open("Dados de confinamento alterados com sucesso.", null, {
            duration: 3000,
          });
        },
        (err) => {
          this.loadingService.changeMessage(false)
          this.snack.open(err.error.message, null, { duration: 3000 });
        }
      );

    }

  }

  close(): void {
    this.dialogRef.close(false);
    this.loadingService.changeMessage(false)
  }

  onUpdateIndex(e: any, index: any, dataupdate: any, data: string) {
    if(data === 'inputWeight'){
      dataupdate.inputWeight = +e.target.value;

    }
    if(data === 'inputDate'){
      dataupdate.inputDate = e.target.value;

    }
    if(data === 'area'){
      dataupdate.area = e.target.value;

    }

    this.data[index] = dataupdate;


  }

}
