import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {SERVER_URL} from '../../../config/env';
import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';
import {LotInterface} from '../../../interfaces/farm/LotInterface';
import {FarmInterface} from '../../../interfaces/farm/FarmInterface';
import {PecServiceInterface} from '../../../interfaces/core/PecServiceInterface';
import {GenericFilterResponse} from '../../../components/shared/pec-common-filter/interaces/GenericFilterResponse';

@Injectable({
  providedIn: 'root'
})
export class LotService implements PecServiceInterface<LotInterface> {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  create(lot: LotInterface): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/lot`, {lot}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }    
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/lot?page=${page}&limit=${limit}${filterQueryString}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }


  getById(lotId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/lot/${lotId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(lot: any): Observable<any> {
    return this.http.put(`${this.authService.getUrlBackEnd()}/api/lot`, {lot}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

}
