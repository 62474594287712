import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { NgxDropzoneModule } from "ngx-dropzone";
import { ChartsModule } from "ng2-charts";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatChipsModule } from "@angular/material/chips";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDividerModule } from "@angular/material/divider";

import { SharedModule } from "src/app/components/shared/shared.module";
import { AnimalRoutingModule } from "./animal-routing.module";

import { AnimalCreateComponent } from "./animal-create/animal-create.component";
import { AnimalIndexComponent } from "./animal-index/animal-index.component";
import { AnimalFormComponent } from "./components/animal-form/animal-form.component";
import { AnimalListComponent } from "./components/animal-list/animal-list.component";
import { AnimalViewComponent } from "./animal-view/animal-view.component";
import { AnimalCharacteristicInputComponent } from "./components/animal-characteristic-input/animal-characteristic-input.component";
import { AnimalEditComponent } from "./animal-edit/animal-edit.component";
import { AnimalAbstractComponent } from "./components/animal-abstract/animal-abstract.component";
import { AnimalImportXlsxComponent } from "./animal-import-xlsx/animal-import-xlsx.component";
import { ChangeAnimalStatusModalComponent } from './components/animal-list/change-animal-status-modal/change-animal-status-modal.component'
import { DirectivesModule } from "src/app/directives/Directives.module";

@NgModule({
  declarations: [
    AnimalCreateComponent,
    AnimalIndexComponent,
    AnimalFormComponent,
    AnimalListComponent,
    AnimalCharacteristicInputComponent,
    AnimalEditComponent,
    AnimalViewComponent,
    AnimalImportXlsxComponent,
    AnimalAbstractComponent,
    ChangeAnimalStatusModalComponent,
  ],
  imports: [
    AnimalRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    ChartsModule,
    SharedModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    NgxDropzoneModule,
    MatTabsModule,
    MatChipsModule,
    MatPaginatorModule,
    MatDividerModule,
    DirectivesModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "pt-BR" }],
})
export class AnimalModule {}
