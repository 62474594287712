<div class="container">
  <mat-card>
    <mat-card-title> {{ title }}</mat-card-title>
    <mat-card-subtitle>Arquivo formato Excel</mat-card-subtitle>
    <mat-card-actions>
      <ng-template matStepLabel>Seleção de arquivo</ng-template>
      <mat-grid-list rowHeight="250px" cols="1">
        <mat-grid-tile colspan="1">
          <ngx-dropzone (change)="onSelect($event)">
            <ngx-dropzone-label style="width: 500px"
              >Solte o arquivo aqui ou click para buscar o arquivo
            </ngx-dropzone-label>
            <ngx-dropzone-preview
              *ngFor="let f of files"
              [removable]="true"
              (removed)="onRemove(f)"
            >
              <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
        </mat-grid-tile>
      </mat-grid-list>
      <button mat-raised-button color="warn" (click)="close()" cdkFocusInitial>
        Cancelar
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="confirm()"
        [disabled]="isDisabled"
      >
        Confirmar
      </button>
    </mat-card-actions>
  </mat-card>
</div>
