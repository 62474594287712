import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { AnimalInternalMovementInterface } from "src/app/interfaces/animalMovement/AnimalInternalMovementInterface";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";
import { ContextService } from "src/app/services/context/context.service";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AnimalInternalMovementService } from "../../animalMovement/animal-internal-movement.service";
import { AuthService } from "../../auth/auth.service";

type TInternalOperationsReport = {
  farmId: number;
  initialDate: string;
  finalDate: string;
  internalMovementId: number;
};

@Injectable({
  providedIn: "root",
})
export class InternalOperationsReportService
  implements ReportInterface<TInternalOperationsReport, Blob>
{
  reportFilters: TInternalOperationsReport;
  title: string;
  filterInternalMovement: PecGenericFilterList[] = [];
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data inicial Ex. 1/1/2020",
      fieldValue: "",
      hint: "obrigatório",
      required: true,
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data final Ex.: 30/1/2020",
      fieldValue: "",
      hint: "obrigatório",
      required: true,
    },
    {
      fieldName: "internalMovementId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione o movimento internl",
      fieldValue: "",
      valuesList: this.filterInternalMovement,
    },
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly contextService: ContextService,
    private readonly dialog: MatDialog,
    private readonly _bottomSheet: MatBottomSheet,
    private readonly internalMovementService: AnimalInternalMovementService
  ) {
    this.title = "Operações Interna";
    this.getInternalMovement();
  }

  getInternalMovement() {
    this.internalMovementService
      .getAll(1, 100)
      .subscribe((internalMovement) => {
        if (internalMovement.items) {
          internalMovement.items.forEach(
            (element: AnimalInternalMovementInterface) => {
              this.filterInternalMovement.push({
                fieldLabel: element.description,
                fieldValue: String(element.internalMovementId),
              });
            }
          );
        }
      });
  }

  openSmartReport() {
    this.reportFilters = {
      farmId: this.contextService.getFarm(),
      initialDate: null,
      finalDate: null,
      internalMovementId: null,
    };

    const data: TDataSmartReport<TInternalOperationsReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };

    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Lista operações internas conforme filtros aplicados",
      description: [
        "Informações Contidas no relatório:",
        "Tipo de Operação",
        "Quantidade de Animais",
        "Identificação SISBOV",
        "Raça",
        "Sexo",
        "Idade",
        "Peso",
        "Peso Anterior",
        "GMD",
        "Quantidade de dias desde a última pesagem",
        "Ganho Total",
        "Aparte",
        "Área de Origem",
        "Área de Destino",
        "Características",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TInternalOperationsReport): Observable<Blob> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/operationReports/internalOperationGeneralReport`,
      datas,
      {
        responseType: "blob",
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
