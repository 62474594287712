<div class="container">
  <mat-card>
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step>
        <ng-template matStepLabel>Seleção de arquivo</ng-template>
        <mat-grid-list rowHeight="250px" cols="1">
          <mat-grid-tile colspan="1">
            <ngx-dropzone (change)="onSelect($event)" style="width:100%">
              <ngx-dropzone-label>Drop it, baby!</ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </mat-grid-tile>
        </mat-grid-list>
        <div>
          <button mat-raised-button color="primary" (click)="uploadFile()">Proximo</button>
        </div>
      </mat-step>
      <mat-step label="Resumo da importação">
        <div>
          <mat-tab-group dynamicHeight>
            <mat-tab label="Importados">
              <div class="example-small-box mat-elevation-z4">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                  <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                  <!-- Position Column -->
                  <ng-container matColumnDef="sisbovNumber">
                    <th mat-header-cell *matHeaderCellDef> SISBOV</th>
                    <td mat-cell *matCellDef="let element"> {{element.sisbovNumber}} </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list class="example-chip">
                        <mat-chip *ngIf="element.status == '1'" selected color="primary">Importado</mat-chip>
                        <mat-chip *ngIf="element.status == '2'" selected color="primary">Já cadastrado</mat-chip>
                      </mat-chip-list>
                    </td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="manejo">
                    <th mat-header-cell *matHeaderCellDef> Manejo</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.manejo}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </mat-tab>
            <mat-tab label="Não importados">
              <div class="example-small-box mat-elevation-z4">
                <table mat-table [dataSource]="dataSourceNaoImportados" class="mat-elevation-z8">

                  <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                  <!-- Position Column -->
                  <ng-container matColumnDef="sisbovNumber">
                    <th mat-header-cell *matHeaderCellDef> SISBOV</th>
                    <td mat-cell *matCellDef="let element"> {{element.sisbovNumber}} </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list class="example-chip">
                        <mat-chip *ngIf="element.status == '1'" selected color="primary">Importado</mat-chip>
                        <mat-chip *ngIf="element.status == '2'" selected color="primary">Já cadastrado</mat-chip>
                      </mat-chip-list>
                    </td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="manejo">
                    <th mat-header-cell *matHeaderCellDef> Manejo</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.manejo}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>

          <button mat-raised-button color="primary" (click)="finalizar()">Finalizar</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card>
</div>
