<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
    <mat-card *ngIf="isLoading" style="width: 95%; height: 100%; ">
        <pec-list-loading text="Aguarde. Gerando Relatório"></pec-list-loading>
    </mat-card>
    <div *ngIf="!isLoading">
        <p>{{ data.description }}</p>
        <mat-grid-list cols="2" rowHeight="2:2">
            <mat-grid-tile>
                <mat-card style="width: 100%; height: 100%; ">
                    <p>
                        Tipo de Relatório
                    </p>                
                    <mat-card-content>
                        <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" (change)="selectedReportType($event)">
                            <mat-radio-button class="radio-button" *ngFor="let reportType of reportTypes"
                                [value]="reportType"  >
                                {{reportType}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card style="width: 100%; height: 100%;">
                    <p> Filtro </p>
                    <button mat-fab color="primary" (click)="clickFilter()" [disabled]="disabledButtonFilter">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </mat-card>
            </mat-grid-tile>
            <!-- <mat-grid-tile>
                <mat-card style="width: 90%;">
                    <p>Ordenação</p>
                    <button mat-fab color="primary" (click)="clickOrder()" [disabled]="disabledButtonOrder">
                        <mat-icon>sort</mat-icon>
                    </button>
                </mat-card>
            </mat-grid-tile> -->
        </mat-grid-list>
    </div>
</div>

<div mat-dialog-actions *ngIf="!isLoading">
    <mat-card style="width: 95%; ">
        <mat-grid-list cols="2" rowHeight="40px">
            <mat-grid-tile>
                <button mat-raised-button color="warn" (click)="close()" cdkFocusInitial>Cancelar</button>
            </mat-grid-tile>
            <mat-grid-tile>
                <button mat-raised-button (click)="confirm()" [disabled]="disabledConfirm" color="primary">Confirmar</button>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card>
</div>