<mat-card class="aj-tb">
  <table mat-table [dataSource]="dataSourceAnimalPosition">
    <!-- heeader-->
    <ng-container matColumnDef="header-row-ageGroup">
      <th [attr.rowspan]="1" mat-header-cell *matHeaderCellDef> Faixa Etária</th>
    </ng-container>

    <ng-container matColumnDef="header-row-animalStock">
      <th  [attr.colspan]="2" mat-header-cell *matHeaderCellDef> Estoque de Animais</th>
    </ng-container>

    <ng-container matColumnDef="header-row-stockInKg">
      <th [attr.colspan]="2" mat-header-cell *matHeaderCellDef> Estoque em Kg</th>
    </ng-container>

    <ng-container matColumnDef="header-row-stockInArroba">
      <th [attr.colspan]="2" mat-header-cell *matHeaderCellDef> Estoque em @</th>
    </ng-container>

    <!-- BODY -->

    <ng-container matColumnDef="ageGroup">
      <td mat-cell *matCellDef="let element"> {{element.ageGroup}} </td>
    </ng-container>
    <ng-container matColumnDef="ianimalStock">
      <td mat-cell *matCellDef="let element"> {{element.animalStock.ianimalStock | number:'1.2-2'}} </td>
    </ng-container>
    <ng-container matColumnDef="fanimalStock">
      <td mat-cell *matCellDef="let element"> {{element.animalStock.fanimalStock | number:'1.2-2'}} </td>
    </ng-container>
    <ng-container matColumnDef="istockInKg">
      <td mat-cell *matCellDef="let element"> {{element.stockInKg.iStockInKg | number:'1.2-2'}} </td>
    </ng-container>
    <ng-container matColumnDef="fstockInKg">
      <td mat-cell *matCellDef="let element"> {{element.stockInKg.fStockInKg | number:'1.2-2'}} </td>
    </ng-container>
    <ng-container matColumnDef="istockInArroba">
      <td mat-cell *matCellDef="let element"> {{element.stockInArroba.iStockInArroba | number:'1.2-2'}} </td>
    </ng-container>
    <ng-container matColumnDef="fstockInArroba">
      <td mat-cell *matCellDef="let element"> {{element.stockInArroba.fStockInArroba | number:'1.2-2'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['header-row-ageGroup','header-row-animalStock', 'header-row-stockInKg', 'header-row-stockInArroba']"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsPositionAnimal;"></tr>
  </table>
</mat-card>







