<div class="container">
<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon class="iconOpened" *ngIf="element.status == '1'">hide_source</mat-icon>
      <mat-icon class="iconPartial" *ngIf="element.status == '2'">check_circle</mat-icon>
      <mat-icon class="iconclosed" *ngIf="element.status == '3'">check_circle</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="classeinicial">
    <th mat-header-cell *matHeaderCellDef>Classe Inicial</th>
    <td mat-cell *matCellDef="let element">{{ element.classeinicial }}</td>
  </ng-container>

  <ng-container matColumnDef="classefinal">
    <th mat-header-cell *matHeaderCellDef>Classe Final</th>
    <td mat-cell *matCellDef="let element">{{ element.classefinal }}</td>
  </ng-container>

  <ng-container matColumnDef="mes">
    <th mat-header-cell *matHeaderCellDef>Ano/Mês</th>
    <td mat-cell *matCellDef="let element">{{ element.mes }}</td>
  </ng-container>

  <ng-container matColumnDef="excecaocldebito">
    <th mat-header-cell *matHeaderCellDef>Excessões de débito</th>
    <td mat-cell *matCellDef="let element">{{ element.excecaocldebito }}</td>
  </ng-container>

  <ng-container matColumnDef="excecaoclcredito">
    <th mat-header-cell *matHeaderCellDef>Excessões de crédito</th>
    <td mat-cell *matCellDef="let element">{{ element.excecaoclcredito }}</td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Opções</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.loading">
        <mat-spinner color="primary" [diameter]="30"></mat-spinner>
      </div>
      <button *ngIf="!element.loading" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="./edit/{{ element.id }}">
          <mat-icon>edit</mat-icon>
          <span>Editar</span>
        </button>

        <button mat-menu-item routerLink="./view/{{ element.id }}">
          <mat-icon>visibility</mat-icon>
          <span>Visualizar</span>
        </button>

        <button mat-menu-item (click)="delete(element)">
          <mat-icon>delete</mat-icon>
          <span>Excluir</span>
        </button>

        <button mat-menu-item (click)="carryCosts(element)">
          <mat-icon>gavel</mat-icon>
          <span>Carregar Custos</span>
        </button>

        <button mat-menu-item (click)="generateExcel(element.id)">
          <mat-icon>print</mat-icon>
          <span>Gerar relatório</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <tr class="mat-row" *matNoDataRow>
    <td *ngIf="!loading" class="mat-cell" colspan="5">Nenhum dado encontrado</td>
    <td *ngIf="loading" class="mat-cell" colspan="5">
      <pec-list-loading></pec-list-loading>
    </td>
  </tr>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
<mat-paginator mPaginationItemsPerPage class="paginator" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
  (page)="onPageChange($event)" [length]="totalRecords" [hidePageSize]="false"></mat-paginator>
</div>
