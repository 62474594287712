<mat-toolbar>
    <mat-toolbar-row class="sizeToolbar">
      <button mat-icon-button [routerLink]="'../../'" class="voltar">
        <mat-icon>keyboard_backspace</mat-icon>
        Voltar
      </button>
      <span class="toolbar-spacer-left"></span>
      <span>Animais Pendentes de Entrada</span>
      <span class="toolbar-spacer"></span>
      <button
        class="add"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        <mat-icon>check</mat-icon>
        Salvar
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <app-animal-temporary-balance-forms [formType]="formType" [inputOperationInput]="inputOperation" (getInformations)="setInputOperation($event)"></app-animal-temporary-balance-forms>
