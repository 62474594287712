import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';


import {AnimalInputMovementInterface} from '../../interfaces/animalMovement/AnimalInputMovementInterface';
import PecControlHeaderRequest from '../../utils/PecControlHeaderRequest';
import {AuthService} from '../auth/auth.service';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class AnimalInputMovementService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) {
  }

  farmId = this.contextService.getFarm();

  create(movement: AnimalInputMovementInterface): Observable<any> {
    movement.farmId = this.farmId;
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/animalInputMovement`, {movement}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animalInputMovement?page=${page}&limit=${limit}&farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animalInputMovement/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(movement: AnimalInputMovementInterface): Observable<any> {
    movement.farmId = this.farmId;
    return this.http.put(`${this.authService.getUrlBackEnd()}/api/animalInputMovement`, {
      movement
    }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/animalInputMovement/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
