<table
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="announceSortChange($event)"
>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
    <td mat-cell *matCellDef="let element">
      {{ element.status === 1 ? "Finalizado" : "Aguardando" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="date">
      Data de Entrada
    </th>
    <td mat-cell *matCellDef="let element">{{ element.date }}</td>
  </ng-container>

  <ng-container matColumnDef="maleQuantity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="maleQuantity">
      Machos
    </th>
    <td mat-cell *matCellDef="let element">{{ element.maleQuantity }}</td>
  </ng-container>

  <ng-container matColumnDef="femaleQuantity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="femaleQuantity">
      Fêmeas
    </th>
    <td mat-cell *matCellDef="let element">{{ element.femaleQuantity }}</td>
  </ng-container>

  <ng-container matColumnDef="animalAgeGroup">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="animalAgeGroup">
      Faixa Etária
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.animalAgeGroup?.description }}
    </td>
  </ng-container>

  <ng-container matColumnDef="operationMovement">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="description">
      Movimentação
    </th>
    <td mat-cell *matCellDef="let element">
      <p *ngIf="element.operationMovement == 'C'">Compra</p>
      <p *ngIf="element.operationMovement == 'N'">Nascimento</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="farmGrowerId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="farmName">
      Fazenda Produtor
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.farmGrower?.description }}
    </td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Opções</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.loading">
        <mat-spinner color="primary" [diameter]="30"></mat-spinner>
      </div>
      <button
        *ngIf="!element.loading"
        mat-icon-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="./edit/{{ element.id }}">
          <mat-icon>edit</mat-icon>
          <span>Editar</span>
        </button>

        <button mat-menu-item (click)="delete(element)">
          <mat-icon>delete</mat-icon>
          <span>Excluir</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
