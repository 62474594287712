import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommercialSellingIndexComponent } from '../commercial/commerciel-selling/commercial-selling-index/commercial-selling-index.component';
import { PastureManegementIndexComponent } from './pasture-manegement-index/pasture-manegement-index.component';

const routes: Routes = [
  {
    path: '',
    component: PastureManegementIndexComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PastureManegementRoutingModule { }
