import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import * as moment from "moment";
import { Color, Label } from "ng2-charts";
import { switchMap } from "rxjs/operators";
import { FormTypeEnum } from "src/app/enums/FormTypeEnum";
import { AnimalService } from "src/app/services/animal/animal/animal.service";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import { UtilitiesToDateService } from "src/app/utils/utilitiesToDate/utilities-to-date.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-animal-abstract",
  templateUrl: "./animal-abstract.component.html",
  styleUrls: ["./animal-abstract.component.scss"],
})
export class AnimalAbstractComponent implements OnInit {
  formType: FormTypeEnum = FormTypeEnum.VIEW;
  loadingChart: boolean;
  animal: any;
  arr: any = [];
  months: any;
  characteristics: any = {};
  lotDescriptionText: any;
  areaDescriptionText: any;
  currentProtocolMedications: any;
  animalCost = {
    id: 0,
    animalId: "",
    inputOpCost: 0,
    outputOpCost: 0,
    internalOpCost: 0,
    nutritionalCost: 0,
    healthProtocolCost: 0,
    otherCost: 0,
    date: "",
    createdAt: "",
    updatedAt: "",
    sumAll: 0,
  };

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {},
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(148,159,177,0.5)",
          },
          ticks: {
            min: 0,
            stepSize: 100,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        color: "#ffff",
        font: {
          family: "FontAwesome",
          size: 12,
        },
      },
      deferred: false,
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[] = [];
  public barChartColors: Color[] = [
    {
      backgroundColor: "#416DF1",
      borderColor: "#ffff",
      hoverBackgroundColor: "#547CF2",
      hoverBorderColor: "#ffff",
    },
    {
      backgroundColor: "#416DF1",
      borderColor: "#ffff",
      hoverBackgroundColor: "#547CF2",
      hoverBorderColor: "#ffff",
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private animalService: AnimalService,
    private dateUtilities: UtilitiesToDateService,
    public dialogRef: MatDialogRef<AnimalAbstractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {animalId: string},
  ) { }

  ngOnInit() {
    const animal = this.animalService.getById(this.data.animalId);

    this.loadingChart = true;
    this.animalService.getCostAnimal(this.data.animalId).subscribe((value: any) => {
      if (value) {
        let sumAll: number = 0;

        for(let valueSum in value) {
          if(valueSum !== "id" && valueSum !== "animalId" && valueSum !== "date" && valueSum !== "createdAt" && valueSum !== "updatedAt") {
            sumAll += value[valueSum];
          }
        }
        this.animalCost = value;
        this.animalCost.sumAll = sumAll;
      }
     }, err => {
     });

    animal.subscribe((value) => {
      this.animal = value.items;

      this.arr.push(this.animal);
      this.loadingChart = false;
      for (let bigArr of this.arr) {
        const animalDatas: any = bigArr["animalCurrentData"];
        let medicineDatas: any = bigArr["animalMedicineHistory"];
        const areaDescription = animalDatas.farmArea?.description;
        const lotDescription = animalDatas.lot?.description;
        this.areaDescriptionText = areaDescription;
        this.lotDescriptionText = lotDescription;

        // TRAZER MEDICAMENTOS QUE FORAM MINISTRADOS NO PROTOCOLO ATUAL
        let currentProtocol =
          this.animal.animalCurrentData.healthProtocol?.description;
        let medicamentsFiltereds = medicineDatas.filter((element: any) => {
          if (element.protocol)
            return element.protocol.description === currentProtocol;
        });
        // MEDICAMENTOS MINISTRADOS DURANTE O PROTOCOLO

        let medicaments = medicamentsFiltereds.map((item: any) => {
          let medicament = item.medicine.description;
          return medicament;
        });
        this.currentProtocolMedications = medicaments.join(" , ");

        // GRAFICO DE PESO DO ANIMAL
        let allWeighingValues = bigArr.weighing.sort((a: any, b: any) =>
          a.date < b.date ? -1 : 1
        );
        let weightValues = allWeighingValues.map((item: any) => {
          let weighing = item.weight;
          return weighing;
        });
        let dateValues = allWeighingValues.map((item: any) => {
          let date = this.dateUtilities.getDateFormated(item.date);
          return date;
        });

        this.barChartLabels = dateValues;
        this.barChartData = [
          {
            data: weightValues,
            label: "Peso(Kg)",
          },
        ];
        // CARACTERISTICAS DO ANIMAL
        const arrayDatas: any[] = bigArr["animalCharacteristic"];
        for (let data of arrayDatas) {
          if (data["characteristic"].description === "Frame") {
            this.characteristics.frame = data["characteristicENUM"].value;
          }
          if (data["characteristic"].description === "Umbigo") {
            this.characteristics.umbigo = data["characteristicENUM"].value;
          }
          if (data["characteristic"].description === "Altura do Animal (Cm)") {
            this.characteristics.altura = data.value;
          }
        }
      }
      // CALCULO IDADE
      let age = moment(this.animal.birthDate);
      this.months = this.dateUtilities.getAgeForBirthDate(age);
    }, err => {
      this.loadingChart = false;
    });

  }
  close(): void {
    this.dialogRef.close();
  }
}
