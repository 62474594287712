import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { AnimalOutputMovementInterface } from "src/app/interfaces/animalMovement/AnimalOutputMovementInterface";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";
import { ContextService } from "src/app/services/context/context.service";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AnimalOutputMovementService } from "../../animalMovement/animal-output-movement.service";
import { AuthService } from "../../auth/auth.service";

type TOutputOperationsReport = {
  farmId: number;
  initialDate: string;
  finalDate: string;
  outputMovementId: number;
};

@Injectable({
  providedIn: "root",
})
export class OutputOperationsReportService
  implements ReportInterface<TOutputOperationsReport, Blob>
{
  reportFilters: TOutputOperationsReport;
  title: string;
  filterOutputMovement: PecGenericFilterList[] = [];
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data inicial Ex. 1/1/2020",
      fieldValue: "",
      hint: "obrigatório",
      required: true,
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data final Ex.: 30/1/2020",
      fieldValue: "",
      hint: "obrigatório",
      required: true,
    },
    {
      fieldName: "outputMovementId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione o movimento internl",
      fieldValue: "",
      valuesList: this.filterOutputMovement,
    },
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly contextService: ContextService,
    private readonly dialog: MatDialog,
    private readonly _bottomSheet: MatBottomSheet,
    private readonly outputMovementService: AnimalOutputMovementService
  ) {
    this.title = "Operações de Saída";
    this.getOutputMovement();
  }

  getOutputMovement() {
    this.outputMovementService.getAll(1, 100).subscribe((outputMovement) => {
      if (outputMovement.items) {
        outputMovement.items.forEach(
          (element: AnimalOutputMovementInterface) => {
            this.filterOutputMovement.push({
              fieldLabel: element.description,
              fieldValue: String(element.outputMovementId),
            });
          }
        );
      }
    });
  }

  openSmartReport() {
    this.reportFilters = {
      farmId: this.contextService.getFarm(),
      initialDate: null,
      finalDate: null,
      outputMovementId: null,
    };

    const data: TDataSmartReport<TOutputOperationsReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };

    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Lista operações de saída conforme filtros aplicados",
      description: [
        "Informações Contidas no relatório:",
        "Operações Realizadas no Período",
        "Quantidade de Animais por Operação",
        "Data e Hora da Operação",
        "Raça",
        "Sexo",
        "Idade em mêses",
        "Peso",
        "Peso de Entrada no Confinamento",
        "Data de Entrada no Confinament",
        "GMD",
        "Dias de Confinamento",
        "Ganho de Peso no Confinamento",
        "Aparte",
        "Área",
        "Fornecedor",
        "Data de Entrada na Fazenda",
        "Tempo na Fazenda",
        "Peso de Chegada",
        "GMD Fazenda",
        "GMD Recria",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TOutputOperationsReport): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/operationReports/outputOperationGeneralReport`,
      datas,
      { headers: PecControlHeaderRequest.getHeader(this.authService.token) }
    );
  }
}
