<mat-nav-list>
    <h2>
        <span mat-line>
            {{data.title}}
        </span>
    </h2>
    <h3>
        <span mat-line>
            {{data.subtitle}}
        </span>
    </h3>
    <p *ngFor="let description of data.description">
        <span mat-line >
            {{description}}
        </span>
    </p>
</mat-nav-list>
