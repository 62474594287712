import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

export type TAnimalAreaReport = {
  farmId: number
}

@Injectable({
  providedIn: 'root'
})
export class AnimalAreaReportService implements ReportInterface<TAnimalAreaReport, any> {
  farmId!: number
  reportFilters: TAnimalAreaReport

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId
    };
    const descriptionFarm = this.contextService.getCurrentDescriptionFarm();

    const data: TDataSmartReport<TAnimalAreaReport> = {
      title: `Animais por área-${descriptionFarm}`,
      description: 'Selecione as opções para gerar o relatório',
      requiredFilters: false,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel]
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: 'Animais por Área',
      subtitle: 'Informações Contidas no relatório',
      description: [
        '-> Área',
        '-> Quantidade de Animais Total',
        '-> Médias de dias',
        '-> Média de Peso',
        '-> Quantidade de Animais por Faixa Etária'
      ]
    }
    this._bottomSheet.open(BottomSheetComponent, { data })
  }

  getReport(datas: TAnimalAreaReport): Observable<any> {
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    }
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/animalsReports/animalAreaAvarege `,
      datas,
      config
    );
  }


}
