<form [formGroup]="filterForm">
  <div formGroupName="formFields">
    <mat-grid-list cols="1" rowHeight="100px" *ngFor="let fm of formFields.controls; let i=index">
      <div [formGroupName]="i">
        <mat-grid-tile [colspan]="1" *ngIf="fields[i].type === genericFilterTypeEnum.DATE">
          <mat-form-field>
            <mat-label>{{fields[i].label}}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="fieldValue" autocomplete="off" [required]="fields[i].required">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker ></mat-datepicker>
            <mat-hint>{{fields[i].hint}}</mat-hint>            
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" *ngIf="fields[i].type === genericFilterTypeEnum.TEXT">
          <mat-form-field>
            <mat-label>{{fields[i].label}}</mat-label>
            <input matInput formControlName="fieldValue" autocomplete="off" type="text" [required]="fields[i].required">
            <mat-hint>{{fields[i].hint}}</mat-hint>            
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" *ngIf="fields[i].type === genericFilterTypeEnum.NUMBER">
          <mat-form-field>
            <mat-label>{{fields[i].label}}</mat-label>
            <input matInput formControlName="fieldValue" autocomplete="off" type="number" [required]="fields[i].required">
            <mat-hint>{{fields[i].hint}}</mat-hint>            
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" *ngIf="fields[i].type === genericFilterTypeEnum.SELECT">
          <mat-form-field>
            <mat-label>{{fields[i].label}}</mat-label>
            <mat-select formControlName="fieldValue" [required]="fields[i].required">
              <mat-option  *ngFor="let l of fields[i].valuesList" [value]="l.fieldValue">{{l.fieldLabel}}</mat-option>
            </mat-select>
            <mat-hint>{{fields[i].hint}}</mat-hint>            
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" *ngIf="fields[i].type === genericFilterTypeEnum.MULTISELECT">
          <mat-form-field>
            <mat-label>{{fields[i].label}}</mat-label>
            <mat-select formControlName="fieldValue" [required]="fields[i].required" multiple>            
              <mat-option  *ngFor="let l of fields[i].valuesList" [value]="l.fieldValue">{{l.fieldLabel}}</mat-option>
            </mat-select>
            <mat-hint>{{fields[i].hint}}</mat-hint>            
          </mat-form-field>
        </mat-grid-tile>
      </div>
    </mat-grid-list>
  </div>
</form>
