import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import {
  TDataSmartReport,
  SMART_TYPE_REPORT,
  CONFIG_SMART_REPORT,
  SmartReportModalComponent,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";
import { environment } from "src/environments/environment";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import { TAnimalBirthPredictReport } from "./animals-birth-prediction-report.service";

export type TAnimalDesmamaReport = {
  seasonId: string;
};

@Injectable({
  providedIn: "root",
})
export class AniDesmamaReportService
  implements ReportInterface<TAnimalDesmamaReport, any>
{
  farmId!: number;
  reportFilters: TAnimalDesmamaReport;
  filterDesmama: PecGenericFilterList[] = [
    {
      fieldLabel: "-Selecione a Estação de Monta-",
      fieldValue: "0",
    },
  ];

  reportFilterFields: any[] = [
    {
      fieldName: "seasonId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione a estação",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
      valuesList: this.filterDesmama,
    },
  ];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.getSeasons();
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      seasonId: "",
    };
    const descriptionFarm = this.contextService.getCurrentDescriptionFarm();

    const data: TDataSmartReport<TAnimalDesmamaReport> = {
      title: `Desmama Bezerro`,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: "Desmama de Animais",
      subtitle: "Informações Contidas no relatório",
      description: [
        "-> SISBOV",
        "-> SISBOV da Mãe",
        "-> Raça do Animal",
        "-> Reprodutor - touro",
        "-> Idade do Animal",
        "-> Peso de Desmama",
        "-> Data de Desmama",
        "-> Área Destino",
        "-> Observações",
        "-> Categoria do Animal",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getSeasons() {
    return this.http
      .get(
        `${
          environment.serverCriaUrlBackend
        }/season/getAllSeason?farmId=${this.contextService.getFarm()}`,
        {
          headers: PecControlHeaderRequest.getHeader(this.authService.token),
        }
      )
      .subscribe((seasons: any) => {
        if (seasons.isError === false) {
          seasons.data.forEach((element: any) => {
            console.log(element);

            this.reportFilterFields[0].valuesList.push({
              fieldLabel: element.seasonDescription,
              fieldValue: String(element.seasonId),
            });
          });
        }
      });
  }

  getReport(data: TAnimalDesmamaReport): any {
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    };
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/reports/cria/operations/desmama`,
      {
        seasonId: data.seasonId,
      },
      config
    );
  }
}
