import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { PecCommonFilterModalComponent } from 'src/app/components/shared/pec-common-filter-modal/pec-common-filter-modal.component';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { SlaughterInterface } from 'src/app/interfaces/commercial/slaughterInterface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import { SlaughterInputParamsService } from 'src/app/services/commercial/slaughter-input-params.service';

import { SlaughterService } from '../../../../../services/commercial/slaughter.service'
import { AreasModalComponent } from '../areas-modal/areas-modal.component';

@Component({
  selector: 'app-commercial-selling-view',
  templateUrl: './commercial-selling-view.component.html',
  styleUrls: ['./commercial-selling-view.component.scss']
})
export class CommercialSellingViewComponent implements OnInit {
  slaughterId: number;
  operationId: string;
  dataFilter: any = null;
  areas: any[];
  params: boolean = false;
  paramsData: any[] = [];
  loading!: boolean;


  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private slaughterService: SlaughterService,
    private slaughterInputParamsService: SlaughterInputParamsService,
    private readonly openSnack: SnackbarComponent,

  ) { }


  ngOnInit() {
    this.getAreas()
    this.getParams(this.slaughterId)


  }

  openModal() {
    const dialogRef = this.dialog.open(AreasModalComponent, {
      width: '700px',
      data: this.areas
    });
    dialogRef.afterClosed().subscribe(async (value: any) => {

      if (value) {
        location.reload()
      }


    });
  }

  getAreas() {
    const datas = this.route.paramMap.pipe(
      switchMap((params) => {

        const id = Number(params.get("id"));
        this.slaughterId = id;
        return this.slaughterService.getById(id);
      })
    );
    this.loading = true;

    datas.subscribe((value) => {

      this.slaughterService.getSlaughteredAnimals(value.items.outputOperationId, this.slaughterId).subscribe((res) => {
        this.loading = false;

        let datas = []
        for(let obj of res.items.modelSlaughterLot){

          let model = {
            area: obj.area,
            slaughterId: this.slaughterId,
            inputWeight: '',
            inputDate: ''
          }

          datas.push(model);

        }
        this.areas = datas

      }, (err) => {
      })
    });
  }

  getParams(slaughterId: number) {
    this.slaughterInputParamsService.getBySlaughterId(slaughterId).subscribe((res) => {


      if( res.items.length > 0 ){
        this.params = true
        let datas: any = []

        for(let obj of res.items) {
          datas.push(obj)
        }

        this.paramsData = datas
      }

    })
  }

  remove(params: any): void {

    const index = this.paramsData.indexOf(params);

    if (index >= 0) {
      this.paramsData.splice(index, 1);
      this.slaughterInputParamsService.delete(params.id)
        .subscribe(res => {
          this.openSnack.snackbarSuccess(res.message)
        }, e => {
          this.openSnack.snackbarError(e.message)
        })
    }
  }

}
