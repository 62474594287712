<div class="container">
  <table mat-table class="maxTable" [dataSource]="dataSource">
    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef>Área</th>
      <td mat-cell *matCellDef="let element">
        {{ element.area }}
      </td>
    </ng-container>

    <ng-container matColumnDef="animalQuantity">
      <th mat-header-cell *matHeaderCellDef>Nº de Animais</th>
      <td mat-cell *matCellDef="let element">
        {{ element.animalQuantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="inputWeightArroba">
      <th mat-header-cell *matHeaderCellDef>Peso Entrada (@)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.inputWeightArroba }}
      </td>
    </ng-container>

    <ng-container matColumnDef="outputWeight">
      <th mat-header-cell *matHeaderCellDef>Peso Saída (Kg)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.outputWeight }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deadWeight">
      <th mat-header-cell *matHeaderCellDef>Peso Morto (@)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.deadWeight }}
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef> Dias </th>
      <td mat-cell *matCellDef="let element">
        {{ element.days }}
      </td>
    </ng-container>

    <ng-container matColumnDef="gmdConf">
      <th mat-header-cell *matHeaderCellDef>GMD</th>
      <td mat-cell *matCellDef="let element">
        {{ element.gmdConf }}
      </td>
    </ng-container>

    <ng-container matColumnDef="gmdLiquid">
      <th mat-header-cell *matHeaderCellDef>GMD líquido</th>
      <td mat-cell *matCellDef="let element">
        {{ element.gmdLiquid }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deadWeightArroba">
      <th mat-header-cell *matHeaderCellDef>Peso Total de Abate (@)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.deadWeightArroba }}
      </td>
    </ng-container>

    <ng-container matColumnDef="yields">
      <th mat-header-cell *matHeaderCellDef>Rendimento (%)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.yields }}
      </td>
    </ng-container>

    <ng-container matColumnDef="daysForOneArroba">
      <th mat-header-cell *matHeaderCellDef>Dias/@</th>
      <td mat-cell *matCellDef="let element">
        {{ element.daysForOneArroba }}
      </td>
    </ng-container>

    <ng-container matColumnDef="arrobaQuantity">
      <th mat-header-cell *matHeaderCellDef>@ produzidas</th>
      <td mat-cell *matCellDef="let element">
        {{ element.arrobaQuantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="arrobaPrice">
      <th mat-header-cell *matHeaderCellDef>Valor Médio de Venda</th>
      <td mat-cell *matCellDef="let element">
        {{ element.arrobaPrice }}
      </td>
    </ng-container>

    <ng-container matColumnDef="animalLiquidValue">
      <th mat-header-cell *matHeaderCellDef>VL/Lote</th>
      <td mat-cell *matCellDef="let element">
        R${{ element.animalLiquidValue }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef>Custo Lote</th>
      <td mat-cell *matCellDef="let element">
        R$ {{ element.costAnimal }}
      </td>
    </ng-container>

    <ng-container matColumnDef="margem">
      <th mat-header-cell *matHeaderCellDef>Margem</th>
      <td mat-cell *matCellDef="let element">
        {{ element.margem }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>