import {Component, OnInit} from '@angular/core';
import {FormTypeEnum} from '../../../../enums/FormTypeEnum';
import {ActivatedRoute, Router} from '@angular/router';
import {AnimalInterface} from '../../../../interfaces/animal/AnimalInterface';
import {AnimalService} from '../../../../services/animal/animal/animal.service';

@Component({
  selector: 'pec-animal-create',
  templateUrl: './animal-create.component.html',
  styleUrls: ['./animal-create.component.scss']
})
export class AnimalCreateComponent implements OnInit {

  isLoading = false;

  formType = FormTypeEnum.CREATE;
  animal: AnimalInterface;

  constructor(
    private animalService: AnimalService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
  }

  setAnimal(animal: AnimalInterface): void {
    this.animal = animal;
  }

  create(): void {
    this.animalService.create(this.animal).subscribe(value => {
      this.router.navigate(['../'], {relativeTo: this.route});
    });
  }
}
