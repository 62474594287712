import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent implements OnInit {

  @Input('list-title') listTitle: string;
  @Input('button-hidden') buttonHidden: boolean = false
  @Input('button-text') buttonText: string;
  @Input('button-link') buttonLink: string;
  @Input('icon-button') iconButton: string;

  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  eventButton() {
    this.isValid.emit(true)
  }
}
