<section class="container scroll">
  <section class="flex center">
    <button mat-button [routerLink]="'../../'" class="voltar">
      <mat-icon>keyboard_backspace</mat-icon>
      <span class="title-btn">Voltar</span>
    </button>
    <span class="spacer"></span>
    <h3>Adicionar Nova Integração</h3>
    <span class="spacer"></span>
    <button
      class="add"
      mat-raised-button
      color="primary"
      (click)="create()"
      [disabled]="!formValid"
    >
      <mat-icon>save</mat-icon>
      Criar
    </button>
  </section>
  <app-integration-forms
    [formType]="formType"
    (integrationOutput)="setIntegration($event)"
    (isValid)="setFormValid($event)"
  ></app-integration-forms>

  <div class="container">
    <h3 class="set center">Configurações atuais de contas</h3>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="containicial">
        <th mat-header-cell *matHeaderCellDef>Conta inicial</th>
        <td mat-cell *matCellDef="let element">{{ element.containicial }}</td>
      </ng-container>

      <ng-container matColumnDef="contafinal">
        <th mat-header-cell *matHeaderCellDef>Conta final</th>
        <td mat-cell *matCellDef="let element">{{ element.contafinal }}</td>
      </ng-container>

      <ng-container matColumnDef="conta">
        <th mat-header-cell *matHeaderCellDef>Conta Redutora</th>
        <td mat-cell *matCellDef="let element">{{ element.conta }}</td>
      </ng-container>

      <ng-container matColumnDef="tm">
        <th mat-header-cell *matHeaderCellDef>Tipo de movimento</th>
        <td mat-cell *matCellDef="let element">{{ element.tm }}</td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>Opções</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Menu dedicated for options"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="delete(element)">
              <mat-icon>delete</mat-icon>
              <span>Deletar</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
    <mat-paginator
      class="paginator"
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
      (page)="onPageChange($event)"
      [length]="totalRecords"
      [hidePageSize]="false"
    ></mat-paginator>
  </div>
</section>
