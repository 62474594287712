import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ViacepResponse} from '../../interfaces/responses/ViacepResponse';

@Injectable({
  providedIn: 'root'
})
export class ViacepService {

  constructor(
    private http: HttpClient
  ) {
  }


  getCEP(cep: string): Observable<ViacepResponse> {
    // @ts-ignore
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

}
