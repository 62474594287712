import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IntegrationCreateComponent } from "./components/integration-create/integration-create.component";
import { IntegrationEditComponent } from "./components/integration-edit/integration-edit.component";
import { IntegrationViewComponent } from "./components/integration-view/integration-view.component";
import { IntegrationIndexComponent } from "./integration-index/integration-index.component";

const routes: Routes = [
  {
    path: '',
    component: IntegrationIndexComponent,
  },
  {
    path: 'create',
    component: IntegrationCreateComponent
  },
  {
    path: 'edit/:id',
    component: IntegrationEditComponent,
  },
  {
    path: 'view/:id',
    component: IntegrationViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationRoutingModule {}
