import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';

import { DeathCauseInterface } from '../../interfaces/animal/death/DeathCauseInterface';
import { SERVER_URL } from '../../config/env';
import { AuthService } from '../auth/auth.service';
import PecControlHeaderRequest from '../../utils/PecControlHeaderRequest';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class DeathCauseService {
  
  PATH = 'api/death/deathCause'

  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    ) { }


  create(deathCause: DeathCauseInterface): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/${this.PATH}`, {deathCause}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/${this.PATH}?page=${page}&limit=${limit}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/${this.PATH}/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(deathCause: DeathCauseInterface): Observable<any> {
    return this.http.put(`${this.authService.getUrlBackEnd()}/${this.PATH}`, {
      deathCause
    }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/${this.PATH}/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
