import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { DeathCauseInterface } from 'src/app/interfaces/animal/death/DeathCauseInterface';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import { PecGenericFilterList } from 'src/app/interfaces/utils/PecGenericFilterList';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { DeathCauseService } from '../../animalDeath/death-cause.service';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

export type TAnimalDeathReport = {
  farmId: number;
  initialDate: string;
  finalDate: string;
  deathCauseId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AnimalDeathReportService implements ReportInterface<TAnimalDeathReport, Blob> {
  farmId!: number
  reportFilters: TAnimalDeathReport
  title!: string
  filterDeathCause: PecGenericFilterList[] = [{
    fieldLabel: '-Todas Causas-',
    fieldValue: '0'
  }];
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data inicial Ex. 1/1/2022",
      fieldValue: "",
      hint: 'obrigatório',
      required: true,
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data final Ex.: 30/1/2022",
      fieldValue: "",
      hint: 'obrigatório',
      required: true,
    },
    {
      fieldName: "deathCauseId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione a causa da morte",
      fieldValue: "",
      valuesList: this.filterDeathCause,
    },
  ];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private deathCauseService: DeathCauseService,
  ) {
    this.title = 'Mortalidade Animal'
    this.getDeathCause()
  }

  getDeathCause() {
    this.deathCauseService.getAll(1, 1000)
      .subscribe(deathCauses => {
        if (deathCauses.items) {
          deathCauses.items.forEach((element: DeathCauseInterface) => {
            this.filterDeathCause.push({
              fieldLabel: element.description,
              fieldValue: String(element.deathCauseId)
            })
          });
        }
      })
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      initialDate: null,
      finalDate: null,
      deathCauseId: null,
    }

    const data: TDataSmartReport<TAnimalDeathReport> = {
      title: this.title,
      description: 'Selecione as opções para gerar o relatório',
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel, SMART_TYPE_REPORT.pdf]
    }
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: 'Relatório informações das mortes dos animais no período/causa. PDF com assinatura',
      description: [
        'Informações Contidas no relatório',
        '- Quantidade de animais totais',
        '-> SISBOV',
        '-> Raça',
        '-> Sexo',
        '-> Idade em mêses',
        '-> Fornecedor',
        '-> Data Morte',
        '-> Causa da Morte',
      ]
    }
    this._bottomSheet.open(BottomSheetComponent, { data })
  }

  getReport(datas: TAnimalDeathReport, seletecReportType: string): Observable<any> {
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    }
    if (seletecReportType === SMART_TYPE_REPORT.excel) {
      return this.http.post(
        `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animaldeath`,
        datas,
        config
      );
    }
    if (seletecReportType === SMART_TYPE_REPORT.pdf) {
      return this.http.post(
        `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animaldeathpdf`,
        datas,
        config
      );
    }
  }
}
