import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoutinesFileXlsxService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  updateAnimalsWithFile(farmId: number, file: File): Observable<HttpResponse<Blob>> {
    const formData = new FormData();
    formData.append('updateAnimalsWithFile', file);

    const headers = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        Authorization: `Bearer ${this.authService.token}`,
      });

    return this.http.post<Blob>(
      `${this.authService.getUrlBackEnd()}/api/routines/update-animals-with-file?farmId=${farmId}`,
      formData,
      {
        headers,
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    );
  }
}
