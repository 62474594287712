import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../auth/auth.service";
import { BreedInterface } from "../../../interfaces/animal/BreedInterface";
import { Observable } from "rxjs";
import { SERVER_URL } from "../../../config/env";
import PecControlHeaderRequest from "../../../utils/PecControlHeaderRequest";
import { AnimalInterface } from "../../../interfaces/animal/AnimalInterface";
import { AnimalFilterInterface } from "../../../interfaces/animal/AnimalFilterInterface";
import { GetDataGenerelaInterface } from "src/app/interfaces/animal/GetDataGenerelaInterface";
import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { ContextService } from "../../context/context.service";

const ROUTINE_PATH = "/api/animal";

@Injectable({
  providedIn: "root",
})
export class AnimalService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) {}

  farmId = this.contextService.getFarm();

  create(animal: AnimalInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animal/animal`,
      { animal, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(page: number, limit: number): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/web?page=${page}&limit=${limit}&farmId=${
        this.farmId
      }`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(animalId: string): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/${animalId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  update(breed: BreedInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/animal/animal`,
      { breed },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getTraceability(sisbov: number): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/getTraceability/${sisbov}&farmId=${
        this.farmId
      }`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  setTraceability(traceability: any) {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/setTraceability`,
      traceability,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getByIdentifier(identifier: any): Observable<any> {
    let filterQueryString = `identifier=${identifier.identifier}&condition=${identifier.condition}&value=${identifier.value}`;
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/search/identifier?${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAllTraceability(
    page: number,
    limit: number,
    filters?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";
    if (filters) {
      for (const f of filters) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/getAll/traceability?page=${page}&limit=${limit}${filterQueryString}&farmId=${
        this.farmId
      }`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
  getAnimalWithFilter(animal: AnimalFilterInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/animal`,
      { ...animal, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAllDataOfBase(nameTable: GetDataGenerelaInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/reports`,
      { ...nameTable, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getCostAnimal(animalId: string) {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/costreleases/costreleases?animalId=${animalId}&farmId=${
        this.farmId
      }`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAnimalCurrentData(
    page: number,
    limit: number,
    filters?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";

    if (filters) {
      for (const filter of filters) {
        if (filter.fieldName && filter.fieldValue) {
          filterQueryString += `&${filter.fieldName}=${filter.fieldValue}`;
        }
      }
    }
    const parameters = `page=${page}&limit=${limit}${filterQueryString}`;

    return this.http.get(
      `${this.authService.getUrlBackEnd()}${ROUTINE_PATH}/animalCurrentData?${parameters}&farmId=${
        this.farmId
      }`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  exportAnimals(animal: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata`,
      animal,
      { responseType: "blob" }
    );
  }

  exportRebanho(data: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animalPosition`,
      data,
      { responseType: "blob" }
    );
  }

  exportAnimalsDeath(animal: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animaldeath`,
      animal,
      { responseType: "blob" }
    );
  }

  exportAnimalsDeathPDF(animalDeathFilter: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animaldeathpdf`,
      animalDeathFilter,
      { responseType: "blob" }
    );
  }
  importAnimalsAgeGroupHtml(): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animalAgeGroupHtml`,
      { farmId: this.farmId },
      { responseType: "blob" }
    );
  }

  updateAnimal(animal: AnimalInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/animal/animal/update`,
      { animal },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  deleteWeight(weighingId: string) {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animal/weighing/delete/weight`,
      { weighingId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
