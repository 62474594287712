import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class UtilitiesToDateService {
  // RETORNA DATA FORMATA NO FORMATO dd/mm/yyyy
  getDateFormated(date: any) {
    let dateNow = new Date(date);
    let dateFormated = String(
      dateNow.toLocaleDateString("pt-BR", { timeZone: "UTC" })
    );
    return dateFormated;
  }

  // TRANSFORMA UMA DATA DE NASCIMENTO EM MESES
  getAgeForBirthDate(birthDate: any) {
    const months = moment().diff(birthDate, "months");
    return months;
  }

  // TRATA DATAS VINDAS DE UM INPUT DO TIPO TEXT
  treatDate(value: any, index: any) {
    return `${value[index].fieldValue.split("/")[2]}/${
      value[index].fieldValue.split("/")[1]
    }/${value[index].fieldValue.split("/")[0]}`;
  }

  // TESTA SE A DATA ESTÁ NA FORMATO dd/mm/yy E RETORNA SE É VÁLIDA OU NÃO
  testDataValidates(date: any) {
    let result = moment(date, "DD/MM/YYYY", true).isValid();
    return result;
  }

  // SOMA O TOTAL DE DADOS DE UM ARRAY
  getTotal(total: any, item: any) {
    return total + +item;
  }
}
