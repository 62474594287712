import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { PecCommonFilterModalComponent } from 'src/app/components/shared/pec-common-filter-modal/pec-common-filter-modal.component';
import { GenericFilterResponse } from 'src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse';
import { PecUploadFileComponent } from 'src/app/components/shared/pec-upload-file/pec-upload-file.component';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import { PecGenericFilterList } from 'src/app/interfaces/utils/PecGenericFilterList';
import { AnimalService } from 'src/app/services/animal/animal/animal.service';
import { ContextService } from 'src/app/services/context/context.service';
import { RoutinesFileXlsxService } from 'src/app/services/routines/routines-file-xlsx.service';

type TIdentifier = {
  identifier: string;
  condition: string;
  value: string;
};

@Component({
  selector: 'pec-animal-index',
  templateUrl: './animal-index.component.html',
  styleUrls: ['./animal-index.component.scss'],
})
export class AnimalIndexComponent implements OnInit {
  filterBreed: PecGenericFilterList[] = [];
  filterFieldsIdentifier!: PecGenericFilterFields[];
  filtersResponse!: GenericFilterResponse;
  filterIdentifier!: TIdentifier;
  dataFiltered: any;
  showButtonExport = false;
  methodExport = '';
  farm: number = this.contextService.getFarm();
  isLoading = false;
  modelFilter: any = {
    farmId: this.farm,
    page: 1,
    status: true,
  };

  modelRebanho: any = {
    date: '',
  };

  filterFields: PecGenericFilterFields[] = [
    {
      fieldName: 'breedId',
      type: GenericFilterTypeEnum.SELECT,
      label: 'Selecione a raça',
      fieldValue: '',
      valuesList: this.filterBreed,
    },
    {
      fieldName: 'minWeight',
      type: GenericFilterTypeEnum.NUMBER,
      label: 'Peso mínimo',
      fieldValue: '',
    },
    {
      fieldName: 'maxWeight',
      type: GenericFilterTypeEnum.NUMBER,
      label: 'Peso máximo',
      fieldValue: '',
    },
    {
      fieldName: 'minAge',
      type: GenericFilterTypeEnum.NUMBER,
      label: 'Idade mínima',
      fieldValue: '',
    },
    {
      fieldName: 'maxAge',
      type: GenericFilterTypeEnum.NUMBER,
      label: 'Idade máxima',
      fieldValue: '',
    },
  ];

  filterFieldsRebanho: PecGenericFilterFields[] = [
    {
      fieldName: 'date',
      type: GenericFilterTypeEnum.TEXT,
      label: 'Selecione a data Ex. 01/01/2001',
      fieldValue: '',
    },
  ];

  constructor(
    private animalService: AnimalService,
    public dialog: MatDialog,
    private contextService: ContextService,
    private readonly routinesFileXlsxService: RoutinesFileXlsxService,
    private openSnack: SnackbarComponent
  ) {}

  ngOnInit() {
    this.animalService
      .getAllDataOfBase({ model: 'BREED', limit: 100, page: 1 })
      .subscribe(
        (res) => {
          res.items.forEach((element: any) => {
            const breed: PecGenericFilterList = {
              fieldLabel: element.description,
              fieldValue: element.breedId,
            };
            this.filterBreed.push(breed);
          });
        },
        (e) => {
        }
      );
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: '600px',
      data: this.filterFields,
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        for (const element of value) {
          for (const itemFilterField in this.filterFields) {
            if (
              element.fieldName === this.filterFields[itemFilterField].fieldName
            ) {
              this.filterFields[itemFilterField].fieldValue =
                element.fieldValue;
              this.modelFilter[this.filterFields[itemFilterField].fieldName] =
                element.fieldValue;
            }
          }
        }
        this.methodExport = 'animal';
        this.showButtonExport = true;
      }
    });
  }

  openFilterModalRebanho() {
    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: '600px',
      data: this.filterFieldsRebanho,
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value) {
        this.methodExport = 'rebanho';
        this.showButtonExport = true;
        this.modelRebanho.date = `${value[0].fieldValue.split('/')[2]}/${
          value[0].fieldValue.split('/')[1]
        }/${value[0].fieldValue.split('/')[0]}`;
      }
    });
  }

  validFilters(filter: any) {
    return `${filter.fieldValue}`;
  }

  openFilterIdentifier() {
    this.setFilterFieldsIdentifier();

    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: '350px',
      data: this.filterFieldsIdentifier,
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value) {
        let condition: string;
        let identifier: string;
        let identifierValue: string;

        value.forEach((element: any) => {
          if (element.fieldName == 'identifier') {
            identifier = element.fieldValue;
          }
          if (element.fieldName == 'condition') {
            condition = element.fieldValue;
          }
          if (element.fieldName == 'value') {
            identifierValue = element.fieldValue;
          }
        });
        this.filtersResponse = {
          fieldName: `${identifier} ${condition}`,
          fieldValue: identifierValue,
        };
        this.filterIdentifier = {
          identifier,
          condition,
          value: identifierValue,
        };
      }
    });
  }

  removeFilter() {
    this.filtersResponse.fieldName = null;
    this.filtersResponse.fieldValue = null;
    this.filterIdentifier = null;
  }

  setFilterFieldsIdentifier() {
    const identifierList: PecGenericFilterList[] = [
      { fieldLabel: 'SISBOV', fieldValue: 'SISBOV' },
      { fieldLabel: 'IDE', fieldValue: 'IDE' },
      { fieldLabel: 'IDV', fieldValue: 'IDV' },
    ];
    const conditionList: PecGenericFilterList[] = [
      { fieldLabel: 'valor igual', fieldValue: '=' },
      { fieldLabel: 'valor contém', fieldValue: '%' },
    ];
    this.filterFieldsIdentifier = [
      {
        fieldName: 'identifier',
        type: GenericFilterTypeEnum.SELECT,
        label: 'Selecione o tipo de identificador',
        fieldValue: '',
        valuesList: identifierList,
      },
      {
        fieldName: 'condition',
        type: GenericFilterTypeEnum.SELECT,
        label: 'Selecione a condição para pesquisa',
        fieldValue: '',
        valuesList: conditionList,
      },
      {
        fieldName: 'value',
        type: GenericFilterTypeEnum.TEXT,
        label: 'Valor para pesquisa',
        fieldValue: '',
      },
    ];
  }

  exportToExcel(method: string) {
    if (method === 'animal') {
      this.isLoading = true;
      this.animalService.exportAnimals(this.modelFilter).subscribe(
        (value) => {
          const url = window.URL.createObjectURL(value);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.href = url;
          a.download = `Relatório Geral ${moment().format('DD/MM/YYYY HH:MM')}`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.isLoading = false;
          this.showButtonExport = false;
          this.methodExport = '';
        },
        (e) => {}
      );
    } else if (method === 'rebanho') {
      this.isLoading = true;
      this.animalService.exportRebanho(this.modelRebanho).subscribe(
        (value) => {
          const url = window.URL.createObjectURL(value);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.href = url;
          a.download = `Relatório Rebanho ${moment().format(
            'DD/MM/YYYY HH:MM'
          )}`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.isLoading = false;
          this.showButtonExport = false;
          this.methodExport = '';
        },
        (e) => {}
      );
    }
  }

  importExcelUpdated() {
    this.isLoading = true;
    const dialogRef = this.dialog.open(PecUploadFileComponent, {
      width: '800px',
      data: {
        message: `Atualização de animais ${this.contextService.getCurrentDescriptionFarm()}`,
      },
    });
    dialogRef.afterClosed().subscribe(
      (file: File[]) => {
        this.isLoading = false;

        if (file.length > 0) {
          this.routinesFileXlsxService
            .updateAnimalsWithFile(this.contextService.getFarm(), file[0])
            .subscribe(
              (response) => {
                this.isLoading = false;
                this.openSnack.snackbarSuccess(response.statusText);
                const url = window.URL.createObjectURL(response.body);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.href = url;
                a.download = `Atualização Animal - ${this.contextService.getCurrentDescriptionFarm()}_${moment().format(
                  'DD-MM-YYYY'
                )}`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              },
              (e: any) => {
                const url = window.URL.createObjectURL(e.error);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.href = url;
                a.download = `Atualização Animal - ${this.contextService.getCurrentDescriptionFarm()}_${moment().format(
                  'DD-MM-YYYY'
                )}`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              }
            );
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
