import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'personalIdentifier'
})
export class PersonalIdentifierPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }
    const newValueArr = value.split(' ');
    let newValue = '';

    if (newValueArr.length > 1) {
      for (const v of newValueArr) {
        newValue += v;
      }
    } else {
      newValue = value;
    }

    newValue = newValue.replace(/[^0-9]/g, '')

    if (newValue.length === 11) {

      let arrAux = newValue.split('');
      newValue = arrAux.splice(0, 3).toString() + '.';
      newValue += arrAux.splice(0, 3).toString() + '.';
      newValue += arrAux.splice(0, 3).toString() + '-';
      newValue += arrAux.splice(0, 2).toString();
      return newValue.replace(/,/g, '');

    } else if (newValue.length === 14) {

      let arrAux = newValue.split('');

      newValue = arrAux.splice(0, 2).toString() + '.';
      newValue += arrAux.splice(0, 3).toString() + '.';
      newValue += arrAux.splice(0, 3).toString() + '/';
      newValue += arrAux.splice(0, 4).toString() + '-';
      newValue += arrAux.splice(0, 2).toString();

      return newValue.replace(/,/g, '');

    } else {
      if (newValue) {
        return newValue
      } else {
        return ''
      }
    }
  }
}
