<div class="container scroll">
  <section class="flex center">
    <span class="spacer-left"></span>
    <h3>Animal</h3>
    <span class="spacer-right"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item color="primary" (click)="importExcelUpdated()">
        <mat-icon>upload_file</mat-icon>
        <span>Atualizar Animais(Importe Excel)</span>
      </button>
    </mat-menu>
    <button
      mat-raised-button
      color="primary"
      class="filter"
      (click)="openFilterIdentifier()"
    >
      <img
        class="nav-icon-filter"
        src="../../../../../assets/nav-icons/filter.svg"
        title="Filtro"
        alt="Item de menu para filtro da tabela de animais"
      />
      <span>Filtro</span>
    </button>
    <button mat-raised-button color="primary" [matMenuTriggerFor]="reports">
      <mat-icon>description</mat-icon>
      <span> Relatórios</span>
    </button>
    <mat-menu #reports="matMenu">
      <button mat-menu-item (click)="openFilterModal()">
        <mat-icon>dashboard</mat-icon>
        <span>Animais</span>
      </button>
      <button mat-menu-item (click)="openFilterModalRebanho()">
        <mat-icon>fact_check</mat-icon>
        <span>Rebanho por período</span>
      </button>
    </mat-menu>
  </section>

  <div class="container">
    <div *ngIf="filtersResponse?.fieldValue">
      <mat-grid-list cols="1" rowHeight="50px">
        <mat-chip-list
          class="example-chip"
          cdkDropList
          cdkDropListOrientation="horizontal"
        >
          <div>
            <mat-chip
              *ngIf="filtersResponse.fieldValue"
              selected
              color="primary"
              [removable]="true"
              (removed)="removeFilter()"
            >
              {{ filtersResponse.fieldName }} {{ filtersResponse.fieldValue }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>
      </mat-grid-list>
    </div>
    <div class="mat-elevation-z3">
      <pec-animal-list [filterIdentifier]="filterIdentifier"></pec-animal-list>
    </div>
    <div class="example-flex-container" *ngIf="showButtonExport">
      <button
        mat-fab
        color="primary"
        (click)="exportToExcel(methodExport)"
        *ngIf="isLoading == false"
      >
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <mat-spinner
        *ngIf="isLoading == true"
        color="primary"
        [diameter]="45"
      ></mat-spinner>
    </div>
  </div>
</div>
