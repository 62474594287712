<mat-card class="aj-tb">
    <table mat-table [dataSource]="dataSourceBuyingAndSellingDatas">
      <ng-container matColumnDef="specification">
        <th mat-header-cell *matHeaderCellDef> Especificação</th>
        <td mat-cell *matCellDef="let element"> {{element.specification}} </td>
      </ng-container>

      <ng-container matColumnDef="animalQuantity">
        <th mat-header-cell *matHeaderCellDef> Número de Animais</th>
        <td mat-cell *matCellDef="let element"> {{element.animalQuantity | number}} </td>
      </ng-container>

      <ng-container matColumnDef="aliveWeightAverageInKg">
        <th mat-header-cell *matHeaderCellDef> Peso Vivo (Kg)</th>
        <td mat-cell *matCellDef="let element"> {{element.aliveWeightAverageInKg | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="deadWeightAverageInKg">
        <th mat-header-cell *matHeaderCellDef> Peso Morto (Kg)</th>
        <td mat-cell *matCellDef="let element"> {{element.deadWeightAverageInKg | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="deadWeightAverageInArroba">
        <th mat-header-cell *matHeaderCellDef> Peso Morto (@)</th>
        <td mat-cell *matCellDef="let element"> {{element.deadWeightAverageInArroba | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="totalWeightInArroba">
        <th mat-header-cell *matHeaderCellDef> Total de Arrobas Comercializadas</th>
        <td mat-cell *matCellDef="let element"> {{element.totalWeightInArroba | number:'1.2-2'}} </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!isLoading" class="mat-cell" colspan="6">Aguarde, Calculando Informações!</td>
        <td *ngIf="isLoading" class="mat-cell" colspan="6">
          <pec-list-loading></pec-list-loading>
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsBuyAndSell"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsBuyAndSell;"></tr>
    </table>
  </mat-card>

