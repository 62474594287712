import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormTypeEnum } from "src/app/enums/FormTypeEnum";
import { ParamsInterface } from "src/app/interfaces/params/ParamsInterface";

@Component({
  selector: "app-integration-forms",
  templateUrl: "./integration-forms.component.html",
  styleUrls: ["./integration-forms.component.scss"],
})
export class IntegrationFormsComponent implements OnInit {
  @Input() formType: FormTypeEnum;
  @Input("integrationType") integrationInput: ParamsInterface;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() integrationOutput: EventEmitter<ParamsInterface> =
    new EventEmitter<ParamsInterface>();
  integrationType: ParamsInterface;

  integrationsFormGroup = new FormGroup({
    classeinicial: new FormControl("", [Validators.required]),
    classefinal: new FormControl("", [Validators.required]),
    status: new FormControl("", [Validators.required]),
    mes: new FormControl("", [Validators.required]),
    conta_sanitario: new FormControl("", [Validators.required]),
    excecaocldebito: new FormControl("", [Validators.required]),
    excecaoclcredito: new FormControl("", [Validators.required]),
  });

  constructor() {}

  ngOnInit() {
    if (this.formType === FormTypeEnum.VIEW) {
      this.integrationsFormGroup.disable();
    } else if (
      this.formType === FormTypeEnum.CREATE ||
      this.formType === FormTypeEnum.EDIT
    ) {
      this.integrationsFormGroup.valueChanges.subscribe((value) => {
        if (this.integrationsFormGroup.valid) {
          this.isValid.emit(this.integrationsFormGroup.valid);
          this.integrationOutput.emit({
            ...value,
          });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty("integrationInput") &&
      !this.integrationsFormGroup.dirty &&
      !this.integrationsFormGroup.touched
    ) {
      if (changes.integrationInput.currentValue) {
        this.integrationType = changes.integrationInput.currentValue;
        this.setIntegrationValue();
        this.integrationsFormGroup.markAsTouched();
      }
    }
  }

  setIntegrationValue() {
    this.integrationsFormGroup.controls.classeinicial.setValue(
      this.integrationType.classeinicial
    );
    this.integrationsFormGroup.controls.classefinal.setValue(
      this.integrationType.classefinal
    );
    this.integrationsFormGroup.controls.status.setValue(
      this.integrationType.status
    );
    this.integrationsFormGroup.controls.mes.setValue(this.integrationType.mes);
    this.integrationsFormGroup.controls.conta_sanitario.setValue(this.integrationType.conta_sanitario);
    this.integrationsFormGroup.controls.excecaocldebito.setValue(
      this.integrationType.excecaocldebito
    );
    this.integrationsFormGroup.controls.excecaoclcredito.setValue(
      this.integrationType.excecaoclcredito
    );
  }
}
