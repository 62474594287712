import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { ProductionValueService } from 'src/app/services/commercial/production-value.service';
import { ContextService } from 'src/app/services/context/context.service';
import { FarmService } from 'src/app/services/farm/farm/farm.service';
import { CattlePositionCalculousComponent } from '../components/cattle-position-calculous/cattle-position-calculous.component';


interface ArrayProductionDatas {
  positionAnimalTotalWeightDifference: number,

}

@Component({
  selector: 'app-production-values-index',
  templateUrl: './production-values-index.component.html',
  styleUrls: ['./production-values-index.component.scss']
})
export class ProductionValuesIndexComponent implements OnInit, OnChanges {

  @Output() initialDate: any;
  @Output() finalDate: any;

  @Output() positionAnimalTotalWeightDifference: number;
  @Output() atualCattle: number;
  @Output() sellWeightInArroba: number;
  @Output() revenueSelling: number;
  @Output() buyWeightInArroba: number;

  @Output() params: any;
  @Output() productionArea: any;

  isShowContribution: boolean = false;
  isShowProduction: boolean = false;
  isGenerateExcel: boolean = false;

  loading: boolean;

  farmId: number;

  initialDateMock = '2021-09-01'

  datas: any = {
    productionArea: null,
    variableCost: null,
    aquisitionCost: null,
    sellRevenue: null,
    fixedCost: null,
    financialExpense: null,
    arrobaCostStock: null,
  }

  productionValueFormGroup = new FormGroup({
    initialDate: new FormControl(''),
    finalDate: new FormControl(''),
    productionArea: new FormControl(''),
    variableCost: new FormControl(''),
    aquisitionCost: new FormControl(''),
    sellRevenue: new FormControl(''),
    fixedCost: new FormControl(''),
    financialExpense: new FormControl(''),
    arrobaCostStock: new FormControl(''),
  })


  constructor(
    private productionValueService: ProductionValueService,
    public dialog: MatDialog,
    public contextService: ContextService,
    public farmService: FarmService,
    private snackBar: SnackbarComponent,

  ) { }

  ngOnInit(): void {

    this.farmId = this.contextService.getFarm();
    this.setFormValue();
    this.loading = false;

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  receivePositionDatas(event: any) {
    this.positionAnimalTotalWeightDifference = event[0].ftotalWeightInArroba - event[0].itotalWeightInArroba;
    this.atualCattle = event[0].ftotalWeightInArroba;
  }

  receiveBuyAndSellingDatas(event: any) {

    for (let e of event) {
      if (e.specification == 'Venda') {
        this.sellWeightInArroba = e.totalWeightInArroba;
        this.datas.sellRevenue = e.totalSaleValue;
      } else if (e.specification == 'Compra') {
        this.buyWeightInArroba = e.totalWeightInArroba;
      }
    }
    // this.getCosts();
    // this.getFixedCostDatas();
  }

  setFormValue(): void {
    this.productionValueFormGroup.controls.initialDate.setValue(this.initialDate);
    this.productionValueFormGroup.controls.finalDate.setValue(this.finalDate);
    this.productionValueFormGroup.controls.productionArea.setValue(this.datas.productionArea);
    this.productionValueFormGroup.controls.variableCost.setValue(this.datas.variableCost);
    this.productionValueFormGroup.controls.aquisitionCost.setValue(this.datas.aquisitionCost);
    this.productionValueFormGroup.controls.sellRevenue.setValue(this.datas.sellRevenue);
    this.productionValueFormGroup.controls.fixedCost.setValue(this.datas.fixedCost);
    this.productionValueFormGroup.controls.financialExpense.setValue(this.datas.financialExpense);
    this.productionValueFormGroup.controls.arrobaCostStock.setValue(this.datas.arrobaCostStock);
  }

  getParams() {
    this.params = this.datas;
    this.productionArea = this.datas.productionArea;
    this.isShowContribution = true;
    this.isShowProduction = true;
    this.isGenerateExcel = true;
  }

  getCosts() {
    this.productionValueService.allCosts(this.farmId, this.initialDate, this.finalDate).subscribe((value) => {
      this.datas.aquisitionCost = value.sumAllBuying;
      this.datas.variableCost = value.sumAll - value.sumAllBuying;

    }, (error) => {
    })
  }

  getFixedCostDatas() {

    this.productionValueService.fixedCostDatasInAPeriod(this.initialDate, this.finalDate, this.farmId).subscribe(values => {
      let totalFixedCost = 0
      for (let value of values.items.fixedCostDatas) {
        totalFixedCost = totalFixedCost + +value.value;
      }

      this.datas.fixedCost = totalFixedCost;
    })
  }

  positionCalculous(){

    this.dialog.open(CattlePositionCalculousComponent, {
      width: '400px',
    });

  }

  generateExcel() {
    let datasForReport = {
      farmId: this.farmId,
      initialDate: this.initialDate,
      finalDate: this.finalDate,
      productionArea: this.params.productionArea,
      variableCost: this.params.variableCost,
      aquisitionCost: this.params.aquisitionCost,
      sellRevenue: this.params.sellRevenue,
      fixedCost: this.params.fixedCost,
      financialExpense: this.params.financialExpense,
      arrobaCostStock: this.params.arrobaCostStock,
    }

    this.loading = true;
    this.productionValueService.generateProductionValueExcel(datasForReport).subscribe(
      (value) => {
        const url = window.URL.createObjectURL(value);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display:none");
        a.href = url;
        a.download = `Relatório VBP  ${moment().format(
          "DD/MM/YYYY HH:MM"
        )}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.loading = false;

      },
      (err) => {
        this.snackBar.snackbarError("Erro ao gerar relatório");
        this.loading = false;
      }
    );
  }
}
