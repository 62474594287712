<div class="scroll">
  <section class="flex center">
    <button mat-icon-button [routerLink]="'../../'" class="voltar">
      <mat-icon>keyboard_backspace</mat-icon>
      Voltar
    </button>
    <span class="spacer-left"></span>
    <h3>Resumo de Abate</h3>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
    >
      <mat-icon class="icon">more_vert</mat-icon>
    </button>
    <span class="toolbar-spacer"></span>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openModal()">
        <mat-icon>description</mat-icon>
        <span>Incluir Dados Para Cálculo</span>
      </button>
    </mat-menu>
    <span class="spacer"></span>
  </section>

  <div class="container" *ngIf="params">
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-chip-list
        class="example-chip"
        cdkDropList
        cdkDropListOrientation="horizontal"
      >
        <div *ngFor="let filter of paramsData">
          <mat-chip
            color="primary"
            [removable]="true"
            (removed)="remove(filter)"
          >
            {{ filter.area }}: Peso = {{ filter.inputWeight }}Kg Data =
            {{ filter.inputDate | date : "dd/MM/yyyy" }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </div>
      </mat-chip-list>
    </mat-grid-list>
  </div>

  <div class="container">
    <mat-card>
      <mat-tab-group mat-stretch-tabs>
        <mat-tab label="Resumo por Lote">
          <app-slaughtered-lot-animals></app-slaughtered-lot-animals>

          <div *ngIf="loading">
            <pec-list-loading></pec-list-loading>
          </div>
        </mat-tab>
        <mat-tab label="Resumo por Animal">
          <app-slaughtered-animals></app-slaughtered-animals>

          <div *ngIf="loading">
            <pec-list-loading></pec-list-loading>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
