import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AnimalOutputMovementInterface } from '../../interfaces/animalMovement/AnimalOutputMovementInterface'
import {AuthService} from '../auth/auth.service';
import PecControlHeaderRequest from '../../utils/PecControlHeaderRequest';
import {SERVER_URL} from '../../config/env';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class AnimalOutputMovementService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) {
  }

  farmId = this.contextService.getFarm();

  create(movement: AnimalOutputMovementInterface): Observable<any> {
    movement.farmId = this.farmId;
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/animalOutputMovement`, {movement}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animalOutputMovement?page=${page}&limit=${limit}&farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animalOutputMovement/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(movement: AnimalOutputMovementInterface): Observable<any> {
    movement.farmId = this.farmId;
    return this.http.put(`${this.authService.getUrlBackEnd()}/api/animalOutputMovement`, {
      movement
    }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/animalOutputMovement/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
