import { Injectable } from "@angular/core";
import * as moment from "moment";
import { ContextService } from "../services/context/context.service";
import { FarmService } from "../services/farm/farm/farm.service";

@Injectable({
    providedIn: 'root', // <---- Adiciona isto ao serviço
})
export class CurrentProductionPeriod {

    farmId: number;

    dates: any = [];


    constructor(
        public contextService: ContextService,
        private farmService: FarmService
    ) {

        this.farmId = this.contextService.getFarm();


    }
    async setDates(): Promise<any> {

        
        //Data de Início do ano Safra 
        await this.farmService.getById(this.farmId).subscribe(farm => {

            let initialProductionDate = moment(farm.items.initialProductionDate).format('yyyy-MM-DD')

            this.dates.push(initialProductionDate)

            //Data Final (mês anterior da data atual)
            let currentDate = moment(new Date()).format('MM')
            let currentYear = moment(new Date()).format('yyyy')

            let month = +currentDate - 1
            let finalDate = moment(`${currentYear}-0${month}-01`).endOf('month').format('yyyy-MM-DD')
            this.dates.push(finalDate)

            
        })
        
        return this.dates
    }


}