import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ViacepService} from '../../../services/viacep/viacep.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormTypeEnum} from '../../../enums/FormTypeEnum';
import {LocationService} from '../../../services/config/location/location.service';
import {startWith, map, filter} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'pec-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnChanges {

  @Input() requireds = {
    isCountryRequired: false,
    isCEPRequired: false,
    isStateRequired: false,
    isCityRequired: false,
    isDistrictRequired: false,
    isStreetRequired: false,
    isAddressNumberRequired: false,
    isComplementRequired: false
  };
  @Input() address: Address;
  @Input() formType: FormTypeEnum;

  @Output() getAddress: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  filteredCountryOptions: Observable<any[]>;
  filteredStateOptions: Observable<any[]>;
  filteredCountyOptions: Observable<any[]>;

  CEPLoading = false;
  newAddress: Address;

  // abriga os timeouts resonsáveis por validar o input do estado
  stateValidate: any = null;

  public countries: any = [];
  public states: any = [];
  public counties: any = [];

  addressForm = new FormGroup({
    id: new FormControl(),
    country: new FormControl({id: 30, nome: 'Brasil'}, [Validators.required]),
    countryId: new FormControl('30'),
    state: new FormControl('', [Validators.required]),
    stateId: new FormControl(),
    county: new FormControl('', [Validators.required]),
    countyId: new FormControl(),
    CEP: new FormControl(),
    district: new FormControl(),
    description: new FormControl(),
    number: new FormControl(),
    complement: new FormControl(),
  });
  timerCep: number = null;

  constructor(
    private viacepService: ViacepService,
    private locationService: LocationService
  ) {
  }

  ngOnInit() {

    this.checkRequired();
    this.loadEntities();

    if (this.formType === FormTypeEnum.CREATE || this.formType === FormTypeEnum.EDIT) {
      this.addressForm.valueChanges.subscribe(value => {
        this.formChanges(value);
      });
    }

    if (this.formType === FormTypeEnum.EDIT || this.formType === FormTypeEnum.VIEW) {
      if (this.formType === FormTypeEnum.VIEW) {
        this.addressForm.disable();
      }
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('address') && !this.addressForm.dirty) {
      this.newAddress = changes.address.currentValue;
      if (this.newAddress) {
        this.setFormValues();
      }
    }
  }

  formChanges(v: any) {
    this.setAddress(v);
    if (this.addressForm.touched) {
      this.isFormValid.emit(this.addressForm.valid);
    }
  }

  /**
   * @description Carrega os objetos necessários para a utilização do componente
   */
  async loadEntities(): Promise<any> {
    const countries = await this.locationService.getCountry(1, 9999).toPromise();
    this.countries = Array.from(countries);
    this.startAutoCompletes();

    const states = await this.locationService.getState(1, 9999).toPromise();
    this.states = Array.from(states);
    this.startAutoCompletes();

    this.setFormValues();

  }

  onCepChange(cep: string): void {
    if (this.formType !== FormTypeEnum.VIEW) {
      if (this.timerCep) {
        clearTimeout(this.timerCep); // limpa a execução da busca pelo cep
      }
      // @ts-ignore
      this.timerCep = this.getCep(cep);
    }
  }

  getCep(cep: string) {
    return setTimeout(() => {
      const c = String(cep).replace('-', '');
      if (c.length === 8) {
        this.CEPLoading = true;
        this.addressForm.disable();
        this.viacepService.getCEP(String(c))
          .subscribe(value => {
            this.addressForm.controls.state.setValue(value.uf);
            this.addressForm.controls.conuty.setValue(value.localidade);
            this.addressForm.controls.district.setValue(value.bairro);
            this.addressForm.controls.street.setValue(value.logradouro);
            this.addressForm.controls.complement.setValue(value.complemento);

            this.CEPLoading = false;
            this.addressForm.enable();
          }, error => {
            this.CEPLoading = false;
            this.addressForm.enable();
          });
      }
    }, 1000);

  }

  setFormValues() {
    // todo refatorar
    if (this.newAddress) {


      this.states.some((v: { UF: string; }) => {
        if (v.UF === this.newAddress.state) {
          this.addressForm.get('state').setValue(v);
          this.addressForm.get('stateId').setValue(v.UF);
          this.locationService.getCounty(1, 9999, [{
            fieldName: 'state',
            fieldValue: v.UF
          }])
            .subscribe(value => {
              this.counties = Array.from(value);
              this.startAutoCompletes();
              // tslint:disable-next-line:no-shadowed-variable
              this.counties.some((v: { id: number; }) => {
                if (v.id === Number(this.newAddress.county)) {
                  this.addressForm.get('county').setValue(v);
                  this.addressForm.get('countyId').setValue(v.id);
                }
              });
            });
        }
      });


      this.countries.some((v: { id: number; }) => {
        if (v.id === Number(this.newAddress.country)) {
          this.addressForm.get('country').setValue(v);
          this.addressForm.get('countryId').setValue(v.id);
        }
      });
      this.addressForm.controls.id.setValue(this.newAddress.id);
      this.addressForm.controls.CEP.setValue(this.newAddress.CEP);
      this.addressForm.controls.district.setValue(this.newAddress.district);
      this.addressForm.controls.description.setValue(this.newAddress.description);
      this.addressForm.controls.complement.setValue(this.newAddress.complement);
      this.addressForm.controls.number.setValue(this.newAddress.number);
    }
  }

  setAddress(address: Address) {
    if (this.addressForm.dirty) {
      this.getAddress.emit(address);
    }
  }

  checkRequired() {
    // if (this.requireds.isCountryRequired) {
    //   this.addressForm.get('country').setValidators([Validators.required]);
    // }
    // if (this.requireds.isCEPRequired) {
    //   this.addressForm.get('CEP').setValidators([Validators.required]);
    // }
    // if (this.requireds.isStateRequired) {
    //   this.addressForm.get('state').setValidators([Validators.required]);
    // }
    // if (this.requireds.isCityRequired) {
    //   this.addressForm.get('county').setValidators([Validators.required]);
    // }
    // if (this.requireds.isDistrictRequired) {
    //   this.addressForm.get('district').setValidators([Validators.required]);
    // }
    // if (this.requireds.isStreetRequired) {
    //   this.addressForm.get('street').setValidators([Validators.required]);
    // }
    // if (this.requireds.isComplementRequired) {
    //   this.addressForm.get('complement').setValidators([Validators.required]);
    // }
    // if (this.requireds.isAddressNumberRequired) {
    //   this.addressForm.get('addressNumber').setValidators([Validators.required]);
    // }
  }

  startAutoCompletes(): void {
    this.filteredCountryOptions = this.addressForm.get('country').valueChanges.pipe(
      startWith(''),
      map(value => this._handleCountryFilter(value))
    );
    this.filteredStateOptions = this.addressForm.get('state').valueChanges.pipe(
      startWith(''),
      map(value => this._handleStateFilter(value))
    );
    this.filteredCountyOptions = this.addressForm.get('county').valueChanges.pipe(
      startWith(''),
      map(value => this._handleCountyFilter(value))
    );
  }

  private _handleCountryFilter(value: any): string[] {
    let filterValue = value;
    if (typeof filterValue !== 'string') {
      filterValue = value.nome;
    }
    return this.countries.filter((option: { nome: string; }) => option.nome.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
  }

  private _handleStateFilter(value: any): string[] {
    let filterValue = value;    
    if (typeof filterValue !== 'string') {
      filterValue = value.nome;
    }
    return this.states.filter((option: { nome: string; }) => option.nome.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
  }

  private _handleCountyFilter(value: any): string[] {
    let filterValue = value;
    const UF = this.addressForm.get('stateId').value

    if (typeof filterValue !== 'string') {
      filterValue = value.name;
    }
    return this.counties.filter((option: { name: string; state: string }) => 
      option.state === UF &&  option.name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
  }

  displayCountry(country: any): string {
    return country && country.nome ? country.nome : '';
  }

  displayState(state: any): string {
    return state && state.nome ? state.nome : '';
  }

  displayCounty(county: any): string {
    return county && county.name ? county.name : '';
  }

  handleSelectCountry() {
    this.addressForm.get('countryId').setValue(this.addressForm.get('country').value.id);
  }

  handleSelectState() {
    const state = this.addressForm.get('state').value;
    this.addressForm.get('stateId').setValue(state.UF);
    this.locationService.getCounty(1, 9999, [{
      fieldName: 'state',
      fieldValue: state.UF
    }])
      .subscribe(value => {
        this.counties = Array.from(value);
        this.startAutoCompletes();
      });
  }

  handleSelectCounty() {
    const county = this.addressForm.get('county').value;
    this.addressForm.get('countyId').setValue(county.id);
  }

  validateCountry(e: FocusEvent): void {
    // @ts-ignore
    if (!this.countries.some((v: any) => v.nome === e.currentTarget.value)) {
      this.addressForm.get('country').setValue('');
    }
  }

  validateCounty(e: FocusEvent): void {
    // @ts-ignore
    if (!this.counties.some((v: any) => v.nome === e.currentTarget.value)) {
      this.addressForm.get('county').setValue('');
    }
  }

  // todo refatorar - utilizdao para consertar um bug no momento em que seleciona o estado
  onStateFocus() {
    this.stateValidate = null;
  }

}

export interface Address {
  id?: number;
  CEP: string;
  country: string;
  state: string;
  county: string;
  district?: string;
  street?: string;
  description?: string;
  number?: number;
  addressNumber?: string;
  complement?: string;
}
