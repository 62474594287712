<div id="btnFilter" *ngIf="filtersSelected">
  <button mat-raised-button color="primary" (click)="removeFilter()">
    <mat-icon>trash</mat-icon>
    Remover Filtros
  </button>

</div>

<div class="mat-elevation-z3">
  <div class="container" style="position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)" *ngIf="loading">
    <mat-spinner>Aguarde</mat-spinner>
  </div>

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="iconclosed" *ngIf="element.status == 1">check_circle</mat-icon>
        <mat-icon class="iconIncomplete" *ngIf="element.status == 2" alt="Não concluída">info</mat-icon>
        <mat-icon class="iconOpened" *ngIf="element.status == 3" alt="Operação Estornada">remove_circle</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="data">Data</th>
      <td mat-cell *matCellDef="let element">{{ element.data }}</td>
    </ng-container>

    <ng-container matColumnDef="documento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="documento">Documento</th>
      <td mat-cell *matCellDef="let element">{{ element.documento }}</td>
    </ng-container>

    <ng-container matColumnDef="produto">
      <th mat-header-cell *matHeaderCellDef>Produto</th>
      <td mat-cell *matCellDef="let element">{{ element.produto }}</td>
    </ng-container>

    <ng-container matColumnDef="origem">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="origem">Origem</th>
      <td mat-cell *matCellDef="let element">{{ element.origem }}</td>
    </ng-container>

    <ng-container matColumnDef="length">
      <th mat-header-cell *matHeaderCellDef>Qtde Operações</th>
      <td mat-cell *matCellDef="let element">{{ element.length }}</td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Opções</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu dedicated for options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="goToDetalhesByState(element)">
            <mat-icon>visibility</mat-icon>
            <span>Visualizar</span>
          </button>
          <button mat-menu-item (click)="revertOperation(element.documento)">
            <mat-icon>undo</mat-icon>
            <span>Reenviar operação</span>
          </button>
          <!-- <button mat-menu-item (click)="revertOperation(element.documento)">
            <mat-icon>redo</mat-icon>
            <span>Estornar operação</span>
          </button> -->
          <button mat-menu-item (click)="revertOperation(element.documento, true)">
            <mat-icon>remove_circle</mat-icon>
            <span>Marcar como estornado</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="disclaimer">
      <td mat-footer-cell *matFooterCellDef colspan="4">
        <mat-grid-list rowHeight="30px" cols="4">
          <mat-grid-tile colspan="1">
            Legendas:
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            Não concluídas
            <mat-icon class="iconIncomplete" alt="Não concluídas">info</mat-icon>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            Operações Estornadas
            <mat-icon class="iconOpened" alt="Operações Estornadas">remove_circle</mat-icon>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            Operações Finalizadas
            <mat-icon class="iconclosed" alt="Operações Finalizadas">check_circle</mat-icon>
          </mat-grid-tile>
        </mat-grid-list>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
    <!-- <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="example-first-footer-row"></tr> -->
  </table>

  <mat-paginator class="paginator"
  [length]="totalRecords"
  [pageSizeOptions]="[10, 25, 50, 100, totalRecords]" showFirstLastButtons
  (page)="onPageChange($event)" >
</mat-paginator>
</div>
