<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="'../'" style="margin-right: 60px">
      <mat-icon>keyboard_backspace</mat-icon>
      Voltar
    </button>
    <span class="toolbar-spacer"></span>
    <span>Animal - {{ animal?.animalId }}</span>
    <span class="toolbar-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container">
  <pec-animal-form [animal]="animal" [formType]="formType"></pec-animal-form>
</div>
