import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pec-cep-form',
  templateUrl: './cep-form.component.html',
  styleUrls: ['./cep-form.component.scss']
})
export class CepFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
