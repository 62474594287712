import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { AnimalInputMovementInterface } from "src/app/interfaces/animalMovement/AnimalInputMovementInterface";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";
import { ContextService } from "src/app/services/context/context.service";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AnimalInputMovementService } from "../../animalMovement/animal-input-movement.service";
import { AuthService } from "../../auth/auth.service";

type TInputOperationsReport = {
  farmId: number;
  initialDate: string;
  finalDate: string;
  inputMovementId: number;
};

@Injectable({
  providedIn: "root",
})
export class InputOperationsReportService
  implements ReportInterface<TInputOperationsReport, Blob>
{
  reportFilters: TInputOperationsReport;
  title: string;
  filterInputMovement: PecGenericFilterList[] = [];
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data inicial Ex. 1/1/2020",
      fieldValue: "",
      hint: "obrigatório",
      required: true,
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data final Ex.: 30/1/2020",
      fieldValue: "",
      hint: "obrigatório",
      required: true,
    },
    {
      fieldName: "inputMovementId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione o movimento de entrada",
      fieldValue: "",
      valuesList: this.filterInputMovement,
    },
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly contextService: ContextService,
    private readonly dialog: MatDialog,
    private readonly _bottomSheet: MatBottomSheet,
    private readonly inputMovementService: AnimalInputMovementService
  ) {
    this.title = "Operações de Entrada";
    this.getInputMovement();
  }

  getInputMovement() {
    this.inputMovementService.getAll(1, 100).subscribe((inputMovement) => {
      if (inputMovement.items) {
        inputMovement.items.forEach((element: AnimalInputMovementInterface) => {
          this.filterInputMovement.push({
            fieldLabel: element.description,
            fieldValue: String(element.inputMovementId),
          });
        });
      }
    });
  }

  openSmartReport() {
    this.reportFilters = {
      farmId: this.contextService.getFarm(),
      initialDate: null,
      finalDate: null,
      inputMovementId: null,
    };

    const data: TDataSmartReport<TInputOperationsReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };

    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Lista operações de entrada conforme filtros aplicados",
      description: [
        "Informações Contidas no relatório:",
        "-> Operações Realizadas no Período",
        "-> Quantidade de Animais por Operação",
        "-> Fornecedor",
        "-> Hora da Coleta",
        "-> Raça",
        "-> Sexo",
        "-> Idade em mêses",
        "-> Peso",
        "-> Aparte",
        "-> Área",
        "-> Características",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TInputOperationsReport): Observable<Blob> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/operationReports/inputOperationGeneralReport`,
      datas,
      {
        responseType: "blob",
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
