<div mat-dialog-content>
  <pec-common-filter
    [fields]="data"
    (getFilterFields)="setFilterFields($event)"
  ></pec-common-filter>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" class="closeBtn">
    Cancelar
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="filterFields"
    class="btnConfirm"
    cdkFocusInitial
  >
    Salvar
  </button>
</div>
