<form [formGroup]="inactiveAnimalForm">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <mat-grid-list rowHeight="100px" cols="1">
      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Data Observação</mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="observationDate"
            [matDatepicker]="observationDateCalendar"
          />
          <mat-error>
            <app-form-field-error
              [form-control]="inactiveAnimalForm.get('observationDate')"
            >
            </app-form-field-error>
          </mat-error>
          <mat-datepicker-toggle
            matSuffix
            [for]="observationDateCalendar"
          ></mat-datepicker-toggle>
          <mat-datepicker #observationDateCalendar></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Observação</mat-label>
          <input autocomplete="off" matInput formControlName="observation" />
          <mat-error>
            <app-form-field-error
              [form-control]="inactiveAnimalForm.get('observation')"
            >
            </app-form-field-error>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Alerta Coletor?</mat-label>
          <mat-select formControlName="observationAlert">
            <mat-option [value]="1">Sim</mat-option>
            <mat-option [value]="0">Não</mat-option>
          </mat-select>
          <mat-error>
            <app-form-field-error
              [form-control]="inactiveAnimalForm.get('observationAlert')"
            >
            </app-form-field-error>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      color="warn"
      (click)="close()"
      class="closeBtn"
      cdkFocusInitial
    >
      Cancelar
    </button>
    <button
      mat-button
      [disabled]="!inactiveAnimalForm.valid"
      (click)="save()"
      class="btnConfirm"
    >
      Confirmar
    </button>
  </div>
</form>
