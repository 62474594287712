import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commercial-selling-index',
  templateUrl: './commercial-selling-index.component.html',
  styleUrls: ['./commercial-selling-index.component.scss']
})
export class CommercialSellingIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
