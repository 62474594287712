import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { LoadingStatusService } from 'src/app/pages/nutrition/daily/components/ComplementarInformations/loading-status.service';
import { ProductionValueService } from 'src/app/services/commercial/production-value.service';

@Component({
  selector: 'app-cattle-position-calculous',
  templateUrl: './cattle-position-calculous.component.html',
  styleUrls: ['./cattle-position-calculous.component.scss']
})
export class CattlePositionCalculousComponent implements OnInit {

  date: Date;
  loading: boolean = false;

  dateFormGroup = new FormGroup({
    date: new FormControl('')
  })

  constructor(
    public dialogRef: MatDialogRef<any>,
    private productionValueService: ProductionValueService,
    private loadingService: LoadingStatusService,
    private snackBar: SnackbarComponent,
  ) { }

  ngOnInit(): void {
  }

  save(): void {      
    this.loading = true;
    this.productionValueService.cattlePosition(this.dateFormGroup.value.date).subscribe((e: any) => {
      
      this.dialogRef.close(true);
      this.loadingService.changeMessage(true);
      this.snackBar.snackbarSuccess(`Posição de Rebanho Calculado com Sucesso.`);

    })
    
  }

  close(): void {
    this.dialogRef.close(false);
    this.loadingService.changeMessage(false)
  }

}
