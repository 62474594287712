import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PecGenericFilterFields } from "../../../interfaces/utils/PecGenericFilterFields";
import { GenericFilterResponse } from "../pec-common-filter/interaces/GenericFilterResponse";

@Component({
  selector: "pec-common-filter-modal",
  templateUrl: "./pec-common-filter-modal.component.html",
  styleUrls: ["./pec-common-filter-modal.component.scss"],
})
export class PecCommonFilterModalComponent {
  filterFields: GenericFilterResponse[];
  titleModal: string = "Filtro";
  disabledButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<PecCommonFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PecGenericFilterFields[]
  ) {}

  setFilterFieldsValidated(isValidated: boolean) {
    if (isValidated) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  setFilterFields(fields: GenericFilterResponse[], title?: string) {
    this.filterFields = fields;
    if (title) {
      this.titleModal = title;
    }
  }

  confirm(): void {
    this.dialogRef.close(this.filterFields);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
