import { Injectable } from '@angular/core';
import { REPORT_BUTTON_EVENT, REPORT_CATEGORY, TReportIndex, TReportsIndex } from './report-index.service'

const INTEGRATION_ERP_REPORT_ID = {
  costERP: 1,  
}

@Injectable({
  providedIn: 'root'
})
export class ReportCategoryIntegrationERPService {
  private integrationERPReports: TReportsIndex
  
  constructor() { }

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    
  }

  getReportsIntegrationERP(): TReportsIndex {
    this.integrationERPReports = [
      {
        id: INTEGRATION_ERP_REPORT_ID.costERP,
        title: 'Custos ERP',
        description: 'Gera os dados custos integrado ao ERP.',
        category: REPORT_CATEGORY.INTEGRATION_ERP
      },      
    ]
    return this.integrationERPReports
  }


}
