import { HttpHeaders } from "@angular/common/http";

export default {
  getHeader(token: string): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      Authorization: `Bearer ${token}`,
    });
  },
};
