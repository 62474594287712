import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

export type TAnimalSaleResultReport = {
  initialDate: string;
  finalDate: string;
  farmId: number;
};

@Injectable({
  providedIn: "root",
})
export class AnimalSaleResultReportService
  implements ReportInterface<TAnimalSaleResultReport, any>
{
  farmId!: number;
  reportFilters: TAnimalSaleResultReport;
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data",
      fieldValue: "",
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data",
      fieldValue: "",
    },
  ];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {}

  openSmartReport(): void {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      initialDate: "",
      finalDate: "",
      farmId: this.farmId,
    };

    const data: TDataSmartReport<TAnimalSaleResultReport> = {
      title: "Resultado venda de animais",
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilterFields: this.reportFilterFields,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: "Resultado de venda de animais",
      subtitle:
        "Detalhes sobre vendas e pesos de animais por negócio, trazendo os dados de custos animal e valor de venda",
      description: [
        "Informações Contidas no relatório: ",
        "Aba de operação das vendas",
        "-> Operação de venda",
        "-> Data de Embarque",
        "-> Valores pagos",
        "-> Média de custos dos animais",
        "-> Média de valor de venda animal",
        "-> Aba Animals",
        "-> Identificação (SISBOV)",
        "-> Dias no confinamento",
        "-> GMD e GMD líquido",
        "-> Rendimentos de carcaça",
        "-> Preço da arroba",
        "-> Custo do animal",
        "-> Valor venda do animal",
        "-> Lucratividade",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TAnimalSaleResultReport): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/report/commercial/animalSaleResultReport`,
      datas,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
