
<div class="org" [formGroup]="formGroup">
        <mat-form-field class="org1">
            <mat-label>Faixa Etária</mat-label>
            <input matInput formControlName="description" />
        </mat-form-field>

        <mat-form-field class="org2">
            <mat-label>Machos</mat-label>
            <input matInput formControlName="maleValue" />
        </mat-form-field>

        <mat-form-field class="org2">
            <mat-label>Fêmeas</mat-label>
            <input matInput formControlName="femaleValue" />
        </mat-form-field>
</div>
