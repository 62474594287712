import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "../services/auth/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    let userLogged: any = this.authService.getSession();

    if (!userLogged) {
      this.authService.clearSession();
      this.router.navigate(["/login"]);
    }

    this.authService.checkAuthIsValid().subscribe(
      (e: any) => {
        userLogged.levels = e.levels;
        userLogged.reports = e.reports;
        this.authService.setSession(userLogged);
      },
      (err) => {
        if (err.status == 401) {
          this.authService.clearSession();
          this.router.navigate(["/login"]);
        }
      }
    );

    if (this.authService.isLoggedIn) {
      return true;
    }
  }
}
