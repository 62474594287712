import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericFilterResponse } from 'src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../auth/auth.service';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionValueService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) { }

  farmId = this.contextService.getFarm();
  
  getAllByDate(initialDate: any, finalDate: any): Observable<any> {
    
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/commercial/productionValue?initialDate=${initialDate}&finalDate=${finalDate}&farmId=${this.farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  cattlePosition(dates: any): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/productionValue`,
    {
      dates
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  buyingDatasInAPeriod(initialDate: any, finalDate: any, farmId: number): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/productionValue/buyingDatas`,
    {
      initialDate,
      finalDate,
      farmId
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  sellingDatasInAPeriod(initialDate: any, finalDate: any, farmId: number): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/productionValue/sellingDatas`,
    {
      initialDate,
      finalDate,
      farmId
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  fixedCostDatasInAPeriod(initialDate: any, finalDate: any, farmId: number): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/productionValue/fixedCostsDatas`,
    {
      initialDate,
      finalDate,
      farmId
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  allCosts(farmId: number, initialDate: any, finalDate: any): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/productionValue/getCost`,
    {
      farmId,
      initialDate,
      finalDate      
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  generateProductionValueExcel(datas: any): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/productionValue/productionValueReport`,
      { datas },
      {
        responseType: "blob",
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
