import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild
} from '@angular/router';
import {Observable} from 'rxjs';
import {ContextService} from '../services/context/context.service';
import {FarmService} from '../services/farm/farm/farm.service';
import {FarmInterface} from '../interfaces/farm/FarmInterface';

@Injectable({
  providedIn: 'root'
})
export class FarmGuard implements CanActivate, CanActivateChild {

  farms: FarmInterface[] = [];

  constructor(
    private contextService: ContextService,
    private router: Router,
    private farmService: FarmService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkFarmSection();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkFarmSection();
  }

  async checkFarmSection(): Promise<boolean> {
    const v = await this.farmService.getAll(1, 10).toPromise()
    this.farms = v.items;
    if (this.contextService.getFarm()) {
      return true;
    } else if (this.farms.length === 0) {
      return true;
    } else if (this.farms.length === 1){
      let farmId = this.farms.map((e: FarmInterface) => {
        let id = e.farmId
        return id
      }) 
      this.contextService.setFarm(Number(farmId))
      return true
    }
    this.router.navigate(['/farmSelection']);
    return false;
  }
}
