import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards/Auth.guard';
import { SessionGuard } from './guards/Session.gard';
import { FarmGuard } from './guards/farm.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard, FarmGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    canActivate: [SessionGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    pathMatch: 'full',
  },
  {
    path: 'farmSelection',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/context/farm-selection/farm-selection.module').then(
        (m) => m.FarmSelectionModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
