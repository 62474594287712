import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SERVER_URL} from '../../../config/env';
import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../auth/auth.service';
import {GenericFilterResponse} from '../../../components/shared/pec-common-filter/interaces/GenericFilterResponse';

import {ProductionPhaseInterface} from '../../../interfaces/nutrition/ProductionPhaseInterface';
import { ContextService } from '../../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionphaseService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService

  ) { }

  farmId = this.contextService.getFarm();

  create(productionPhase:ProductionPhaseInterface): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/productionPhase`, {productionPhase}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }


  getAll(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/productionPhase?page=${page}&limit=${limit}${filterQueryString}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(productionPhaseId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/productionPhase/${productionPhaseId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(productionPhase: ProductionPhaseInterface): Observable<any> {
    return this.http.put(`${this.authService.getUrlBackEnd()}/api/productionPhase`, {productionPhase}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
