import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";

@Component({
  selector: "app-operation-integration-list-view",
  templateUrl: "./operation-integration-list-view.html",
  styleUrls: ["./operation-integration-list-view.scss"],
})
export class OperationIntegrationListViewComponent implements OnInit {

  columns: string[] = [
    'status',
    'documento',

    'armazem',
    'centrocusto',
    'classevalor',
    'conta',

    'emissao',
    'empresa',
    'filial',

    'produto',
    'quantidade',

    'tipoMovimento',
    'tipoMovimentoEstorno',
    'type',
    'valor',
    'options',

  ];

  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private integrationService: FarmIntegrationService,
    private snackBar: SnackbarComponent
  ) { }

  nav = this.router.getCurrentNavigation();

  ngOnInit() {
    this.dataSource.data = this.nav.extras.state.moviments
  }

  //Operação para refazer
  revertOperation(doc: string) {
    this.integrationService.revertOperationsProtheus(doc).subscribe(
      () => {
        this.snackBar.snackbarSuccess("Reenviado com sucesso!");
        this.router.navigate([`./operation-integration`]);
      },
      (e) => {
        this.snackBar.snackbarError(e);
      }
    );
  }
}
