import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import { AuthService } from '../../../auth/auth.service';
import { ContextService } from '../../../context/context.service';
import { PecGenericFilterList } from 'src/app/interfaces/utils/PecGenericFilterList';
import { TruckService } from 'src/app/services/nutrition/truck/truck.service';

export type TFeedLoadReport = {
    farmId: number;
    truckId: number;
    date: Date;
}

@Injectable({
    providedIn: 'root'
})
export class FeedLoadReportService implements ReportInterface<TFeedLoadReport, Blob> {
    farmId!: number
    reportFilters: TFeedLoadReport
    title!: string
    filterTruck: PecGenericFilterList[] = [
        {fieldLabel: 'Todos', fieldValue: '0'}
    ]
    reportFilterFields: PecGenericFilterFields[] = [
        {
            fieldName: "date",
            type: GenericFilterTypeEnum.DATE,
            label: "Selecione a data carga",
            fieldValue: "",
            required: true,
            hint: 'Campo obrigatório',
        },
        {
            fieldName: "truckId",
            type: GenericFilterTypeEnum.SELECT,
            label: "Selecione o Caminhão",
            fieldValue: "",
            valuesList: this.filterTruck,
            required: true,
            hint: 'Campo obrigatório',
        },
    ];

    constructor(
        private http: HttpClient,
        private contextService: ContextService,
        private dialog: MatDialog,
        private authService: AuthService,
        private _bottomSheet: MatBottomSheet,
        private truckService: TruckService
    ) {
        this.title = 'Cargas dos tratos'
        this.getTruck()
    }

    getTruck() {
        this.truckService.getAll().subscribe(trucks => {
            trucks.items.forEach((element: any) => {
                this.filterTruck.push({
                    fieldLabel: element.indentifier,
                    fieldValue: element.truckId
                })
            })
        })
    }

    openSmartReport() {
        this.farmId = this.contextService.getFarm();
        this.reportFilters = {
            date: null,
            truckId: null,
            farmId: this.farmId
        }

        const data: TDataSmartReport<TFeedLoadReport> = {
            title: 'Carga do Trato',
            description: 'Selecione as opções para gerar o relatório',
            requiredFilters: true,
            reportFilterFields: this.reportFilterFields,
            reportFilters: this.reportFilters,
            service: this,
            reportTypes: [SMART_TYPE_REPORT.pdf]
        }
        this.dialog.open(SmartReportModalComponent, {
            width: CONFIG_SMART_REPORT.width,
            height: CONFIG_SMART_REPORT.height,
            data
        });
    }

    openInfoReport() {
        const data: HtmlData = {
            title: 'Carga do Trato',
            subtitle: 'Informações Contidas no relatório',
            description: [
                '-> Dieta',
                '-> Ingrediente',
                '-> Caminhão',
                '-> Sequência da carga',
                '-> Número do trato',
                '-> Quantidade Planejada',
                '-> Quantidade Fornecida',
            ]
        }
        this._bottomSheet.open(BottomSheetComponent, { data })
    }

    getReport(datas: TFeedLoadReport): Observable<Blob> {
        return this.http.post(
            `${this.authService.getUrlBackEnd()}/api/daily-feed-routine-planning/report/feed-load-ingredient`,
            datas,
            { responseType: "blob" }
        );
    }
}
