import {Component, HostListener, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthService} from './services/auth/auth.service';


import { Title } from '@angular/platform-browser';
import { Router, ActivationStart } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pec-control-frontend';
  userActivity: any;
  userInactive: Subject<any> = new Subject();

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit() {

    this.router.events.subscribe((events: any)  => {
      if (events instanceof ActivationStart) {
        if (events.snapshot.data.title) {
          this.titleService.setTitle(events.snapshot.data.title);
        } else {
          this.titleService.setTitle('Pec Code')
        }
      }
    })
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.authService.clearSession();
      window.location.reload();
    }, 30 * 60000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

}
