import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ProductionValueService } from 'src/app/services/commercial/production-value.service';
import { ContextService } from 'src/app/services/context/context.service';
import { FarmService } from 'src/app/services/farm/farm/farm.service';
import { CurrentProductionPeriod } from 'src/app/utils/productionPeriod';

@Component({
  selector: 'app-production-values-tables',
  templateUrl: './production-values-tables.component.html',
  styleUrls: ['./production-values-tables.component.scss']
})
export class ProductionValuesTablesComponent implements OnInit, OnChanges {

  @Input() initialDate: any;
  @Input() finalDate: any;

  @Output() positionDatas = new EventEmitter()

  //Colunas de Tabelas
  displayedColumnsPositionAnimal: string[] = [
    'ageGroup', 'ianimalStock', 'fanimalStock', 'istockInKg', 'fstockInKg', 'istockInArroba', 'fstockInArroba'
  ];
  farmId: number;

  iTotalPositionWeightInArroba = 0;
  fTotalPositionWeightInArroba = 0;

  dataSourceAnimalPosition = new MatTableDataSource<any>();

  constructor(
    private productionValueService: ProductionValueService,
    public currentProductionPeriod: CurrentProductionPeriod,
    private farmService: FarmService,
    public contextService: ContextService,


  ) { }

  ngOnInit() {
    this.farmId = this.contextService.getFarm();
  }

  getProductionValuesDatas(): void {

    this.productionValueService.getAllByDate(this.initialDate, this.finalDate).subscribe((values: any) => {

      this.dataSourceAnimalPosition.data = values.items;

      this.positionDatas.emit(values.items)

    }, error => {
    });
  }

  ngOnChanges(event: any) {

    if(event.initialDate){
      this.initialDate = event.initialDate.currentValue;
    }
    if(event.finalDate){
      this.finalDate = event.finalDate.currentValue;
    }

    if(this.initialDate && this.finalDate){
      this.getProductionValuesDatas()
    }
  }



}
