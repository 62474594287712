<div class="container scroll">
  <section class="flex center">
    <button mat-button [routerLink]="'../'">
      <mat-icon>keyboard_backspace</mat-icon>
      Voltar
    </button>
    <span class="spacer-left"></span>
    <h3>Incluindo Animais</h3>
    <span class="spacer-right"></span>

    <button mat-raised-button color="primary" (click)="create()">
      <mat-icon>save</mat-icon>
      Salvar
    </button>
  </section>

  <div class="container">
    <app-animal-temporary-balance-forms
      [formType]="formType"
      (getInformations)="setInputOperation($event)"
    ></app-animal-temporary-balance-forms>
  </div>
</div>
