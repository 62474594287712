<mat-card class="aj-tb">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="production">
      <th mat-header-cell *matHeaderCellDef> Produção de @</th>
      <td mat-cell *matCellDef="let element"> {{ element.productionArea ? (element.production | number:'1.2-2') : 0 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="productionArea">
      <th mat-header-cell *matHeaderCellDef> Área Produtiva (ha)</th>
      <td mat-cell *matCellDef="let element"> {{ element.productionArea ? (element.productionArea | number:'1.2-2') :
        0}} </td>
    </ng-container>

    <ng-container matColumnDef="productionQuantities">
      <th mat-header-cell *matHeaderCellDef> Quantitade Produzida (@/ha)</th>
      <td mat-cell *matCellDef="let element"> {{ element.productionArea ? (element.productionQuantities |
        number:'1.2-2') : 0}} </td>
    </ng-container>

    <!-- <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!loading" class="mat-cell" colspan="5">Nenhum dado encontrado</td>
        <td *ngIf="loading" class="mat-cell" colspan="5">
          <pec-list-loading></pec-list-loading>
        </td>
      </tr> -->
    <tr mat-header-row *matHeaderRowDef="displayedproductionDatas"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedproductionDatas;"></tr>
  </table>
</mat-card>