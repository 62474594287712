import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/Directives.module';

import { IntegrationRoutingModule } from '../integration/integration-routing.modules';
import {
  OperationIntegrationListViewComponent,
} from './components/operation-integration-list-view/operation-integration-list-view';
import {
  OperationIntegrationListComponent,
} from './components/operation-integration-list/operation-integration-list.component';
import { OperationIntegrationIndexComponent } from './operation-integration-index/operation-integration-index.component';
import { OperationIntegrationRoutingModule } from './operation-integration-routing.module';

@NgModule({
  declarations: [
    OperationIntegrationIndexComponent,
    OperationIntegrationListComponent,
    OperationIntegrationListViewComponent
  ],
  imports: [
    CommonModule,
    OperationIntegrationRoutingModule,
    IntegrationRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatDialogModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSortModule,
    SharedModule,
    ReactiveFormsModule,
    DirectivesModule,
  ]
})
export class OperationIntegrationModule { }
