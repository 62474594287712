import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { FormTypeEnum } from 'src/app/enums/FormTypeEnum';
import { AnimalTemporaryBalanceInterface } from 'src/app/interfaces/animal/AnimalTemporaryBalanceInterface';
import { AnimalTemporaryBalanceService } from 'src/app/services/animal/animal/animal-temporary-balance.service';
import { ContextService } from 'src/app/services/context/context.service';

@Component({
  selector: 'app-animal-temporary-balance-create',
  templateUrl: './animal-temporary-balance-create.component.html',
  styleUrls: ['./animal-temporary-balance-create.component.scss']
})
export class AnimalTemporaryBalanceCreateComponent implements OnInit {

  formType = FormTypeEnum.CREATE;
  formValid: boolean;
  animalTemporaryDatas: any;
  animalTemporaryId: number;

  farmId: number;


  constructor(
    private readonly contextService: ContextService,
    private animalTemporaryBalanceService: AnimalTemporaryBalanceService,
    private router: Router,
    private route: ActivatedRoute,
    private snack: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();

    this.getLastAnimalTemporaryId()

  }

  setInputOperation(animalTemporaryDatas: any) {
    
    this.animalTemporaryDatas = animalTemporaryDatas

  }

  create() {

    for (let animalTemporaryData of this.animalTemporaryDatas.animalAgeGroup) {

      let animalTemporaryDatas = {
        date: this.animalTemporaryDatas.date,
        animalTemporaryId: this.animalTemporaryId ? this.animalTemporaryId + 1 : 1 ,
        farmId: this.farmId,
        maleQuantity: animalTemporaryData.maleValue,
        femaleQuantity: animalTemporaryData.femaleValue,
        animalAgeGroupId: animalTemporaryData.animalAgeGroupId,
        operationMovement: this.animalTemporaryDatas.inputMovement,
        farmGrowerId: this.animalTemporaryDatas.farmGrower.farmId,
        status: 0
      }

      if (animalTemporaryData.maleValue > 0 || animalTemporaryData.femaleValue > 0) {

        this.animalTemporaryBalanceService.create(animalTemporaryDatas).subscribe(value => {
          this.snack.open('Saldo de Animais Temporarios Lançados com sucesso.', null, {duration:3000});
          this.router.navigate(['../'],{relativeTo: this.route});   
        }, err =>{
          this.snack.open(err.error.message, null, {duration: 3000});
        })

      }

    }

  }

  getLastAnimalTemporaryId() {
    this.animalTemporaryBalanceService.getAll(1, 1000, this.farmId).subscribe(value => {
      
      this.animalTemporaryId = value.items[0]?.animalTemporaryId
      
    })
  }

}
