<h2 class="center" mat-dialog-title>Calcular Posição de Rebanho</h2>
<div mat-dialog-content>

  <div class="add-lessons-form">
    <form [formGroup]="dateFormGroup">
        <mat-form-field >
            <mat-label>Data</mat-label>
            <input  matInput type="date" formControlName="date"  >

        </mat-form-field>
    </form>
    <div class="grid-spinner" *ngIf="loading">
      <span>Calculando Posição de Rebanho, Aguarde ...</span>
      <mat-spinner color="primary"></mat-spinner>
    </div>

  </div>
  <h3 mat-dialog-title class="center mt-5"></h3>

</div>
<div mat-dialog-actions class="center">
  <button mat-button color="warn" (click)="close()">Cancelar</button>
  <button mat-button (click)="save()">Confirmar</button>
</div>
