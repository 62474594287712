<div class="tb-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Abate</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="animalQuantity">
            <th class="table-head" mat-header-cell *matHeaderCellDef >Quantidade de Animais</th>
            <td mat-cell *matCellDef="let element"> {{element.animalQuantity}} </td>
        </ng-container>
        <ng-container matColumnDef="customer">
            <th class="table-head" mat-header-cell *matHeaderCellDef>Cliente</th>
            <td mat-cell *matCellDef="let element"> {{element.customer}} </td>
        </ng-container>
        <ng-container matColumnDef="outputDate">
            <th class="table-head" mat-header-cell *matHeaderCellDef>Data de Embarque</th>
            <td mat-cell *matCellDef="let element"> {{element.outputDate | date: 'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="weight">
            <th class="table-head" mat-header-cell *matHeaderCellDef>Peso Médio (kg)</th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>
        <ng-container matColumnDef="lotWeight">
            <th class="table-head" mat-header-cell *matHeaderCellDef>Peso Total do Lote (kg)</th>
            <td mat-cell *matCellDef="let element"> {{element.lotWeight}} </td>
        </ng-container>
        <ng-container matColumnDef="options">
            <th class="table-head-last" mat-header-cell *matHeaderCellDef> Opções</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button 
                    mat-menu-item 
                    routerLink="./view/{{element.id}}">
                        <mat-icon>visibility</mat-icon>
                        <span>Visualizar Resumo</span>
                    </button>
                    <button mat-menu-item (click)="generateExcel(element.outputOperationId, element.id)">
                        <mat-icon>print</mat-icon>
                        <span> Gerar relatório </span>
                      </button>
                    
                </mat-menu>
            </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">Nenhum dado encontrado</td>
            
        </tr>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator class="pag-size"
        [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons (page)="onPageChange($event)"
        [length]="totalRecords"></mat-paginator>
</div>

