import { AnimalAgeGroupInterface } from "src/app/interfaces/animal/AnimalAgeGroupInterface";
import { SERVER_URL } from "./../../config/env";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Observable } from "rxjs";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { ContextService } from "../context/context.service";

@Injectable({
  providedIn: "root",
})
export class AgeGroupService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,

  ) { }

  farmId = this.contextService.getFarm();

  create(ageGroupType: AnimalAgeGroupInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/ageGroup/create`,
      { ...ageGroupType },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }

    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/ageGroup?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(ageGroupid: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/ageGroup/${ageGroupid}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  update(ageGroupType: AnimalAgeGroupInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/ageGroup`,
      { ...ageGroupType },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  delete(ageGroupid: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/ageGroup/delete/${ageGroupid}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }
}
