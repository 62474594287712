import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { PersonalIdentifierPipe } from "../../pipes/masks/personal-identifier/personal-identifier.pipe";
import { AddressFormComponent } from "./address-form/address-form.component";
import { AnimalTimelineComponent } from "./animal-timeline/animal-timeline.component";
import { CepFormComponent } from "./cep-form/cep-form.component";
import { ConfirmCancelModalComponent } from "./confirm-cancel-modal/confirm-cancel-modal.component";
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";
import { FormFieldErrorComponent } from "./form-field-error/form-field-error.component";
import { FormHeaderComponent } from "./form-header/form-header.component";
import { ListHeaderComponent } from "./list-header/list-header.component";
import { PecCommonCreateModalComponent } from "./pec-common-create-modal/pec-common-create-modal.component";
import { PecCommonFilterModalEstornoComponent } from "./pec-common-filter-modal-estorno/pec-common-filter-modal-estorno.component";
import { PecCommonFilterModalComponent } from "./pec-common-filter-modal/pec-common-filter-modal.component";
import { PecCommonFilterComponent } from "./pec-common-filter/pec-common-filter.component";
import { PecListLoadingComponent } from "./pec-list-loading/pec-list-loading.component";
import { LookupDialogComponent } from "./pec-lookup/components/lookup-dialog/lookup-dialog.component";
import { LookupModalComponent } from "./pec-lookup/components/lookup-modal/lookup-modal.component";
import { PecLookupComponent } from "./pec-lookup/pec-lookup.component";
import { SmartTableComponent } from "./smart-table/smart-table.component";
import { SnackbarComponent } from "./snackbar/snackbar.component";

import { MatListModule } from "@angular/material/list";
import { MatStepperModule } from "@angular/material/stepper";
import { NgxDropzoneModule } from "ngx-dropzone";
import { BottomSheetComponent } from "./bottom-sheet/bottom-sheet.component";
import { PecUploadFileComponent } from "./pec-upload-file/pec-upload-file.component";
import { SmartReportModalComponent } from "./smart-report-modal/smart-report-modal.component";
import { DateModalComponent } from "./date-production-modal/date-production-modal.component";

@NgModule({
  declarations: [
    PersonalIdentifierPipe,
    CepFormComponent,
    AddressFormComponent,
    PecCommonFilterComponent,
    PecCommonFilterModalComponent,
    PecCommonFilterModalEstornoComponent,
    PecListLoadingComponent,
    PecLookupComponent,
    LookupModalComponent,
    LookupDialogComponent,
    ListHeaderComponent,
    FormHeaderComponent,
    DeleteDialogComponent,
    SnackbarComponent,
    FormFieldErrorComponent,
    ConfirmCancelModalComponent,
    AnimalTimelineComponent,
    PecCommonCreateModalComponent,
    SmartTableComponent,
    SmartReportModalComponent,
    BottomSheetComponent,
    PecUploadFileComponent,
    DateModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatMenuModule,
    MatRadioModule,
    MatDividerModule,
    MatListModule,
    NgxDropzoneModule,
    MatStepperModule,
  ],
  exports: [
    PersonalIdentifierPipe,
    AddressFormComponent,
    PecListLoadingComponent,
    PecLookupComponent,
    ListHeaderComponent,
    FormHeaderComponent,
    DeleteDialogComponent,
    FormFieldErrorComponent,
    AnimalTimelineComponent,
    ReactiveFormsModule,
    LookupModalComponent,
    SmartTableComponent,
    SmartReportModalComponent,
    BottomSheetComponent,
  ],
  providers: [
    SnackbarComponent,
    { provide: MatPaginatorIntl, useClass: SmartTableComponent },
  ],
})
export class SharedModule {}
