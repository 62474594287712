import { Injectable } from "@angular/core";
import { ReportCategoryAnimalService } from "./report-category-animal.service";
import { ReportCategoryCommercialService } from "./report-category-commercial.service";
import { ReportCategoryCostService } from "./report-category-cost.service";
import { ReportCategoryIntegrationERPService } from "./report-category-integration-erp.service";
import { ReportCategoryNutritionService } from "./report-category-nutrition.service";
import { ReportCategoryOperationService } from "./report-category-operation.service";
import { ReportCategoryCriaService } from "./report-category-cria.service";

export const enum REPORT_CATEGORY {
  ANIMAL,
  COMMERCIAL,
  OPERATIONS,
  INTEGRATION_ERP,
  NUTRITION,
  COST,
  CRIA,
}

export const enum REPORT_BUTTON_EVENT {
  INFO,
  EXEC,
}

export type TReportIndex = {
  id: number;
  title: string;
  description: string;
  category: REPORT_CATEGORY;
};

export type TReportsIndex = Array<TReportIndex>;

type TCategory = {
  id: number;
  description: string;
};

export type TReportCategories = Array<TCategory>;

@Injectable({
  providedIn: "root",
})
export class ReportIndexService {
  private reportsIndex: TReportsIndex = [];
  private categories: TReportCategories = [];

  constructor(
    private reportCommercialService: ReportCategoryCommercialService,
    private reportAnimalService: ReportCategoryAnimalService,
    private reportOpertionService: ReportCategoryOperationService,
    private reportIntegrationERPService: ReportCategoryIntegrationERPService,
    private reportNutritionService: ReportCategoryNutritionService,
    private reportCostService: ReportCategoryCostService,
    private reportCriaService: ReportCategoryCriaService
  ) {}

  getCategoriesReports(): TReportCategories {
    this.categories = [];

    this.categories.push(
      {
        id: REPORT_CATEGORY.ANIMAL,
        description: "Animais",
      },
      {
        id: REPORT_CATEGORY.COMMERCIAL,
        description: "Comercial",
      },
      {
        id: REPORT_CATEGORY.OPERATIONS,
        description: "Operações",
      },
      {
        id: REPORT_CATEGORY.NUTRITION,
        description: "Nutricional",
      },
      {
        id: REPORT_CATEGORY.COST,
        description: "Custos",
      },
      {
        id: REPORT_CATEGORY.CRIA,
        description: "Cria",
      }
    );

    return this.categories;
  }

  getReportsIndex(): TReportsIndex {
    this.reportsIndex = [];

    this.reportAnimalService.getReportsAnimals().forEach((reports) => {
      this.reportsIndex.push(reports);
    });

    this.reportCommercialService.getReportsCommercial().forEach((reports) => {
      this.reportsIndex.push(reports);
    });

    this.reportOpertionService.getReportsOperations().forEach((reports) => {
      this.reportsIndex.push(reports);
    });

    this.reportIntegrationERPService
      .getReportsIntegrationERP()
      .forEach((reports) => {
        this.reportsIndex.push(reports);
      });

    this.reportNutritionService.getReportsNutrition().forEach((reports) => {
      this.reportsIndex.push(reports);
    });

    this.reportCostService.getReportsCost().forEach((reports) => {
      this.reportsIndex.push(reports);
    });

    this.reportCriaService.getReportsAnimals().forEach((reports) => {
      this.reportsIndex.push(reports);
    });

    return this.reportsIndex;
  }

  setReport(
    reportIndex: TReportIndex,
    reportButtonEvent: REPORT_BUTTON_EVENT
  ): void {
    if (reportIndex.category === REPORT_CATEGORY.COMMERCIAL) {
      this.reportCommercialService.setReport(reportIndex, reportButtonEvent);
    }
    if (reportIndex.category === REPORT_CATEGORY.ANIMAL) {
      this.reportAnimalService.setReport(reportIndex, reportButtonEvent);
    }
    if (reportIndex.category === REPORT_CATEGORY.INTEGRATION_ERP) {
      this.reportIntegrationERPService.setReport(
        reportIndex,
        reportButtonEvent
      );
    }
    if (reportIndex.category === REPORT_CATEGORY.OPERATIONS) {
      this.reportOpertionService.setReport(reportIndex, reportButtonEvent);
    }
    if (reportIndex.category === REPORT_CATEGORY.NUTRITION) {
      this.reportNutritionService.setReport(reportIndex, reportButtonEvent);
    }
    if (reportIndex.category === REPORT_CATEGORY.COST) {
      this.reportCostService.setReport(reportIndex, reportButtonEvent);
    }
    if (reportIndex.category === REPORT_CATEGORY.CRIA) {
      this.reportCriaService.setReport(reportIndex, reportButtonEvent);
    }
  }
}
