<form [formGroup]="animalFormGroup">
  <div class="flex gap-4">
    <!--  FARM information-->
    <mat-card class="full">
      <mat-card-header>
        <h3 class="set">
          Informações do Animal
          <span *ngIf="formType !== 0">
            {{ animal?.traceability.sisbovNumber }}</span
          >
        </h3>
      </mat-card-header>
      <mat-grid-list rowHeight="100px" cols="2">
        <mat-grid-tile colspan="1">
          <mat-form-field>
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="sex">
              <mat-option value="M">Macho</mat-option>
              <mat-option value="F">Fêmea</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <mat-form-field>
            <mat-label>Peso</mat-label>
            <input matInput formControlName="weight" />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="2" rowHeight="100px">
        <mat-grid-tile [colspan]="1">
          <mat-form-field>
            <mat-label>Data Nascimento</mat-label>
            <input
              matInput
              formControlName="birthDate"
              [matDatepicker]="birthDateCalendar"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="birthDateCalendar"
            ></mat-datepicker-toggle>
            <mat-datepicker #birthDateCalendar></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" formGroupName="breed">
          <mat-form-field>
            <mat-label>Raça</mat-label>
            <mat-select formControlName="breedId">
              <mat-option
                *ngFor="let breed of breeds"
                [value]="breed.breedId"
                >{{ breed.description }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card>

    <mat-card class="full">
      <table mat-table [dataSource]="weighings" class="mat-elevation-z6 full">
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Pesos</th>
          <td mat-cell *matCellDef="let weight">{{ weight.weight }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let weight">{{ weight.date }}</td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Opções</th>
          <td mat-cell *matCellDef="let weight">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="delete_weight(weight)">
                <mat-icon>delete</mat-icon>
                <span>Exclúir peso</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>
    </mat-card>
  </div>
</form>
