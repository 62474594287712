import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';
import {AuthService} from '../../auth/auth.service';
import {SERVER_URL} from '../../../config/env';
import {GenericFilterResponse} from '../../../components/shared/pec-common-filter/interaces/GenericFilterResponse';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  getCountry(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/location/country`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getState(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/location/state`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getCounty(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {

    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }

    return this.http.get(`${this.authService.getUrlBackEnd()}/api/location/county?page=${page}&limit=${limit}${filterQueryString}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
