import { filter } from "rxjs/operators";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";

import { PaginationService } from "../../../../directives/Services-for-directives/Pagination.service";
import * as moment from 'moment';

@Component({
  selector: "app-operation-integration-list",
  templateUrl: "./operation-integration-list.component.html",
  styleUrls: ["./operation-integration-list.component.scss"],
})
export class OperationIntegrationListComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() filters: GenericFilterResponse[];
  @Input() loading = false;
  @Input() showMovimentRedo = false;
  totalRecords = 0;
  status = false;

  public columns = [
    "documento",
    "length",
    "data",
    "status",
    "origem",
    "options",
  ];
  dataSource = new MatTableDataSource();
  dataSourceFilterNotRedo = new MatTableDataSource();
  dataSourceFilterAll = new MatTableDataSource();
  filtersSelected: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private integrationService: FarmIntegrationService,
    private paginationService: PaginationService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackbarComponent
  ) { }

  ngOnInit() {
    this.loading = true;
    this.integrationService.getOperationsProtheus().subscribe((value) => {

      this.dataSource.sort = this.sort;
      this.dataSourceFilterAll.data = value.items;

      this.dataSourceFilterNotRedo.data = value.items.filter((e: any) => {
        return e.status != 3;
      });

      this.getPages(

        this.paginationService.getPageSize() -
        this.paginationService.getPageSize(),
        this.paginationService.getPageSize()
      );

      this.loading = false;

    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item:any, property) => {
      switch (property) {
        case 'data': {
          let newDate = new Date(item.data);
          return newDate;
        }
        default: return item[property];

      }
    };
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.filtersSelected = changes.filters.currentValue;

    this.getPages(
      this.paginationService.getPageSize() -
      this.paginationService.getPageSize(),
      this.paginationService.getPageSize()
    );

    if ("filters" in changes) {
      if (changes.filters.currentValue) {
        let dataFilter = this.dataSourceFilterAll.data;
        let datesFilter: any = { initDate: "", finalDate: "" };
        for (let value of changes.filters.currentValue) {
          if (value.fieldName === "status") {
            value.fieldValue = parseInt(value.fieldValue);
          }
          dataFilter = this.dataSourceFilterAll.data.filter((e: any) => {
            if (
              value.fieldName === "initDate" ||
              value.fieldName === "finalDate"
            ) {

              datesFilter[value.fieldName] = value.fieldValue;

            }

            else if (e[value.fieldName] == value.fieldValue) {
              return e;
            }
          });
        }

        if (datesFilter.initDate && datesFilter.finalDate) {
        }
      }
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  getPages(start: number, end: number) {
    this.dataSource.data = this.showMovimentRedo ? this.dataSourceFilterAll.data : this.dataSourceFilterNotRedo.data;
    let datesFilter: any = {
      initDate: "",
      finalDate: ""
    }
    if (this.filtersSelected) {
      for (let filter of this.filtersSelected) {
        if (filter.fieldName === "status") {
          filter.fieldValue = parseInt(filter.fieldValue);
        }
        if (filter.fieldName === "initDate" || filter.fieldName === "finalDate") {
          datesFilter[filter.fieldName] = filter.fieldValue;
        }
        else {
          this.dataSource.data = this.dataSource.data.filter((e: any, index: number) => {

            if (e[filter.fieldName] == filter.fieldValue) {
              return e;
            }
          });
        }
      }
      this.setRowsData(start, end);
    }
    if (this.filtersSelected) {
      this.totalRecords = this.dataSource.data.length

    } else {
      this.totalRecords = this.showMovimentRedo ? this.dataSourceFilterAll.data.length : this.dataSourceFilterNotRedo.data.length;
    }
  }

  removeFilter() {
    this.filtersSelected = undefined;
    this.getPages(
      this.paginationService.getPageSize() -
      this.paginationService.getPageSize(),
      this.paginationService.getPageSize()
    );
  }

  goToDetalhesByState(moviments: any) {
    this.router.navigate([`./view/${moviments.documento}`], {
      relativeTo: this.route,
      state: { moviments: moviments.moviments },
    });
  }

  //Operação para refazer
  revertOperation(doc: string, isRevert?: boolean) {
    this.integrationService.revertOperationsProtheus(doc, isRevert).subscribe(
      () => {
        this.snackBar.snackbarSuccess("Status alterado com sucesso!");
        this.getPages(0, this.paginationService.getPageSize());
      },
      (e) => {
        this.snackBar.snackbarError(e);
      }
    );
  }

  // Responsável por atualizar a pagina ao clicar em visualizar
  onPageChange(pageEvent: PageEvent) {

    this.getPages(
      pageEvent.pageIndex * pageEvent.pageSize + 1,
      pageEvent.pageIndex * pageEvent.pageSize + pageEvent.pageSize
    );
  }
  setRowsData(start: number, end: number) {
    let data: any[] = [];
    this.dataSource.data.forEach((e: any, index) => {
      if (index >= start && index <= end) {
        data.push(e);
      }
    });
    this.dataSource.data = data;
    this.loading = false;
  }
}
