import { Injectable } from "@angular/core";
import { CostAnimalService } from "src/app/services/reports/cost/cost-animal.service";
import { CostNutritionalLotService } from "./../../../services/reports/cost/cost-nutritional-lot.service";
import {
  REPORT_BUTTON_EVENT,
  REPORT_CATEGORY,
  TReportIndex,
  TReportsIndex,
} from "./report-index.service";

const COST_REPORT = {
  nutritionalLot: 1,
  animals: 2,
};

@Injectable({
  providedIn: "root",
})
export class ReportCategoryCostService {
  private costReports: TReportsIndex;

  constructor(
    private costAnimalService: CostAnimalService,
    private costNutritionalLotService: CostNutritionalLotService
  ) {}

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    if (reportButtonEvent === REPORT_BUTTON_EVENT.EXEC) {
      this.setExec(report);
    }
    if (reportButtonEvent === REPORT_BUTTON_EVENT.INFO) {
      this.setInfo(report);
    }
  }

  setExec(report: TReportIndex) {
    if (report.id === COST_REPORT.nutritionalLot) {
      this.costNutritionalLotService.openSmartReport();
    }

    if (report.id === COST_REPORT.animals) {
      this.costAnimalService.openSmartReport();
    }
  }

  setInfo(report: TReportIndex) {
    if (report.id === COST_REPORT.nutritionalLot) {
      this.costNutritionalLotService.openInfoReport();
    }
    if (report.id === COST_REPORT.animals) {
      this.costAnimalService.openInfoReport();
    }
  }

  getReportsCost(): TReportsIndex {
    this.costReports = [
      {
        id: COST_REPORT.nutritionalLot,
        title: "Custo Lotes Nutricionais",
        description: "Gera os dados de custos dos lotes nutricionais.",
        category: REPORT_CATEGORY.COST,
      },
      {
        id: COST_REPORT.animals,
        title: "Custo por Animal",
        description: "Gera os dados de custos dos animais.",
        category: REPORT_CATEGORY.COST,
      },
    ];
    return this.costReports;
  }
}
