import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { PaginationService } from "src/app/directives/Services-for-directives/Pagination.service";
import { AnimalObservationService } from "src/app/services/animal/animal/animal-observation.service";
import { ContextService } from "src/app/services/context/context.service";
import { FarmService } from "src/app/services/farm/farm/farm.service";
import { AnimalInterface } from "../../../../../interfaces/animal/AnimalInterface";
import { AnimalService } from "../../../../../services/animal/animal/animal.service";
import { AnimalAbstractComponent } from "../animal-abstract/animal-abstract.component";
import { ChangeAnimalStatusModalComponent } from "./change-animal-status-modal/change-animal-status-modal.component";

@Component({
  selector: "pec-animal-list",
  templateUrl: "./animal-list.component.html",
  styleUrls: ["./animal-list.component.scss"],
})
export class AnimalListComponent implements OnInit, OnChanges {
  pageIndex!: number;
  pageSize!: number;
  loading!: boolean;
  totalRecords!: number;
  identifierLabel!: string;
  farmId: number;
  hiddenColumn: boolean;
  columns: string[] = [
    "status",
    "farm",
    "identifier",
    "breed",
    "sex",
    "birthDate",
    "months",
    "options",
  ];
  dataSource = new MatTableDataSource<AnimalInterface>();
  @Input() filterIdentifier: any;

  constructor(
    private readonly animalService: AnimalService,
    private readonly farmService: FarmService,
    private readonly animalObsService: AnimalObservationService,
    private readonly contextService: ContextService,
    private readonly dialog: MatDialog,
    private readonly paginationService: PaginationService
  ) {
    this.pageIndex = 1;
    let localPageSyze = this.paginationService.getPageSize();
    localPageSyze > 10 ? (this.pageSize = localPageSyze) : (this.pageSize = 10);
  }

  ngOnInit() {
    this.farmId = this.contextService.getFarm();

    this.farmService
      .getById(this.contextService.getFarm())
      .subscribe((farm: any) => {
        if (farm.items) {
          if (farm.items.tracked) {
            this.identifierLabel = "SISBOV";
          } else {
            this.identifierLabel = "Identificador";
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("filterIdentifier" in changes) {
      if (this.filterIdentifier?.identifier) {
        this.getIdentifierAnimal();
      } else {
        this.getAnimals();
      }
    }
  }

  getIdentifierAnimal() {
    this.loading = true;
    this.dataSource.data = [];

    this.animalService.getByIdentifier(this.filterIdentifier).subscribe(
      (value) => {
        this.loading = false;
        if (value.items.length > 0) {
          value.items.map((element: any) => {
            element.months = moment().diff(element.birthDate, "months");
            this.hiddenColumn = this.farmId === element.farm.farmId;
          });

          this.dataSource.data = value.items;
          this.totalRecords = value.items.length;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  onPageChange(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex + 1;
    this.pageSize = pageEvent.pageSize;

    this.getAnimals();
  }

  getAnimals() {
    this.loading = true;
    this.dataSource.data = [];

    this.animalService.getAll(this.pageIndex, this.pageSize).subscribe(
      (value) => {
        this.loading = false;
        value.items.map((element: any) => {
          element.months = moment().diff(element.birthDate, "months");
          this.hiddenColumn = this.farmId === element.farm.farmId;
        });

        this.dataSource.data = value.items;
        this.totalRecords = value.pagination.totalRecords;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  disableAnimal(animal: AnimalInterface) {
    let modalTitle: string = "ATIVAR Animal";

    if (animal.status) modalTitle = "BLOQUEAR Animal";

    const dialog = this.dialog.open(ChangeAnimalStatusModalComponent, {
      width: "350px",
      height: "400px",
      disableClose: true,
      hasBackdrop: true,
      data: {
        animalId: animal.animalId,
        title: modalTitle,
      },
    });
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        const resultStatus = this.returnUpdatedAnimal(animal.status);
        const animalObservation = { ...result, ...resultStatus };

        this.animalObsService.create(animalObservation).subscribe(
          () => {
            this.getAnimals();
          },
          (error) => {
          }
        );
      }
    });
  }

  insertObservation(animal: AnimalInterface) {
    let modalTitle: string = "Observação Animal";

    const dialog = this.dialog.open(ChangeAnimalStatusModalComponent, {
      width: "400px",
      height: "500px",
      disableClose: true,
      hasBackdrop: true,
      data: {
        animalId: animal.animalId,
        title: modalTitle,
      },
    });
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        const email = this.contextService.getSession().user.email;
        const animalObservation = { ...result, createdAtEmail: email };

        this.animalObsService.create(animalObservation).subscribe(
          () => {
            this.getAnimals();
          },
          (error) => {
          }
        );
      }
    });
  }

  openResume(animalId: string) {
    this.dialog.open(AnimalAbstractComponent, {
      width: "99%",
      height: "99%",
      disableClose: false,
      hasBackdrop: true,
      data: { animalId: animalId },
    });
  }

  returnUpdatedAnimal(animalStatus: boolean): object {
    let newStatus = {
      animal: {
        status: false,
      },
    };
    if (!animalStatus) {
      newStatus.animal.status = true;
    }

    return newStatus;
  }
}
