<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button color="primary" [routerLink]="'../'" style="margin-right: 60px">
      <mat-icon>keyboard_backspace</mat-icon>
      Voltar
    </button>
    <span class="toolbar-spacer"></span>
    <span>Tipo de Fazenda</span>
    <span class="toolbar-spacer"></span>
    <button mat-raised-button class="add" color="primary" (click)="create()" class="save-button" [disabled]="isLoading">
      <mat-spinner [diameter]="25" class="spinner-button"
                   [style.display]="!isLoading ? 'none': 'inline-block'"
      ></mat-spinner>
      <mat-icon [style.display]="isLoading ? 'none': 'inline-block'"
      >save
      </mat-icon>
      Salvar
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="container">
  <pec-animal-form [formType]="formType" (getAnimal)="setAnimal($event)"></pec-animal-form>
</div>
