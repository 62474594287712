<h2 class="center" mat-dialog-title>Incluir Dados de Peso e Data de Entrada no Confinamento</h2>
<div mat-dialog-content>

  <div class="add-lessons-form">
    <ng-container *ngFor="let infoForm of data; let i = index">

      <div class="information-inputs center">

        <mat-form-field appearance="fill">
          <input 
          matInput 
          formControlName="area" 
          placeholder="area" 
          [value]="infoForm.area"
          ([ngModel])="infoForm.area"
          (blur)="onUpdateIndex($event, i, infoForm, 'area')"
          />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <input 
          matInput 
          formControlName="inputWeight" 
          placeholder="Peso"
          ([ngModel])="infoForm.inputWeight"
          (blur)="onUpdateIndex($event, i, infoForm, 'inputWeight')"
          />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <input 
            matInput 
            formControlName="inputDate"
            placeholder="DD/MM/AAAA"
            ([ngModel])="infoForm.inputDate"
            (blur)="onUpdateIndex($event, i, infoForm, 'inputDate')"
          />
        </mat-form-field>
        
      </div>
    </ng-container>
  </div>
  <h3 mat-dialog-title class="center mt-5"></h3>

</div>
<div mat-dialog-actions class="center">
  <button mat-button color="warn" (click)="close()">Cancelar</button>
  <button mat-button (click)="save()">Confirmar</button>
</div>
