import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperationIntegrationIndexComponent } from "./operation-integration-index/operation-integration-index.component";
import { OperationIntegrationListViewComponent } from "./components/operation-integration-list-view/operation-integration-list-view";

const routes: Routes = [
  {
    path: '',
    component: OperationIntegrationIndexComponent,
  },
  {
    path: 'view/:id',
    component: OperationIntegrationListViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationIntegrationRoutingModule { }
