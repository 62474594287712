import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

type TAnimalObservation = {
  animalObservationId: string;
  farmId: number;
  animalId: string;
  observationDate: Date;
  observation: string;
  observationAlert: boolean;
  createdAtEmail: string;
};
@Injectable({
  providedIn: "root",
})
export class AnimalObservationService {
  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private contextService: ContextService
  ) {}

  farmId = this.contextService.getFarm();

  create(animalObservation: TAnimalObservation): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animal/observation`,
      { animalObservation, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
