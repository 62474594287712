<mat-grid-list rowHeight="50px" cols="1">
  <mat-grid-tile colspan="1">
    <h3>{{data.title}}</h3>
    <button mat-icon-button color="primary" disableRipple [style.display]="data.type === 1 ? 'block' : 'none'" (click)="openFilterModal()">
      <mat-icon class="pec-title-search-icon">search</mat-icon>
    </button>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list rowHeight="30px" cols="1">
  <mat-grid-tile colspan="1">
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list rowHeight="80px" cols="1">
  <mat-grid-tile colspan="1">
    <mat-form-field>
      <mat-label>Pesquisa</mat-label>
      <input matInput autocomplete="off" (keyup)="applyFilter($event)" #input>
    </mat-form-field>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list rowHeight="1:1" cols="1" class="pec-table-list">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox *ngIf="data.multiple"
                      (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selectRow(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container *ngFor="let column of data.columns" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef> {{column.label}}</th>
      <td mat-cell *matCellDef="let element"> {{element[column.name]}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selectRow(row)">
    </tr>

    <tr class="mat-row" *matNoDataRow>
      <td *ngIf="!loading" class="mat-cell" colspan="5">Nenhum animal encontrado</td>
      <td *ngIf="loading" class="mat-cell" colspan="5">
        <pec-list-loading text='Carregando animais...'></pec-list-loading>
      </td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25]" (page)="pageChange($event)" [length]="recordSize"></mat-paginator>
</mat-grid-list>
<mat-grid-list rowHeight="2.5rem" cols="1" style="justify-content: end">
  <div mat-dialog-actions class="pec-dialog-actions">
    <button mat-flat-button class="closeBtn" (click)="onNoClick()">Sair</button>
    <button mat-flat-button class="btnColor" [mat-dialog-close]="selection.selected">Confirmar</button>
  </div>
</mat-grid-list>
