import { Injectable } from "@angular/core";
import { AnimalTransferReportService } from "src/app/services/reports/animal/animal-transfer-report.service";
import { FeedDailyReportService } from "src/app/services/reports/nutrition/feed-daily-report.service";
import { FeedLoadReportService } from "src/app/services/reports/nutrition/feed-load/feed-load-report.service";
import { NutritionalLotActiveReportService } from "src/app/services/reports/nutrition/nutritional-lot-active.service";
import { NutritionalLotConsumptionReportService } from "src/app/services/reports/nutrition/nutritional-lot-consumption.service";
import { NutritionalLotInformationService } from "src/app/services/reports/nutrition/nutritional-lot-information.service";
import { NutritionalLotMNConsumptionReportService } from "src/app/services/reports/nutrition/nutritional-lot-mn-consumption.service";
import { NutritionalLotMSConsumptionReportService } from "src/app/services/reports/nutrition/nutritional-lot-ms-consumption.service";
import {
  REPORT_BUTTON_EVENT,
  REPORT_CATEGORY,
  TReportIndex,
  TReportsIndex,
} from "./report-index.service";
import { NutritionalDailyMonthlyProductsReportService } from "../../../services/reports/nutrition/daily-monthly-products/nutritional-daily-monthly-products.service";
import { TreatIntegrationReportService } from "src/app/services/reports/nutrition/integration/treat-integration-report.service";

const NUTRITION_REPORTS_ID = {
  feedLoad: 1,
  feedDaily: 2,
  nutritionalLot: 3,
  nutritionalLotMNConsumption: 4,
  nutritionalLotMSConsumption: 5,
  nutritionalLotInformation: 6,
  animalTransfer: 7,
  nutritionalLotConsumption: 8,
  nutritionalDailyMonthlyProducts: 9,
  treatIntegration: 10,
};

@Injectable({
  providedIn: "root",
})
export class ReportCategoryNutritionService {
  private nutritionReports: TReportsIndex;

  constructor(
    private readonly feedLoadService: FeedLoadReportService,
    private readonly nutritionalLotConsumptionReportService: NutritionalLotConsumptionReportService,
    private readonly feedDailyService: FeedDailyReportService,
    private readonly nutritionalLotService: NutritionalLotActiveReportService,
    private readonly nutritionalLotMNConsumptionService: NutritionalLotMNConsumptionReportService,
    private readonly nutritionalLotMSConsumptionService: NutritionalLotMSConsumptionReportService,
    private readonly nutritionalLotConsumptionService: NutritionalLotConsumptionReportService,
    private readonly nutritionalLotInformationService: NutritionalLotInformationService,
    private readonly animalTransferReportService: AnimalTransferReportService,
    private readonly nutritionalDailyMonthlyProducts: NutritionalDailyMonthlyProductsReportService,
    private readonly treatIntegrationReportService: TreatIntegrationReportService
  ) {}

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    if (reportButtonEvent === REPORT_BUTTON_EVENT.EXEC) {
      this.setExec(report);
    } else if (reportButtonEvent === REPORT_BUTTON_EVENT.INFO) {
      this.setInfo(report);
    }
  }

  setExec(report: TReportIndex) {
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLot) {
      this.nutritionalLotService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.feedLoad) {
      this.feedLoadService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.feedDaily) {
      this.feedDailyService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotMNConsumption) {
      this.nutritionalLotMNConsumptionService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotMSConsumption) {
      this.nutritionalLotMSConsumptionService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotConsumption) {
      this.nutritionalLotConsumptionService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotInformation) {
      this.nutritionalLotInformationService.openSmartReport();
    }

    if (report.id === NUTRITION_REPORTS_ID.animalTransfer) {
      this.animalTransferReportService.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalDailyMonthlyProducts) {
      this.nutritionalDailyMonthlyProducts.openSmartReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.treatIntegration) {
      this.treatIntegrationReportService.openSmartReport();
    }
  }

  setInfo(report: TReportIndex) {
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLot) {
      this.nutritionalLotService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.feedLoad) {
      this.feedLoadService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.feedDaily) {
      this.feedDailyService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotMNConsumption) {
      this.nutritionalLotMNConsumptionService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotMSConsumption) {
      this.nutritionalLotMSConsumptionService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotConsumption) {
      this.nutritionalLotConsumptionService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalLotInformation) {
      this.nutritionalLotInformationService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.animalTransfer) {
      this.animalTransferReportService.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.nutritionalDailyMonthlyProducts) {
      this.nutritionalDailyMonthlyProducts.openInfoReport();
    }
    if (report.id === NUTRITION_REPORTS_ID.treatIntegration) {
      this.treatIntegrationReportService.openInfoReport();
    }
  }

  getReportsNutrition(): TReportsIndex {
    this.nutritionReports = [
      {
        id: NUTRITION_REPORTS_ID.nutritionalLot,
        title: "Lotes Nutricionais",
        description: "Gera os dados dos lotes nutricionais ativo.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.feedLoad,
        title: "Carga do Trato",
        description: "Gera os dados da carga do Trato animal.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.feedDaily,
        title: "Trato Diário",
        description: "Gera os dados do Trato diário animal.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.nutritionalLotMNConsumption,
        title: "Consumo Matéria Natural dos Lotes ",
        description:
          "Gera os dados de consumo materia natural. Período de consumo e notas de 5 dias",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.nutritionalLotMSConsumption,
        title: "Consumo Matéria Seca dos Lotes ",
        description:
          "Gera os dados de consumo materia seca. Período de consumo e notas de 5 dias",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.nutritionalLotConsumption,
        title: "Consumo Lotes Nutricionais",
        description:
          "Gera os dados de média de consumo dos lotes nutricionais.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.nutritionalLotInformation,
        title: "Trato diários lotes nutricionais",
        description: "Gera as informações dos tratos dos lotes nutricionais.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.animalTransfer,
        title: "Transferência de Lotes Nutricionais",
        description:
          "Gera os dados de transferência dos animais. Conforme parâmetros.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.nutritionalDailyMonthlyProducts,
        title: "Trato Diário - Produtos Consumidos",
        description:
          "Dados dos produtos consumidos por dia no módulo nutricional.",
        category: REPORT_CATEGORY.NUTRITION,
      },
      {
        id: NUTRITION_REPORTS_ID.treatIntegration,
        title: "Integração de Tratos",
        description: "Gera os valores dos trotos integrados com ERP.",
        category: REPORT_CATEGORY.NUTRITION,
      },
    ];

    return this.nutritionReports;
  }
}
