import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { ContextService } from "../../../context/context.service";
import { AuthService } from "../../../auth/auth.service";
import { PecGenericFilterFields } from "../../../../interfaces/utils/PecGenericFilterFields";
import { GenericFilterTypeEnum } from "../../../../enums/GenericFilterTypeEnum";
import * as moment from "moment/moment";

export type TTreatIntegrationReport = {
  farmId: number;
  startDate: string;
  endDate: string;
};

@Injectable({
  providedIn: "root",
})
export class TreatIntegrationReportService
  implements ReportInterface<TTreatIntegrationReport, Blob>
{
  farmId!: number;
  reportFilters: TTreatIntegrationReport;
  title!: string;

  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "startDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data trato de",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
    },
    {
      fieldName: "endDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data trato até",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
    },
  ];

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private dialog: MatDialog,
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.title = "Integração de Tratos";
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      startDate: "",
      endDate: "",
    };

    const data: TDataSmartReport<TTreatIntegrationReport> = {
      title: `${this.title}`,
      requiredFilters: true,
      description: "Selecione as opções para gerar o relatório",
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };

    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Informações Contidas no relatório",
      description: [
        "-> Data do trato",
        "-> Código do ERP do produto a ser baixado",
        "-> Descrição do produto",
        "-> Data da Movimentação",
        "-> Quantidade",
        "-> Status integração",
        "-> Mensagem de integração",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(
    datas: TTreatIntegrationReport,
    seletecReportType: string
  ): Observable<any> {
    if (seletecReportType === SMART_TYPE_REPORT.excel) {
      return this.http.post(
        `${this.authService.getUrlBackEnd()}/api/reports/treat-integration`,
        {
          farmId: datas.farmId,
          startDate: datas.startDate,
          endDate: datas.endDate,
        },
        {
          headers: PecControlHeaderRequest.getHeader(this.authService.token),
          responseType: "blob",
        }
      );
    }
  }
}
