<h1 mat-dialog-title>{{ titleModal }}</h1>

<div mat-dialog-content>
  <pec-common-filter
    [fields]="data"
    (getValidatedFilterFields)="setFilterFieldsValidated($event)"
    (getFilterFields)="setFilterFields($event)"
  >
  </pec-common-filter>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="close()"
    [mat-dialog-close]="null"
    class="closeBtn"
  >
    Cancelar
  </button>
  <button
    mat-raised-button
    (click)="confirm()"
    class="btnConfirm"
    [disabled]="disabledButton"
  >
    Aplicar
  </button>
</div>
