<section class="flex center">
  <button mat-raised-button color="accent" [routerLink]="'../../'">
    <mat-icon>keyboard_backspace</mat-icon>
      Voltar
  </button>
  <span class="spacer-left"></span>
  <h3>Integração Custos - Período: {{ integrationType?.mes }}</h3>
  <span class="spacer-right"></span>
  <div class="button-container">
    <button class="add loadingBtn" mat-raised-button *ngIf="integrationSoon" color="accent"
      (click)="getAssessment(integrationType)">
      <mat-icon [style.display]="loading ? 'none': 'inline-block'">gavel</mat-icon>
      <span [style.display]="loading ? 'none': 'inline-block'">Gerar Rateio</span>
    </button>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner diameter="26" color="warn"></mat-spinner>
    </div>
  </div>
</section>

<mat-divider></mat-divider>
<div class="container scroll">
  <app-integration-forms (integrationOutput)="setIntegration($event)" (isValid)="setFormValid($event)"
    [formType]="formType" [integrationType]="integrationType"></app-integration-forms>

  <div class="animate__backInLeft" *ngIf="integrationSoon">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="conta">
        <th mat-header-cell *matHeaderCellDef>Conta</th>
        <td mat-cell *matCellDef="let element">{{ element.conta }}</td>
      </ng-container>
      <ng-container matColumnDef="typeCost">
        <th mat-header-cell *matHeaderCellDef>Tipo de custo</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>
      <ng-container matColumnDef="releaseDate">
        <th mat-header-cell *matHeaderCellDef>Data de lançamento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.releaseDate | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.value | currency: "BRL" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef>Origem</th>
        <td mat-cell *matCellDef="let element">{{ element.origin }}</td>
      </ng-container>
      <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef>Fase</th>
        <td mat-cell *matCellDef="let element">{{ element.phase }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
  </div>
</div>