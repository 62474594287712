import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../auth/auth.service';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class PastureManegementService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) { }

  farmId = this.contextService.getFarm();

  areasToTransferPicketInRecreatePhase(): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/pasture/areasPicket?farmId=${this.farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  areasToTransfer(): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/pasture/areas?farmId=${this.farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  areaUaInfo(): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/pasture/areaUaInfo`,
    {
      farmId: this.farmId
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }
}
