<!-- <mat-toolbar>
  <mat-toolbar-row class="sizeToolbar">
    <span class="toolbar-spacer-left"></span>
    <span class="toolbar-title">Acompanhamento de Operação de Integração</span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openFilterModalOperations()">
        <mat-icon>description</mat-icon>
        <span>Relatórios</span>
      </button>
      <button mat-menu-item (click)="showRedoMoviments()">
        <mat-icon *ngIf="!showMovimentRedo">check</mat-icon>
        <mat-icon *ngIf="showMovimentRedo">cancel</mat-icon>
        <span *ngIf="!showMovimentRedo">Exibir movimentos estornados</span>
        <span *ngIf="showMovimentRedo">Remover movimentos estornados</span>
      </button>
    </mat-menu>
    <span class="toolbar-spacer"></span>
    <button mat-menu-item class="filter" (click)="openFilterModal()">
      <img class="nav-icon-filter " src="../../../../../assets/nav-icons/filter.svg" title="Filtro" alt="Item de menu para filtro da tabela Acompanhamento de Operação de Integração">
      <span>Filtro</span>
    </button>
  </mat-toolbar-row>
</mat-toolbar> -->

<section class="flex center">
  <span class="spacer-left"></span>
  <!-- <span> -->
  <h3>Acompanhamento de Operação de Integração</h3>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openFilterModalOperations()">
      <mat-icon>description</mat-icon>
      <span>Relatórios</span>
    </button>
    <button mat-menu-item (click)="showRedoMoviments()">
      <mat-icon *ngIf="!showMovimentRedo">check</mat-icon>
      <mat-icon *ngIf="showMovimentRedo">cancel</mat-icon>
      <span *ngIf="!showMovimentRedo">Exibir movimentos estornados</span>
      <span *ngIf="showMovimentRedo">Remover movimentos estornados</span>
    </button>
  </mat-menu>
  <!-- </span> -->
  <span class="spacer"></span>
  <button mat-menu-item class="filter" (click)="openFilterModal()">
    <!-- <img class="nav-icon-filter " src="../../../../../assets/nav-icons/filter.svg" title="Filtro" alt="Item de menu para filtro da tabela Acompanhamento de Operação de Integração"> -->
    <svg
      class="icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xml:space="preserve"
    >
      <g>
        <path
          class="icon-filter"
          xmlns="http://www.w3.org/2000/svg"
          d="m14 24a1 1 0 0 1 -.6-.2l-4-3a1 1 0 0 1 -.4-.8v-5.62l-7.016-7.893a3.9 3.9 0 0 1 2.916-6.487h14.2a3.9 3.9 0 0 1 2.913 6.488l-7.013 7.892v8.62a1 1 0 0 1 -1 1zm-3-4.5 2 1.5v-7a1 1 0 0 1 .253-.664l7.268-8.177a1.9 1.9 0 0 0 -1.421-3.159h-14.2a1.9 1.9 0 0 0 -1.421 3.158l7.269 8.178a1 1 0 0 1 .252.664z"
        />
      </g>
    </svg>
    <span class="title-filter">Filtro</span>
  </button>
</section>

<div class="container">
  <mat-chip-list
    class="example-chip"
    cdkDropList
    cdkDropListOrientation="horizontal"
  >
    <div class="tt" *ngFor="let filter of filterFields">
      <mat-chip
        *ngIf="filter.fieldValue"
        color="primary"
        [removable]="true"
        (removed)="removeFilter(filter)"
      >
        {{ filter.fieldValue }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </div>
  </mat-chip-list>
  <app-operation-integration-list
    [filters]="filters"
    [showMovimentRedo]="showMovimentRedo"
  >
  </app-operation-integration-list>
</div>
