<mat-toolbar>
  <mat-toolbar-row class="sizeToolbar">
    <button mat-icon-button color="primary" [routerLink]="'../../'" class="voltar">
      <mat-icon>keyboard_backspace</mat-icon>
      Voltar
    </button>
    <span class="toolbar-spacer-left"></span>
    <span>Integração Custos - Período: {{ integrationType?.mes }}</span>
    <span class="toolbar-spacer"></span>
    <button
      class="add"
      mat-raised-button
      color="primary"
      (click)="save()"
    >
      <mat-icon>check</mat-icon>
      Salvar
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<app-integration-forms (integrationOutput)="setIntegration($event)" (isValid)="setFormValid($event)"
  [formType]="formType" [integrationType]="integrationType"></app-integration-forms>