<mat-grid-list rowHeight="50px" cols="1">
    <mat-grid-tile colspan="1">
        <h3>{{data.title}}</h3>
    </mat-grid-tile>
</mat-grid-list>
<mat-grid-list rowHeight="500px" cols="1" class="pec-table-list">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                Selecione
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="stopPropagation($event)" (change)="$event ? selectRow(row, $event) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container *ngFor="let column of data.columns" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef> {{column.label}}</th>
            <td mat-cell *matCellDef="let element"> {{element[column.name]}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)">
        </tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nenhum dado encontrado com o valor </td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[8, 20]" (page)="pageChange($event)" [length]="recordSize"></mat-paginator>
</mat-grid-list>
<mat-grid-list rowHeight="2.5rem" cols="1" style="justify-content: end">
    <div mat-dialog-actions class="pec-dialog-actions">
        <button mat-flat-button class="closeBtn" (click)="close()">Sair</button>
        <button mat-flat-button class="btnColor" (click)="save(selection.selected)">Confirmar</button>
    </div>
</mat-grid-list>