import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericFilterResponse } from 'src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class AnimalTemporaryBalanceService {

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private contextService: ContextService,
  ) { }

  farmId = this.contextService.getFarm();

  create(animalTemporaryBalance: any): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/animal/temporaryBalance/create`, {animalTemporaryBalance}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number, farmId: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animal/temporaryBalance?page=${page}&limit=${limit}&farmId=${farmId}${filterQueryString}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animal/temporaryBalance/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(animalTemporaryBalance: any): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/animal/temporaryBalance/`,
      { ...animalTemporaryBalance },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/animal/temporaryBalance/delete/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }


  //RELATÓRIO

  animalTemporaryBalanceReport(datas: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animal/temporaryBalance/report`,
      datas,
      { responseType: "blob" }
    );
  }

  
}
