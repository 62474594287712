import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { BreedInterface } from '../../../interfaces/animal/BreedInterface';
import { Observable } from 'rxjs';
import { SERVER_URL } from '../../../config/env';
import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';
import { CharacteristicInterface } from '../../../interfaces/animal/CharacteristicInterface';
import { ContextService } from '../../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class CharacteristicService {


  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,

  ) {
  }

  farmId = this.contextService.getFarm();

  create(characteristic: CharacteristicInterface): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/animal/characteristic`, { characteristic, farmId: this.farmId }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animal/characteristic?page=${page}&limit=${limit}&farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(breedId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/animal/characteristic/${breedId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(characteristic: CharacteristicInterface): Observable<any> {
    return this.http.put(`${this.authService.getUrlBackEnd()}/api/animal/characteristic`, { characteristic }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
