<div class="container">
  <div class="timeline">
    <div class="timeline-container primary" *ngFor="let pesagem of pesagens">
      <div class="timeline-icon">
        <mat-icon svgIcon="weighing"></mat-icon>
      </div>
      <div class="timeline-body">
        <h4 class="timeline-title"><span class="badge">{{pesagem.weight}} Kg</span></h4>
        <p>Fazenda Santa Maria - Lote 5 - Picket 12</p>
        <p class="timeline-subtitle">{{getData(pesagem.date)}}</p>
      </div>
    </div>
  </div>
</div>
