import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../auth/auth.service';
import {Observable} from 'rxjs';
import {SERVER_URL} from '../../../config/env';
import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';

@Injectable({
  providedIn: 'root'
})
export class AnimalImportByXlsxService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }


  import(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('animals', file);
    const header = PecControlHeaderRequest.getHeader(this.authService.token);
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/animal-import/import-by-xlsx`, formData, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Authorization': `Bearer ${this.authService.token}`
      }),
    });
  }

}
