import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { ContextService } from "../../../context/context.service";
import { AuthService } from "../../../auth/auth.service";
import { PecGenericFilterFields } from "../../../../interfaces/utils/PecGenericFilterFields";
import { GenericFilterTypeEnum } from "../../../../enums/GenericFilterTypeEnum";
import * as moment from "moment/moment";

export type TNutritionalLotConsumptionReport = {
  farmId: number;
  startDate: string;
  endDate: string;
};

@Injectable({
  providedIn: "root",
})
export class NutritionalDailyMonthlyProductsReportService
  implements ReportInterface<TNutritionalLotConsumptionReport, Blob>
{
  farmId!: number;
  reportFilters: TNutritionalLotConsumptionReport;
  title!: string;

  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "startDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data trato de",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
    },
    {
      fieldName: "endDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data trato até",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
    },
  ];

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private dialog: MatDialog,
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.title = "Trato Diário - Produtos Consumidos";
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };

    const data: TDataSmartReport<TNutritionalLotConsumptionReport> = {
      title: `${this.title}`,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Informações Contidas no relatório",
      description: [
        "-> Planejado",
        "-> Realizado",
        "-> Data",
        "-> Produto",
        "-> Código do produto",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(
    datas: TNutritionalLotConsumptionReport,
    seletecReportType: string
  ): Observable<any> {
    if (seletecReportType === SMART_TYPE_REPORT.excel) {
      return this.http.post(
        `${this.authService.getUrlBackEnd()}/api/nutritional/report/nutritional-monthly-products`,
        {
          farmId: datas.farmId,
          startDate: datas.startDate,
          endDate: datas.endDate,
        },
        {
          headers: PecControlHeaderRequest.getHeader(this.authService.token),
          responseType: "blob",
        }
      );
    }
  }
}
