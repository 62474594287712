import { Injectable } from "@angular/core";
import {
  REPORT_BUTTON_EVENT,
  REPORT_CATEGORY,
  TReportIndex,
  TReportsIndex,
} from "./report-index.service";
import { AniDgOperationReportService } from "src/app/services/reports/cria/animal-dg-operation-report.service";
import { AniBirthPredictReportService } from "src/app/services/reports/cria/animals-birth-prediction-report.service";
import { AniDesmamaReportService } from "src/app/services/reports/cria/animals-desmama-report.service";
import { AniBirthReportService } from "src/app/services/reports/cria/animals-birth-report.service";
import { AniIatfOperationReportService } from "src/app/services/reports/cria/animal-iatf-operation-report.service";
import { AniProtocolReportService } from "src/app/services/reports/cria/animals-protocol-report.service";

const ANIMAL_REPORT_ID = {
  dgOperation: 1,
  birthPrediction: 2,
  desmama: 3,
  birth: 4,
  iatf: 5,
  protocol: 6,
};

@Injectable({
  providedIn: "root",
})
export class ReportCategoryCriaService {
  private animalReports: TReportsIndex;

  constructor(
    private readonly aniDgOperationReportService: AniDgOperationReportService,
    private readonly aniBirthPredictReportService: AniBirthPredictReportService,
    private readonly aniDesmamaReportService: AniDesmamaReportService,
    private readonly aniBirthReportService: AniBirthReportService,
    private readonly aniIatfOperationReportService: AniIatfOperationReportService,
    private readonly aniProtocolReportService: AniProtocolReportService
  ) {}

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    if (reportButtonEvent === REPORT_BUTTON_EVENT.EXEC) {
      this.setExec(report);
    } else if (reportButtonEvent === REPORT_BUTTON_EVENT.INFO) {
      this.setInfo(report);
    }
  }

  setExec(report: TReportIndex) {
    if (report.id === ANIMAL_REPORT_ID.dgOperation) {
      this.aniDgOperationReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.birthPrediction) {
      this.aniBirthPredictReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.desmama) {
      this.aniDesmamaReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.birth) {
      this.aniBirthReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.iatf) {
      this.aniIatfOperationReportService.openSmartReport();
    }
    if (report.id === ANIMAL_REPORT_ID.protocol) {
      this.aniProtocolReportService.openSmartReport();
    }
  }

  setInfo(report: TReportIndex) {
    if (report.id === ANIMAL_REPORT_ID.dgOperation) {
      this.aniDgOperationReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.birthPrediction) {
      this.aniBirthPredictReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.desmama) {
      this.aniDesmamaReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.birth) {
      this.aniBirthReportService.openInfoReport();
    }
    if (report.id === ANIMAL_REPORT_ID.iatf) {
      this.aniIatfOperationReportService.openInfoReport;
    }
    if (report.id === ANIMAL_REPORT_ID.protocol) {
      this.aniProtocolReportService.openInfoReport();
    }
  }

  getReportsAnimals(): TReportsIndex {
    this.animalReports = [
      {
        id: ANIMAL_REPORT_ID.dgOperation,
        title: "Operação Diagnóstico Gestação",
        description: "Gera dados dos manejos de Diagnóstico.",
        category: REPORT_CATEGORY.CRIA,
      },
      {
        id: ANIMAL_REPORT_ID.birthPrediction,
        title: "Previsão de Nascimento",
        description: "Gera dados de Previsão de Nascimento.",
        category: REPORT_CATEGORY.CRIA,
      },
      {
        id: ANIMAL_REPORT_ID.desmama,
        title: "Desmama",
        description: "Gera dados de Desmama.",
        category: REPORT_CATEGORY.CRIA,
      },
      {
        id: ANIMAL_REPORT_ID.birth,
        title: "Nascimento",
        description: "Gera dados de Nascimento.",
        category: REPORT_CATEGORY.CRIA,
      },
      {
        id: ANIMAL_REPORT_ID.iatf,
        title: "Inseminação Artificial em Tempo Fixo",
        description: "Gera dados de Inseminação Artificial em Tempo Fixo.",
        category: REPORT_CATEGORY.CRIA,
      },
      {
        id: ANIMAL_REPORT_ID.protocol,
        title: "Protocolos Hormonais",
        description: "Gera dados de Protocolos Hormonais.",
        category: REPORT_CATEGORY.CRIA,
      },
    ];

    return this.animalReports;
  }
}
