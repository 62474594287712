import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormTypeEnum } from 'src/app/enums/FormTypeEnum';
import { AnimalTemporaryBalanceInterface } from 'src/app/interfaces/animal/AnimalTemporaryBalanceInterface';
import { AgeGroupService } from 'src/app/services/ageGroup/age-group.service';
import { ContextService } from 'src/app/services/context/context.service';
import { FarmService } from 'src/app/services/farm/farm/farm.service';

@Component({
  selector: 'app-animal-temporary-balance-forms',
  templateUrl: './animal-temporary-balance-forms.component.html',
  styleUrls: ['./animal-temporary-balance-forms.component.scss']
})
export class AnimalTemporaryBalanceFormsComponent implements OnInit, OnChanges {
  farmId: number;
  farmGrowers: any[] = [];
  animalInputMovements: AnimalTemporaryBalanceInterface[] = [];
  animalTemporaryBalanceFormGroup: any;
  filteredOptions: Observable<string[]>;
  teste: any;
  inputMovement!: string;

  @Input('formType') formTypeInput: FormTypeEnum;
  @Input() inputOperationInput!: any;
  @Output('getInformations') animalTemporaryDatas: EventEmitter<any> = new EventEmitter<any>();
  @Output('isValid') isValidOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly farmService: FarmService,
    private readonly contextService: ContextService,
    private readonly ageGroupService: AgeGroupService,
  ) {
    this.animalTemporaryBalanceFormGroup = this.formBuilder.group({
      inputMovement: ['', Validators.required],
      farmGrower: this.formBuilder.group({
        farmId: ['', Validators.required]
      }),
      date: ['', Validators.required],
      fGrower: [''],
      animalAgeGroup: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();
    this.getValuesChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty("formTypeInput") &&
      !this.animalTemporaryBalanceFormGroup.dirty &&
      !this.animalTemporaryBalanceFormGroup.touched
    ) {
      this.setFormType();
    }
    if (
      changes.hasOwnProperty("inputOperationInput") &&
      !this.animalTemporaryBalanceFormGroup.dirty &&
      !this.animalTemporaryBalanceFormGroup.touched
    ) {
      this.inputOperationInput = changes.inputOperationInput.currentValue;

      this.setInputOperation();

    }
  }

  public get animalAgeGroups() {
    return this.animalTemporaryBalanceFormGroup.get("animalAgeGroup") as FormArray;
  }

  setFormType() {
    if (this.formTypeInput === FormTypeEnum.CREATE) {
      this.getFarmGrower();
      this.getAgeGroups();
      this.setEventEmit();
    } else if (this.formTypeInput === FormTypeEnum.EDIT) {

      this.getFarmGrower();
      this.setEventEmit();

    } else if (this.formTypeInput === FormTypeEnum.VIEW) {
      this.animalTemporaryBalanceFormGroup.disable();
      this.setInputOperation();
    }

    return;

  }

  getValuesChanges() {
    this.animalTemporaryBalanceFormGroup.controls.inputMovement.valueChanges.subscribe((value: any) => {
      if (value) {
        this.inputMovement = value
        this.startAutoCompletes();
      }
    })
  }

  setEventEmit() {
    this.animalTemporaryBalanceFormGroup.valueChanges.subscribe((values: any) => {
      this.isValidOutput.emit(this.animalTemporaryBalanceFormGroup.valid);
      if (this.animalTemporaryBalanceFormGroup.valid) {
        this.animalTemporaryDatas.emit(values)
      }
    })
    return;

  }

  setInputOperation() {

    if (this.inputOperationInput) {
      this.animalTemporaryBalanceFormGroup.controls.inputMovement.setValue(this.inputOperationInput.operationMovement);
      this.animalTemporaryBalanceFormGroup.controls.date.setValue(this.inputOperationInput.date);

      this.animalAgeGroups.push(
        new FormGroup({
          animalAgeGroupId: new FormControl(this.inputOperationInput.animalAgeGroup.animalAgeGroupId),
          description: new FormControl(this.inputOperationInput.animalAgeGroup.description),
          maleValue: new FormControl(this.inputOperationInput.maleQuantity),
          femaleValue: new FormControl(this.inputOperationInput.femaleQuantity)
        })
      )
    }


    return;

  }

  getFarmGrower() {
    this.farmGrowers = []
    this.farmService.getAll(1, 1000).subscribe(value => {
      const filterFarm: any[] = value.items.filter((farm: any) => farm.farmId)

      filterFarm.forEach((row: any) => {
        this.farmGrowers.push({
          farmId: row.farmId,
          description: `${row.description} | ${row.grower.name}`,
          growerId: row.grower.growerId,
        })
      });
      this.startAutoCompletes()
    }, error => {
    })
  }

  startAutoCompletes(): void {

    if (this.inputMovement) {
      if (this.inputMovement === 'N') {
        const farmGrowerFilter = this.farmGrowers.find(fil => fil.farmId === this.farmId)
        if (farmGrowerFilter) {
          this.animalTemporaryBalanceFormGroup.controls.fGrower.setValue(farmGrowerFilter);
          this.animalTemporaryBalanceFormGroup.get('farmGrower').get('farmId').setValue(farmGrowerFilter.farmId);
          this.animalTemporaryBalanceFormGroup.controls.fGrower.disable();
        }
      } else {
        this.animalTemporaryBalanceFormGroup.controls.fGrower.enable();
        if (this.inputOperationInput) {
          const farmGrowerFilter = this.farmGrowers.find(fil => fil.farmId === this.inputOperationInput.farmGrower.farmId)
          if (farmGrowerFilter) {
            this.animalTemporaryBalanceFormGroup.controls.fGrower.setValue(farmGrowerFilter);
            this.animalTemporaryBalanceFormGroup.get('farmGrower').get('farmId').setValue(farmGrowerFilter.farmId);
          }
        }
      }
    }

    this.filteredOptions = this.animalTemporaryBalanceFormGroup.get('fGrower').valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value.description),
        map(description => this._filterFarm(description))
      );
  }

  private _filterFarm(description: any): string[] {
    return this.farmGrowers.filter((filter: any) => {
      const filDescription = filter.description.toLowerCase();
      return filDescription.includes(description.toLowerCase());
    })
  }

  selectFarmGrower() {
    const fGrower = this.animalTemporaryBalanceFormGroup.get('fGrower').value
    this.animalTemporaryBalanceFormGroup.get('farmGrower').get('farmId').setValue(fGrower.farmId);
  }

  displayFarmGrower(fGrower: any): string {
    return fGrower && fGrower.description ? fGrower.description : '';
  }

  getAgeGroups() {
    this.ageGroupService.getAll(1, 1000).subscribe((e: any) => {
      for (let item of e.items) {
        this.animalAgeGroups.push(
          new FormGroup({
            animalAgeGroupId: new FormControl(item.animalAgeGroupId),
            description: new FormControl(item.description),
            maleValue: new FormControl(0),
            femaleValue: new FormControl(0)
          })
        )
      }

    })
  }

}
