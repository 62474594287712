import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { ContextService } from "src/app/services/context/context.service";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../../auth/auth.service";

type TInputGtaReport = {
  farmId: number;
  gta: string;
};

@Injectable({
  providedIn: "root",
})
export class AnimalInputGtaReportService
  implements ReportInterface<TInputGtaReport, Blob>
{
  reportFilters: TInputGtaReport;
  title: string;
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "gta",
      type: GenericFilterTypeEnum.TEXT,
      label: "Informe o número das GTAs de entrada",
      fieldValue: "",
      hint: "Separe as GTAs por virgula(,)",
      required: true,
    },
  ];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.title = "Animais GTA de Entrada";
  }

  openSmartReport() {
    this.reportFilters = {
      farmId: this.contextService.getFarm(),
      gta: null,
    };

    const data: TDataSmartReport<TInputGtaReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };

    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Relatório de animais por GTA de entrada",
      description: [
        "Informações Contidas no relatório:",
        "-> Quantidade de animais por Gta",
        "-> Datas de emissão da gta, validade, saída e chegada na fazenda",
        "-> Raça",
        "-> Sexo",
        "-> Idade em mêses",
        "-> Fornecedor",
        "-> Data de chegada na fazenda",
        "-> Peso de Chegada na Fazenda",
        "-> Última pesagem registrada na fazenda",
        "-> Data da última pesagem registrada na fazenda",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TInputGtaReport): Observable<Blob> {
    const gtas = datas.gta.split(",");

    const newData = {
      farmId: datas.farmId,
      gta: gtas,
    };
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/report/gta/gtaReport`,
      newData,
      {
        responseType: "blob",
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
