import { Injectable } from "@angular/core";

const PAGE_SIZE_KEY = "pagination_page_size";
@Injectable({
  providedIn: "root",
})
export class PaginationService {
  constructor() {}

  getPageSize() {
    return +localStorage.getItem(PAGE_SIZE_KEY);
  }

  setPageSize(size: number) {
    localStorage.setItem(PAGE_SIZE_KEY, "" + size);
  }
}
