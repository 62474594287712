import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "src/app/services/auth/auth.service";
import {ContextService} from "src/app/services/context/context.service";
import {TroughScoreInterface} from "src/app/interfaces/nutrition/TroughScoreInterface";
import {Observable} from "rxjs";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import {GenericFilterResponse} from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";

@Injectable({
  providedIn: 'root'
})
export class TruckService {


  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) {
  }

  farmId = this.contextService.getFarm();

  getAll(): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/truck?farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

}
