import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

export type TAnimalSisbovChangeReport = {
  farmId: number
  startDate: Date
  endDate: Date
};

@Injectable({
  providedIn: "root",
})
export class AnimalSisbovChangeReportService
  implements ReportInterface<TAnimalSisbovChangeReport, Blob>
{
  farmId!: number;
  reportFilters: TAnimalSisbovChangeReport;
  title = `Reidentificação SISBOV`;
  reportFilterFields: PecGenericFilterFields[] = [

    {
      fieldName: "startDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data Inicial",
      fieldValue: "",
      required: true
    },
    {
      fieldName: "endDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data Final",
      fieldValue: "",
      required: true
    },
  ];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
  ) {}

  openSmartReport() {
    const descriptionFarm = this.contextService.getCurrentDescriptionFarm();
    this.title = `Informações reidentificação SISBOV-${descriptionFarm}`;

    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      startDate: null,
      endDate: null,
    };

    const data: TDataSmartReport<TAnimalSisbovChangeReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle:
        "Relatório com as informações de reidentificação dos SISBOV.",
      description: [
        "Informações Contidas no relatório",
        "-> SISBOV",
        "-> Raça",
        "-> Sexo",
        "-> Idade em mêses",
        "-> Peso",
        "-> Status",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(data: TAnimalSisbovChangeReport): Observable<any> {
    let config: any = {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
      responseType: "blob",
    };
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/reports/animal/sisbov-change`,
      data,
      config
    );
  }
}
