import { Component, OnInit } from '@angular/core';
import { REPORT_BUTTON_EVENT, ReportIndexService, TReportCategories, TReportIndex, TReportsIndex } from '../services/report-index.service';

@Component({
  selector: 'app-reports-index',
  templateUrl: './reports-index.component.html',
  styleUrls: ['./reports-index.component.scss']
})
export class ReportsIndexComponent implements OnInit {
  reportsIndex: TReportsIndex
  reports: TReportsIndex
  reportCategories: TReportCategories
  selected: any
  info = REPORT_BUTTON_EVENT.INFO
  exec = REPORT_BUTTON_EVENT.EXEC
  searchTerm: string = '';

  constructor(
    private readonly reportsIndexService: ReportIndexService,
  ) { }

  ngOnInit(): void {
    this.reportCategories =
    this.reportsIndex = this.reportsIndexService.getReportsIndex();
    this.reportCategories = this.reportsIndexService.getCategoriesReports();
    this.reports = this.reportsIndex;
  }

  selectCategory(category: number) {
    if (category || category === 0) {
      this.reports = this.reportsIndex.filter(report => report.category === category)
    } else {
      this.reports = this.reportsIndex
    }
  }

  setReport(event: TReportIndex, eventButton: REPORT_BUTTON_EVENT) {
    this.reportsIndexService.setReport(event, eventButton)
  }

  onSearch(e: Event): void {
    const target = e.target as HTMLInputElement
    const value = target.value.toLowerCase()

    this.reports = this.reportsIndex.filter((moment) => {
      return moment.description.toLowerCase().includes(value)
    });
  }
}
