import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DateModalComponent {
  title: string;
  description: string;
}

@Component({
  selector: "pec-date-production-modal",
  templateUrl: "./date-production-modal.component.html",
  styleUrls: ["./date-production-modal.component.scss"],
})
export class DateModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DateModalComponent
  ) {}

  dateSelect = new FormGroup({
    date_select: new FormControl(""),
  });

  ngOnInit(): void {}

  save(): void {
    this.dialogRef.close(this.dateSelect.value);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
