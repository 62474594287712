import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { PecCommonFilterModalComponent } from "src/app/components/shared/pec-common-filter-modal/pec-common-filter-modal.component";
import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";

@Component({
  selector: "app-operation-integration-index",
  templateUrl: "./operation-integration-index.component.html",
  styleUrls: ["./operation-integration-index.component.scss"],
})
export class OperationIntegrationIndexComponent {
  constructor(
    public dialog: MatDialog,
    private integrationService: FarmIntegrationService,
    private snackBar: SnackbarComponent
  ) {}

  showButtonExportOperations = true;
  isLoading = false;
  dataFilter: any = null;
  showMovimentRedo = true;

  filterOperations: PecGenericFilterFields[] = [
    {
      fieldName: "initDate",
      type: GenericFilterTypeEnum.TEXT,
      label: "Data Inicial",
      fieldValue: "",
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.TEXT,
      label: "Data Final",
      fieldValue: "",
    },
  ];

  filterFields: PecGenericFilterFields[] = [
    {
      fieldName: "documento",
      type: GenericFilterTypeEnum.TEXT,
      label: "Número do documento",
      fieldValue: "",
    },
    {
      fieldName: "length",
      type: GenericFilterTypeEnum.TEXT,
      label: "Quantidade de movimento",
      fieldValue: "",
    },
    {
      fieldName: "status",
      type: GenericFilterTypeEnum.SELECT,
      label: "Status",
      fieldValue: "",
      valuesList: [
        {
          fieldLabel: "Enviado",
          fieldValue: "1",
        },
        {
          fieldLabel: "Não enviado",
          fieldValue: "2",
        },
        {
          fieldLabel: "Estornado",
          fieldValue: "3",
        },
      ],
    },
    // {
    //   fieldName: 'origem',
    //   type: GenericFilterTypeEnum.SELECT,
    //   label: 'Tipo de lançamento',
    //   fieldValue: '',
    //   valuesList: [
    //     {
    //       fieldLabel: 'Enviado',
    //       fieldValue: '1'
    //     },
    //     {
    //       fieldLabel: 'Não enviado',
    //       fieldValue: '2'
    //     },
    //     {
    //       fieldLabel: 'Estornado',
    //       fieldValue: '3'
    //     }
    //   ]
    // },
    {
      fieldName: "initDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data Inicial",
      fieldValue: "",
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data Final",
      fieldValue: "",
    },
  ];

  filters: GenericFilterResponse[];
  searchLoading = false;

  openFilterModalOperations() {
    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: "600px",
      data: this.filterOperations,
    });
    dialogRef.afterClosed().subscribe(async (value: any) => {
      if (value) {
        this.dataFilter = value;
        this.showButtonExportOperations = true;
        this.exportToExcelOperations();
      }
    });
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: "600px",
      data: this.filterFields,
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        value = value.filter((e: any) => {
          if (e.fieldValue !== "") {
            return e;
          }
        });
        this.filters = value;
      }
    });
  }

  removeFilter(removedFilter: PecGenericFilterFields) {
    const newFilters: GenericFilterResponse[] = [];
    this.filterFields = this.filterFields.map((v) => {
      if (removedFilter.fieldName === v.fieldName) {
        return { ...v, fieldValue: "" };
      }
      newFilters.push({
        fieldValue: v.fieldValue,
        fieldName: v.fieldName,
      });
      this.filters = newFilters;
      return { ...v };
    });
  }

  exportToExcelOperations() {
    this.isLoading = true;
    this.integrationService.reportOperationsProtheus(this.dataFilter).subscribe(
      (value) => {
        const url = window.URL.createObjectURL(value);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display:none");
        a.href = url;
        a.download = `Relatório Geral ${moment().format("DD/MM/YYYY HH:MM")}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.isLoading = false;
        this.showButtonExportOperations = false;
      },
      (e) => {
        this.isLoading = false;
        this.showButtonExportOperations = false;
        this.snackBar.snackbarError("Sem no período informado!");
      }
    );
  }

  showRedoMoviments() {
    this.showMovimentRedo = !this.showMovimentRedo;
  }
}
