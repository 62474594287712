<mat-card [formGroup]="addressForm">
  <mat-card-header>
    <h3 class="set">Endereço</h3>
  </mat-card-header>

  <mat-grid-list rowHeight="100px" cols="4">
    <mat-grid-tile colspan="1">
      <mat-form-field>
        <mat-label>País</mat-label>
        <input matInput formControlName="country" [matAutocomplete]="auto" />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayCountry"
          (optionSelected)="handleSelectCountry()"
        >
          <mat-option
            *ngFor="let country of filteredCountryOptions | async"
            [value]="country"
          >
            {{ country.nome }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field>
        <mat-label>CEP</mat-label>
        <input autocomplete="new-CEP" matInput formControlName="CEP"/>
      </mat-form-field>
      <div [style.display]="CEPLoading ? 'block' : 'none'">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field>
        <mat-label>UF</mat-label>
        <input
          matInput
          autocomplete="new-state"
          formControlName="state"
          [matAutocomplete]="autoStates"
          (focusin)="onStateFocus()"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoStates="matAutocomplete"
          [displayWith]="displayState"
          (optionSelected)="handleSelectState()"
        >
          <mat-option
            *ngFor="let state of filteredStateOptions | async"
            [value]="state"
          >
            {{ state.nome }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field>
        <mat-label>Cidade</mat-label>
        <input
          matInput
          formControlName="county"
          autocomplete="new-county"
          [matAutocomplete]="autoCounties"
        />
        <mat-autocomplete
          #autoCounties="matAutocomplete"
          [displayWith]="displayCounty"
          (optionSelected)="handleSelectCounty()"
        >
          <mat-option
            *ngFor="let county of filteredCountyOptions | async"
            [value]="county"
          >
            {{ county.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list rowHeight="100px" cols="6">
    <mat-grid-tile colspan="1">
      <mat-form-field>
        <mat-label>Bairro</mat-label>
        <input matInput autocomplete="new-district" formControlName="district" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
      <mat-form-field>
        <mat-label>Endereço</mat-label>
        <input matInput autocomplete="new-description" formControlName="description" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field>
        <mat-label>Número</mat-label>
        <input matInput autocomplete="new-number" formControlName="number" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
      <mat-form-field>
        <mat-label>Complemento</mat-label>
        <input matInput autocomplete="off" formControlName="complement" />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
</mat-card>
