import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommercielSellingRoutingModule } from './commerciel-selling-routing.module';
import { CommercialSellingIndexComponent } from './commercial-selling-index/commercial-selling-index.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, _MatMenuDirectivesModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommercialSellingListComponent } from './components/commercial-selling-list/commercial-selling-list.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatStepperModule } from '@angular/material/stepper';
import { CommercialSellingViewComponent } from './components/commercial-selling-view/commercial-selling-view.component';
import { SlaughteredAnimalsComponent } from './components/slaughtered-animals/slaughtered-animals.component';
import { SlaughteredLotAnimalsComponent } from './components/slaughtered-lot-animals/slaughtered-lot-animals.component';
import { AreasModalComponent } from './components/areas-modal/areas-modal.component';
import { MatChipsModule } from '@angular/material/chips';


@NgModule({
  declarations: [
    CommercialSellingIndexComponent,
    CommercialSellingListComponent, 
    CommercialSellingViewComponent, SlaughteredAnimalsComponent, SlaughteredLotAnimalsComponent, AreasModalComponent,
  ],
  imports: [
    CommonModule,
    CommercielSellingRoutingModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatSelectModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    _MatMenuDirectivesModule,
    NgxDropzoneModule,
    MatStepperModule,
    MatChipsModule,


  ]
})
export class CommercielSellingModule { }
