import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PecLookupTypeEnum } from '../../enums/PecLookupTypeEnum';
@Component({
  selector: 'app-lookup-modal',
  templateUrl: './lookup-modal.component.html',
  styleUrls: ['./lookup-modal.component.scss']
})
export class LookupModalComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(false, []);
  recordSize = 0;
  pageIndex: number = 1;
  pageSize: number = 8;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.displayedColumns = this.data.columns.map((value: any) => {
      return value.name
    })
    this.displayedColumns.unshift('select');
    if (this.data.type === PecLookupTypeEnum.SERVICE) {
      this.getItems();
    }
  }

  selectRow(row: any, event?: any) {
    this.selection.toggle(row);
    
    if (!event?.checked) {
      this.selection.clear();
    }
  }
  
  stopPropagation(event: Event) {
    event.stopPropagation()
  }

  pageChange(pageEvent: PageEvent): void {
    if (this.data.type === PecLookupTypeEnum.SERVICE) {
      this.pageIndex = pageEvent.pageIndex + 1;
      this.pageSize = pageEvent.pageSize;
      this.getItems();
    }
  }

  getItems() {
    this.data.service.getAll(this.pageIndex, this.pageSize, this.data.serviceFilter)
      .subscribe((value: any) => {
        this.dataSource.data = value.items;
        this.paginator.length = value.pagination.totalRecords;
      });
  }

  save(select: any): void {
    if (select.length > 0) {
      this.dialogRef.close(select[0])
    }
  }

  close(): void {
    this.dialogRef.close(false)
  }

}
