import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {AnimalInternalMovementInterface} from '../../interfaces/animalMovement/AnimalInternalMovementInterface'
import {AuthService} from '../auth/auth.service';
import PecControlHeaderRequest from '../../utils/PecControlHeaderRequest';
import {SERVER_URL} from '../../config/env';
import { GenericFilterResponse } from 'src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse';
import { ContextService } from '../context/context.service';

const ROUTINE_PATH = '/api/animalInternalMovement'

@Injectable({
  providedIn: 'root'
})
export class AnimalInternalMovementService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) {
  }

  farmId = this.contextService.getFarm();

  create(movement: AnimalInternalMovementInterface): Observable<any> {
    movement.farmId = this.farmId;
    return this.http.post(`${this.authService.getUrlBackEnd()}${ROUTINE_PATH}`, {movement}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number, filters?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    
    if (filters) {
      for(const filter of filters) {
        if (filter.fieldName && filter.fieldValue) {
          filterQueryString += `&${filter.fieldName}=${filter.fieldValue}`;
        }
      }
    }
    const parameters = `page=${page}&limit=${limit}${filterQueryString}`
    
    return this.http.get(`${this.authService.getUrlBackEnd()}${ROUTINE_PATH}?${parameters}&farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}${ROUTINE_PATH}/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(movement: AnimalInternalMovementInterface): Observable<any> {
    return this.http.put(`${this.authService.getUrlBackEnd()}${ROUTINE_PATH}`, {
      movement
    }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}${ROUTINE_PATH}/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
}
