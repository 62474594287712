import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductionValueService } from 'src/app/services/commercial/production-value.service';
import { ContextService } from 'src/app/services/context/context.service';

@Component({
  selector: 'app-production-values-buy-and-sell',
  templateUrl: './production-values-buy-and-sell.component.html',
  styleUrls: ['./production-values-buy-and-sell.component.scss']
})
export class ProductionValuesBuyAndSellComponent implements OnInit, OnChanges {

  @Input() initialDate: any;
  @Input() finalDate: any;
  
  @Output() buyAndSellingDatas = new EventEmitter();
  farmId: number;

  isLoading: boolean = false;

  displayedColumnsBuyAndSell: string[] = [
    'specification', 'animalQuantity', 'aliveWeightAverageInKg', 'deadWeightAverageInKg', 'deadWeightAverageInArroba', 'totalWeightInArroba'
  ];

  dataSourceBuyingAndSellingDatas = new MatTableDataSource();

  constructor(
    private productionValueService: ProductionValueService,
    public contextService: ContextService,

  ) { }

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();
    this.getBuyingAndSelling()

  }

  ngOnChanges(event: any) {
    if(event.initialDate){
      this.initialDate = event.initialDate.currentValue;
    }
    if(event.finalDate){
      this.finalDate = event.finalDate.currentValue;
    }
    
    if(this.initialDate && this.finalDate){
      this.getBuyingAndSelling()
    }
  }

  getBuyingAndSelling(){
    this.productionValueService.sellingDatasInAPeriod(this.initialDate, this.finalDate, this.farmId).subscribe(values => {
      
      this.isLoading = false;
      this.dataSourceBuyingAndSellingDatas = values.items;
      this.buyAndSellingDatas.emit(values.items)
      
    })
    
  }

}
