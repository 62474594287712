import { EventEmitter, Injectable } from "@angular/core";
import { UserAuthInterface } from "src/app/interfaces/user-auth-interface";

@Injectable({
  providedIn: "root",
})
export class ContextService {
  private farm: number;
  private descriptionFarm: string;
  farmChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  getSession(): UserAuthInterface {
    return JSON.parse(localStorage.getItem("P3cC0nTrolJWT"));
  }

  setDescriptionCurrentFarm(descriptionFarm: string): void {
    this.descriptionFarm = descriptionFarm;
  }

  getCurrentDescriptionFarm(): string {
    return this.descriptionFarm;
  }
  setFarm(farm: number) {
    localStorage.setItem("P3cC0nTrolFarm", String(farm));
    this.farm = farm;
    this.farmChange.emit(farm);
  }

  getFarm(): number {
    const farmNumber = localStorage.getItem("P3cC0nTrolFarm");
    return Number(farmNumber ? farmNumber : null);
    // return this.farm;
  }

  deleteFarm() {
    localStorage.removeItem("P3cC0nTrolFarm");
  }

  setFarmConfigIntegration(config: any) {
    localStorage.removeItem("config_integration");
    localStorage.setItem("config_integration", config);
  }

  getFarmConfigIntegrationSplitCostFixed(): boolean {
    let config: any = JSON.parse(localStorage.getItem("config_integration"));
    return config ? config.splitCostFixed : false;
  }

  getFarmConfigIntegrationsincStock(): boolean {
    let config: any = JSON.parse(localStorage.getItem("config_integration"));
    return config ? config.sincStock : false;
  }
}
