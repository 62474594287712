import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {

  @Input('form-title') formTitle: string;

  @Input('button-text') buttonText: string;
  @Input('button-back-text') buttonBackText = 'Voltar';
  @Input('icon-back-button') iconButtonBack = 'keyboard_backspace';
  @Input('button-link') buttonLink: string;
  @Input('icon-button') iconButton: string;

  @Input('buttonBack-link') buttonBackLink: string;

  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isValidBack: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() {
  }

  ngOnInit(): void {
  }

  eventButton() {
    this.isValid.emit(true);
  }

  eventButtonBack() {
    this.isValidBack.emit(true);
  }

}
