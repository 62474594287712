import { Component, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-form-field-error",
  template: `
    <p class="text-danger">
      {{ errorMessage }}
    </p>
  `,
  styleUrls: ["./form-field-error.component.scss"],
})
export class FormFieldErrorComponent implements OnInit {
  @Input("form-control") formControl: FormControl;

  constructor() {}

  ngOnInit(): void {}

  public get errorMessage(): string | null {
    if (this.mustShowErrorMessage()) return this.getErrorMessage();
    else return null;
  }

  private mustShowErrorMessage(): boolean {
    return this.formControl.invalid && this.formControl.touched;
  }

  private getErrorMessage(): string | null {
    if (this.formControl.errors.required) return "Campo obrigatório";

    if (this.formControl.errors.min) {
      const requiredLength = this.formControl.errors.min.min;
      return `Não pode ser inferior ${requiredLength}`;
    }
    if (this.formControl.errors.max) {
      const requiredLength = this.formControl.errors.max.max;
      return `Não pode ser superior ${requiredLength}`;
    }
    if (this.formControl.errors.minlength) {
      const requiredLength = this.formControl.errors.minlength.requiredLength;
      return `Tamanho minimo campo ${requiredLength}`;
    }
    if (this.formControl.errors.maxlength) {
      const requiredLength = this.formControl.errors.maxlength.requiredLength;
      return `Tamanho máximo campo ${requiredLength}`;
    }
    if (this.formControl.errors.dup) {
      return "Campo Duplicado";
    }
    if (this.formControl.errors.sisbovError) {
      return "O número do SISBOV está incorreto";
    }
  }
}
