import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CharacteristicInterface} from '../../../../../interfaces/animal/CharacteristicInterface';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'pec-animal-characteristic-input',
  templateUrl: './animal-characteristic-input.component.html',
  styleUrls: ['./animal-characteristic-input.component.scss']
})
export class AnimalCharacteristicInputComponent implements OnInit, OnChanges {

  @Input() characteristics: CharacteristicInterface[];
  @Input() formGroup: FormGroup;

  characteristic: CharacteristicInterface;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  changeCharacteristic(characteristic: CharacteristicInterface): void {
    this.characteristic = characteristic;
    this.formGroup.controls.characteristicId.setValue(characteristic);
  }

}
