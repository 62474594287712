import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export enum VALUE_TYPE_SMART_TABLE {
  INTEGER,
  FLOAT,
  CURRENCY,
  STRING,
  DATE,
  OPTIONS
}

type TOptionSmartTable = {
  description: string;
  icon?: string;
  routerLink?: string;
  idClick: number;
}

export type TOptionsSmartTable = Array<TOptionSmartTable>

type TSmartTable = {
  columnDef: string;
  headerCellDef: string
  valueType: VALUE_TYPE_SMART_TABLE
  hide?: boolean
}

export type TSmartTableData = Array<TSmartTable>

export type TClickSmartTable<T> = {
  idClick: number;
  element: T
}

@Component({
  selector: 'pec-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})
export class SmartTableComponent extends MatPaginatorIntl implements OnInit, OnChanges {
  @Input('data-source') dataSource = new MatTableDataSource<any>();
  @Input('options') options: TOptionsSmartTable
  @Input('element-columns') elementColumns: TSmartTableData;
  @Input('hide-paginator') hidePaginator: boolean;
  @Input('list-message') listMessage: string;
  @Input('total-records') totalRecords: number
  @Input('is-loading') isLoading: boolean

  @Output('pagination-event') paginationEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output('get-click') eventClick: EventEmitter<any> = new EventEmitter<any>();

  itemsPerPageLabel = 'Itens por página';
  nextPageLabel = 'Próxima página';
  previousPageLabel = 'Página atual';
  firstPageLabel = 'de'
  lastPageLabel = 'de'

  displayedColumns: string[] = []
  valueType = VALUE_TYPE_SMART_TABLE;
  isClickAddLine: boolean

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes.hasOwnProperty('elementColumns')) {
      if (changes.elementColumns.currentValue) {
        this.displayedColumns = this.elementColumns.map(value => { return value.columnDef })        
      }
    }
  }

  returnCondition(value: any, type: any) {
    if (value.valueType === type) {
      return true
    }
    return false
  }

  onPageChange(pageEvent: PageEvent) {
    this.paginationEvent.emit(pageEvent)
  }

  setClick(id: string, element: any): void {
    this.eventClick.emit( { idClick: id, element: element })
  }

}
