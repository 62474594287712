import { Component } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "pec-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent {
  constructor(private snackBar: MatSnackBar) {}

  public configSuccess: MatSnackBarConfig = {
    panelClass: ["style-snack-succes-bgColor"],
    duration: 8000,
    horizontalPosition: "right",
    verticalPosition: "bottom",
  };

  public configError: MatSnackBarConfig = {
    panelClass: ["style-snack-error"],
    duration: 3000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  public configAlert: MatSnackBarConfig = {
    panelClass: ["style-snack-alert"],
    duration: 6000,
    horizontalPosition: "right",
    verticalPosition: "bottom",
  };

  public snackbarSuccess(message: string) {
    this.snackBar.open(message, "[Fechar]", this.configSuccess);
  }

  public snackbarError(message: string) {
    this.snackBar.open(message, "[Fechar]", this.configError);
  }

  public snackbarAlert(message: string) {
    this.snackBar.open(message, "[Fechar]", this.configAlert);
  }
}
