import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmCancelModalComponent } from "src/app/components/shared/confirm-cancel-modal/confirm-cancel-modal.component";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { FormTypeEnum } from "src/app/enums/FormTypeEnum";
import { ParamsInterface } from "src/app/interfaces/params/ParamsInterface";
import { ConfigIntegration } from "src/app/pages/config-integration/components/config-integration-forms/config-integration-forms.component";
import { ContextService } from "src/app/services/context/context.service";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";
import { ConfigIntegrationService } from "../../../../services/configIntegration/config-integration.service";

@Component({
  selector: "app-integration-create",
  templateUrl: "./integration-create.component.html",
  styleUrls: ["./integration-create.component.scss"],
})
export class IntegrationCreateComponent implements OnInit {
  integration: ParamsInterface;
  formType = FormTypeEnum.CREATE;
  formValid = false;
  farmId: any;

  columns: string[] = ["containicial", "contafinal", "conta", "tm", "options"];
  dataSource = new MatTableDataSource<ConfigIntegration>();
  totalRecords = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private integrationService: FarmIntegrationService,
    private snackBar: SnackbarComponent,
    private contextService: ContextService,
    private configService: ConfigIntegrationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();
    this.getConfigIntegrationDatas(1, 10);
  }

  setFormValid(valid: boolean) {
    this.formValid = valid;
  }

  setIntegration(integration: ParamsInterface) {
    this.integration = integration;
    this.integration.farmId = String(this.farmId);
  }

  create() {
    this.integrationService.create(this.integration).subscribe(
      (res) => {
        this.snackBar.snackbarSuccess("Integração criada com sucesso!");
        this.router.navigate(["../"], { relativeTo: this.route });
      },
      (err) => {
        this.snackBar.snackbarError("Erro ao adicionar integração");
      }
    );
  }

  getConfigIntegrationDatas(index: number, limit: number) {
    this.configService.getAll(index, limit).subscribe(
      (res) => {
        this.dataSource.data = res.items;
        this.totalRecords = res.pagination.totalRecords;
      },
      (err) => {
      }
    );
  }

  onPageChange(e: any) {
    this.getConfigIntegrationDatas(e.pageIndex + 1, e.pageSize);
  }

  delete(integrationConfig: ConfigIntegration) {
    this.dialog
      .open(ConfirmCancelModalComponent, {
        width: "250px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: "Excluir Configuração",
          description: "Confirma exclusão da configuração?",
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.configService.delete(integrationConfig.id).subscribe(
            (res) => {
              this.snackBar.snackbarSuccess(
                "Configuração excluída com sucesso!"
              );
              this.getConfigIntegrationDatas(1, 10);
            },
            (err) => {
              this.snackBar.snackbarError(
                "Não foi possível excluir a configuração"
              );
            }
          );
        }
      });
  }
}
