import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { FarmAreaInterface } from "../../../interfaces/farm/FarmArea";
import PecControlHeaderRequest from "../../../utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

const ROUTINE_PATH = "/api/farmArea";

@Injectable({
  providedIn: "root",
})
export class FarmAreaService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) {}

  farmId = this.contextService.getFarm();

  create(farmArea: FarmAreaInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}${ROUTINE_PATH}`,
      { farmArea, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(
    page: number,
    limit: number,
    filters?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";

    if (filters) {
      for (const filter of filters) {
        if (filter.fieldName && filter.fieldValue) {
          filterQueryString += `&${filter.fieldName}=${filter.fieldValue}`;
        }
      }
    }
    const parameters = `page=${page}&limit=${limit}${filterQueryString}`;

    return this.http.get(
      `${this.authService.getUrlBackEnd()}${ROUTINE_PATH}?${parameters}&farmId=${
        this.farmId
      }`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(farmId: number): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}${ROUTINE_PATH}/${farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  update(farmArea: FarmAreaInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}${ROUTINE_PATH}`,
      { farmArea },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
