import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { PaginationService } from 'src/app/directives/Services-for-directives/Pagination.service';
import { SlaughterService } from 'src/app/services/commercial/slaughter.service';
import { ContextService } from 'src/app/services/context/context.service';
import { YieldSlaughterReportService } from 'src/app/services/reports/commercial/yield-slaughter-report.service';

@Component({
  selector: 'app-commercial-selling-list',
  templateUrl: './commercial-selling-list.component.html',
  styleUrls: ['./commercial-selling-list.component.scss']
})
export class CommercialSellingListComponent implements OnInit {

  farmId: number;
  dataSource = new MatTableDataSource<any>();
  pageIndex: number = 1;
  pageSize: number = 10;
  loading!: boolean;
  totalRecords!: number;
  columns: string[] = ['animalQuantity', 'customer', 'outputDate', 'weight', 'lotWeight', 'options']


  constructor(
    private readonly contextService: ContextService,
    private slaughterService: SlaughterService,
    private yieldSlaughterReportService: YieldSlaughterReportService,
    private snackBar: SnackbarComponent,
    private paginationService: PaginationService

  ) { }

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();

    let pageSize = this.paginationService.getPageSize();
    pageSize > 0 ? this.getSlaughter(1, pageSize) : this.getSlaughter(1, 10);

  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    this.getSlaughter(event.pageIndex + 1, event.pageSize)
  }

  getSlaughter(index: number, limit: number): void {
    this.loading = true;
    this.slaughterService.getAll(index, limit).subscribe((value) => {

      this.dataSource.data = value.items;
      this.totalRecords = value.pagination.totalRecords;

    })

  }

  generateExcel(outputoperationId: any, id: any){
    this.yieldSlaughterReportService.getSlaughterPerOperationReport(outputoperationId, id, this.farmId).subscribe(
      (value) => {
        const url = window.URL.createObjectURL(value);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display:none");
        a.href = url;
        a.download = `Relatório Pesos e Vendas ${moment().format(
          "DD/MM/YYYY HH:MM"
        )}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (err) => {
        this.snackBar.snackbarError("Erro ao gerar relatório");
      }
    );


  }

}
