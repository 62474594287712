<mat-toolbar>
    <mat-toolbar-row class="sizeToolbar">
      <span class="toolbar-spacer-left"></span>
      <span class="toolbar-title">Manejo de Pastagem e Unidade Animal (UA)</span>
      <span class="toolbar-spacer"></span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container">
    <app-pasture-manegement-list></app-pasture-manegement-list>
  </div>