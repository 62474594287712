import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductionValuesRoutingModule } from './production-values-routing.module';
import { ProductionValuesIndexComponent } from './production-values-index/production-values-index.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProductionValuesTablesComponent } from './components/production-values-tables/production-values-tables.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DirectivesModule } from 'src/app/directives/Directives.module';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { ProductionValuesBuyAndSellComponent } from './components/production-values-buy-and-sell/production-values-buy-and-sell.component';
import { PraductionValuesProductionDatasComponent } from './components/praduction-values-production-datas/praduction-values-production-datas.component';
import { ProductionValuesContribuitionMarginComponent } from './components/production-values-contribuition-margin/production-values-contribuition-margin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CattlePositionCalculousComponent } from './components/cattle-position-calculous/cattle-position-calculous.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [ProductionValuesIndexComponent, ProductionValuesTablesComponent, ProductionValuesBuyAndSellComponent, PraductionValuesProductionDatasComponent, ProductionValuesContribuitionMarginComponent, CattlePositionCalculousComponent],
  imports: [
    CommonModule,
    ProductionValuesRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatSelectModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    DirectivesModule,
    SharedModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule

  ]
})
export class ProductionValuesModule { }
