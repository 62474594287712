import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PecGenericFilterFields} from '../../../interfaces/utils/PecGenericFilterFields';
import {GenericFilterResponse} from '../pec-common-filter/interaces/GenericFilterResponse';

@Component({
  selector: 'pec-common-create-modal',
  templateUrl: './pec-common-create-modal.component.html',
  styleUrls: ['./pec-common-create-modal.component.scss']
})
export class PecCommonCreateModalComponent implements OnInit {

  filterFields: GenericFilterResponse[];
  titleModal: string = "Criar/Editar"

  constructor(

    public dialogRef: MatDialogRef<PecCommonCreateModalComponent>,

    @Inject(MAT_DIALOG_DATA) public data: PecGenericFilterFields[],

  ) {
  }

  ngOnInit(): void {
  }

  setFilterFields(fields: GenericFilterResponse[]) {
    this.filterFields = fields;
  }


}
