import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

export type TAnimalBalanceReport = {
  farmId: number;
  date: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnimalBalanceReportService implements ReportInterface<TAnimalBalanceReport, Blob> {
  farmId!: number
  reportFilters: TAnimalBalanceReport
  title!: string
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: 'date',
      type: GenericFilterTypeEnum.DATE,
      label: 'Selecione a data',
      fieldValue: '',
    }
  ];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.title = 'Saldo de Animais'
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      date: '',
    }

    const data: TDataSmartReport<TAnimalBalanceReport> = {
      title: this.title,
      description: 'Selecione as opções para gerar o relatório',
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel]
    }
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: 'Este relatório trás o saldo de animais vivos na data selecionada.',
      description: [
        'Informações Contidas no relatório',
        '-> Quantidade de animais totais',
        '-> Quantidade de animais por faixa etária',
        '---------------------------',
        '-> Identificação (SISBOV)',
        '-> Manejo',
        '-> Raça',
        '-> Sexo',
        '-> Idade em mêses',
        '-> Data da última pesagem',
        '-> Último peso',
      ]
    }
    this._bottomSheet.open(BottomSheetComponent, { data })
  }

  getReport(datas: TAnimalBalanceReport): Observable<any> {
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    }
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animalBalanceReport`,
      datas,
      config
    );
  }


}
