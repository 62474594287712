import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";
import { SERVER_URL } from "src/app/config/env";
import { ParamsInterface } from "src/app/interfaces/params/ParamsInterface";
import { FixedCostsInterface } from "src/app/interfaces/stock/FixedCostsInterface";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

@Injectable({
  providedIn: "root",
})
export class FarmIntegrationService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) { }

  farmId = this.contextService.getFarm();


  create(integrationType: ParamsInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/protheusParams/create`,
      { ...integrationType },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(
    page: number,
    limit: number,
    filter?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/protheusParams?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(integrationId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/protheusParams/${integrationId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  update(integrationType: ParamsInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/protheusParams/`,
      { ...integrationType, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  delete(integrationId: number): Observable<any> {
    return this.http.delete(
      `${this.authService.getUrlBackEnd()}/api/protheusParams/delete/${integrationId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  closeMonth(integration: ParamsInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/protheusParams/closeMonth`,
      { ...integration },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getParamsId(paramsId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/pecCost/params/${paramsId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  getPecCostId(integrationId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/pecCost/${integrationId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  getAllPecCost(
    page: number,
    limit: number,
    filter?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/pecCost?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  createPecCost(pecCostType: FixedCostsInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/pecCost/create`,
      { ...pecCostType },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  updatePecCost(newPecCost: FixedCostsInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/pecCost`,
      { ...newPecCost },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  assessmentPecCost(assessment: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/pecCost/fixedCostSplit`,
      { assessment, farmId: this.farmId },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  generateIntegrationExcel(id: number): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/pecCost/fixedCostsReport`,
      { id },
      {
        responseType: "blob",
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  generalFixedCostsReport(dates: any): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/pecCost/generalFixedCostsReport`,
      { dates},
      {
        responseType: "blob",
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getOperationsProtheus(): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/operationsprotheus?farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  revertOperationsProtheus(documentRevert: string, isRevert?: boolean): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/operationsprotheus/revert`,
      {
        documentRevert,
        isRevert
      },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      });
  }

  reportOperationsProtheus(params: any): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/operationsprotheus/reportoperations`,
      {
        params,
        farmId: this.farmId
      },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
        responseType: "blob"
      });
  }
}
