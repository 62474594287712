import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SlaughterService } from 'src/app/services/commercial/slaughter.service';


export interface SlaughteredAnimalsLot {
  area: string;
  animalQuantity: number;
  inputWeightArroba: number;
  outputWeight: number;
  days: number;
  gmdConf: number;
  gmdLiquid: number;
  deadWeightArroba: number;
  yields: number;
  daysForOneArroba: number;
  arrobaQuantity: number;
  arrobaPrice: number;
  animalLiquidValue: number;
};

@Component({
  selector: 'app-slaughtered-lot-animals',
  templateUrl: './slaughtered-lot-animals.component.html',
  styleUrls: ['./slaughtered-lot-animals.component.scss']
})
export class SlaughteredLotAnimalsComponent implements OnInit {
  @Output() areas: EventEmitter<any> = new EventEmitter<any>();

  columns: string[] = [
    "area",
    "animalQuantity",
    "inputWeightArroba",
    "outputWeight",
    "deadWeight",
    "gmdConf",
    "cost",
    "gmdLiquid",
    "days",
    "daysForOneArroba",
    "arrobaQuantity",
    "yields",
    "deadWeightArroba",
    "arrobaPrice",
    "animalLiquidValue",
    "margem",
  ];

  dataSource = new MatTableDataSource<SlaughteredAnimalsLot>();
  totalRecords = 0;
  loading = false;
  allDatas: SlaughteredAnimalsLot[];
  amountAnimals: number[] = [];
  slaughterId: number;



  constructor(
    private slaughterService: SlaughterService,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {
    const datas = this.route.paramMap.pipe(
      switchMap((params) => {


        const id = Number(params.get("id"));
        this.slaughterId = id;
        return this.slaughterService.getById(id);
      })
    );

    datas.subscribe((value) => {

      this.slaughterService.getSlaughteredAnimals(value.items.outputOperationId, this.slaughterId).subscribe((res) => {
        this.dataSource = res.items.modelSlaughterLot;

        for (let obj of res.items.modelSlaughterLot) {
          this.areas.emit(obj.areas)

        }


      }, (err) => {
      })
    });
  }

}
