<div class="container">
<mat-card>
  <form [formGroup]="integrationsFormGroup">
    <mat-card-header>
      <h3 class="set">Integração Protheus</h3>
    </mat-card-header>

    <mat-grid-list rowHeight="100px" cols="4">
      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Classe Inicial</mat-label>
          <input matInput formControlName="classeinicial" type="text" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Classe Final</mat-label>
          <input matInput formControlName="classefinal" type="text" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Ano/Mês</mat-label>
          <input matInput formControlName="mes" type="text" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Conta Sanitário</mat-label>
          <input matInput formControlName="conta_sanitario" type="text" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>

    </mat-grid-list>

    <mat-grid-list rowHeight="100px" cols="3">
      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option value="1">Não Processado</mat-option>
            <mat-option value="2">Processando</mat-option>
            <mat-option value="3">Pronto</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Exceções de débito</mat-label>
          <input matInput formControlName="excecaocldebito" type="text" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>Exceções de crédito</mat-label>
          <input matInput formControlName="excecaoclcredito" type="text" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</mat-card>
</div>
