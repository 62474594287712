import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs';
import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';

import { NutritionalLotInterface } from '../../../interfaces/nutrition/NutritionalLotInterface';
import { ContextService } from '../../context/context.service';
import { DietPlanningLotInterface } from 'src/app/interfaces/nutrition/DietPlanningLotInterface';
import { TResultGetAll } from 'src/app/types/TResultGelAll';
import { TResultNutritionalLot } from 'src/app/pages/nutrition/nutritionallot/nutritionallot-index/nutritionallot-index.component';
import { TTransferNutritionalLot } from 'src/app/pages/nutrition/nutritionallot/nutritional-lot-transfer.service';

type TParamNutritionalLotArea = {
  farmId: number
}
export type TResultNutritionalLotArea = {
  farmAreaId: number
  areaDescription: string,
  animalQuantity: number,
  averageWeight: number,
}

export type TDataNutritionalLotArea = {
  data: Array<TResultNutritionalLotArea>
}

export type TParamUpdateNutritionalLot = {
  farmId: number
  nutritionalLotId: number
  breedId?: number
  initialWeight?: number
  amountAnimals?: number
  status?: number
  dietPlanningLot?: DietPlanningLotInterface[];
}

export type TParamUpdateDietLot = {
  farmId: number,
  nutritionalPlanningId: number,
  nutritionalLotId: number,
  dietPlanningId?: number, 
  changeDate?: Date
}

export type TResultUpdateNutritionalLot = {
  message: string
  error?: {message: string} 
  isError: boolean
} 

@Injectable({
  providedIn: 'root'
})
export class NutritionallotService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) { }

  farmId = this.contextService.getFarm();

  create(nutritionalLot: NutritionalLotInterface): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/nutritionalLot`, { nutritionalLot, farmId: this.farmId }, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number, filters: string, order: string): Observable<TResultGetAll<TResultNutritionalLot>> {
    const url = `${this.authService.getUrlBackEnd()}/api/nutritional/nutritional-lot/nutritional-lot/?page=${page}&limit=${limit}&filters=${filters}&order=${order}`
    const options = { headers: PecControlHeaderRequest.getHeader(this.authService.token) }
    
    return this.http.get<TResultGetAll<TResultNutritionalLot>>(url, options);
  }

  getNutritionalLotArea(param: TParamNutritionalLotArea): Observable<TDataNutritionalLotArea> {
    let params = new HttpParams()
    params = params.append('farmId', String(param.farmId));
    
    const url = `${this.authService.getUrlBackEnd()}/api/nutritional/nutritional-lot/nutritional-lot-area`
    const options = { headers: PecControlHeaderRequest.getHeader(this.authService.token), params }
    
    return this.http.get<TDataNutritionalLotArea>(url, options);
  }

  update(nutritionalLot: TParamUpdateNutritionalLot): Observable<TResultUpdateNutritionalLot> {
    return this.http.put<TResultUpdateNutritionalLot>(`${this.authService.getUrlBackEnd()}/api/nutritional/nutritional-lot/updated`, nutritionalLot, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  updateDiet(updateDietLot: TParamUpdateDietLot): Observable<TResultUpdateNutritionalLot> {
    return this.http.put<TResultUpdateNutritionalLot>(`${this.authService.getUrlBackEnd()}/api/nutritional/nutritional-lot/diet-updated`, updateDietLot, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }
  
  updateTransfer(updateTransfer: TTransferNutritionalLot): Observable<TResultUpdateNutritionalLot> {
    return this.http.put<TResultUpdateNutritionalLot>(`${this.authService.getUrlBackEnd()}/api/nutritional/nutritional-lot/transfer`, updateTransfer, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getInfoLot(): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/nutritionalLot/lot/${this.contextService.getFarm()}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getBreedFarmArea(areaId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/nutritionalLot/breed/farmArea?farmId=${this.farmId}&areaId=${areaId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(nutritionalLotId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/nutritionalLot/${nutritionalLotId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

}
