<div class="container-second">
  <form [formGroup]="animalTemporaryBalanceFormGroup">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Informações Operação de Entrada</mat-card-title>
      </mat-card-header>
      <mat-grid-list rowHeight="100px" cols="3">
        <mat-grid-tile colspan="1" >
          <mat-form-field>
            <mat-label>Movimento</mat-label>
            <mat-select formControlName="inputMovement">
              <mat-option value="C">Compra</mat-option>
              <mat-option value="N">Nascimento</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <mat-label>Produtor</mat-label>
            <input
              matInput
              formControlName="fGrower"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayFarmGrower"
              (optionSelected)="selectFarmGrower()"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list rowHeight="100px" cols="3">
        <mat-grid-tile colspan="1">
          <mat-form-field>
            <mat-label>Data de Chegada</mat-label>
            <input
              type="text"
              matInput
              [matDatepicker]="datepicker"
              formControlName="date"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datepicker> </mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card>

    <mat-card class="container-animal">
      <mat-card-header>
        <h3 class="set">Saldo de Animais</h3>
      </mat-card-header>
      <mat-grid-list
      class="align-left"
        rowHeight="2:1"
        formGroupName="animalAgeGroup"
        *ngFor="let animalAgeGroup of animalAgeGroups.controls"
        cols="3"
      >
        <app-animal-temporary-balance-age-groups
          [formGroup]="animalAgeGroup"
          [formType]="formTypeInput"
        >
        </app-animal-temporary-balance-age-groups>
      </mat-grid-list>
    </mat-card>
  </form>
</div>
