<section class="flex center">
  <span class="spacer-left"></span>
  <h3>{{ listTitle }}</h3>
  <span class="spacer-right"></span>

  <button
    *ngIf="!buttonHidden"
    mat-raised-button
    color="primary"
    (click)="eventButton()"
    [routerLink]="buttonLink"
  >
    <mat-icon>{{ iconButton }}</mat-icon>
    {{ buttonText }}
  </button>
</section>
