<div class="grid-container">
  <div class="grid-spinner">
    <mat-spinner color="primary"></mat-spinner>
  </div>
  <!-- <div class="grid-spinner-text">
    <p>
      {{ text }}
    </p>
  </div> -->
</div>
