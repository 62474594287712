import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { PecGenericFilterFields } from "../../../interfaces/utils/PecGenericFilterFields";
import { GenericFilterResponse } from "./interaces/GenericFilterResponse";

@Component({
  selector: "pec-common-filter",
  templateUrl: "./pec-common-filter.component.html",
  styleUrls: ["./pec-common-filter.component.scss"],
})
export class PecCommonFilterComponent implements OnInit, OnChanges {
  genericFilterTypeEnum = GenericFilterTypeEnum;

  @Input() fields: PecGenericFilterFields[] = [];

  @Output() getFilterFields: EventEmitter<GenericFilterResponse[]> =
    new EventEmitter<GenericFilterResponse[]>();
  @Output() getValidatedFilterFields: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  filterForm = new FormGroup({
    formFields: this.formBuilder.array([]),
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.filterForm.valueChanges.subscribe((value) => {
      this.getValidatedFilterFields.emit(this.filterForm.valid);

      if (this.filterForm.valid) {
        this.getFilterFields.emit(value.formFields);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("fields" in changes) {
      for (const field of changes.fields.currentValue) {
        if (field?.required) {
          this.getValidatedFilterFields.emit(false);
        }
        this.formFields.push(
          new FormGroup({
            fieldName: new FormControl(field.fieldName),
            fieldValue: new FormControl(field.fieldValue),
          })
        );
      }
    }
  }

  public get formFields() {
    return this.filterForm.get("formFields") as FormArray;
  }
}
