<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table">
    <div *ngFor="let rowCollumn of elementColumns">
        <ng-container [matColumnDef]="rowCollumn['columnDef']">
            <th mat-header-cell style="background-color: whitesmoke;" *matHeaderCellDef [hidden]="rowCollumn.hide">{{ rowCollumn.headerCellDef }}</th>

            <div *ngIf="returnCondition(rowCollumn, valueType['OPTIONS'])">
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div  *ngFor="let option of options">
                            <button mat-menu-item (click)="setClick(option.idClick, element)">
                                <mat-icon>{{ option.icon }}</mat-icon>
                                <span>{{ option.description }}</span>
                            </button>
                        </div>                        
                    </mat-menu>
                </td>
            </div>

            <div *ngIf="returnCondition(rowCollumn, valueType['STRING'])">
                <td mat-cell *matCellDef="let element" [hidden]="rowCollumn.hide">
                    {{ element[rowCollumn.columnDef] }}
                </td>
            </div>
            <div *ngIf="returnCondition(rowCollumn, valueType['INTEGER'])" >
                <td mat-cell *matCellDef="let element" style="align-items: flex-end;" [hidden]="rowCollumn.hide">
                    {{ element[rowCollumn.columnDef] }}
                </td>
            </div>
            <div *ngIf="returnCondition(rowCollumn, valueType['DATE'])">
                <td mat-cell *matCellDef="let element" [hidden]="rowCollumn.hide">
                    {{ element[rowCollumn.columnDef] | date: 'dd/MM/yyyy' }}
                </td>
            </div>
            <div *ngIf="returnCondition(rowCollumn, valueType['FLOAT'])">
                <td mat-cell *matCellDef="let element" style="align-items: flex-end; " [hidden]="rowCollumn.hide">
                    {{ element[rowCollumn.columnDef] | number: '1.0-4' }}
                </td>
            </div>
            <div *ngIf="returnCondition(rowCollumn, valueType['CURRENCY'])" [hidden]="rowCollumn.hide">
                <td mat-cell *matCellDef="let element" style="align-items: flex-end;">
                    {{ element[rowCollumn.columnDef] | currency: 'BRL' }}
                </td>
            </div>

        </ng-container>
    </div>
    <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!isLoading" class="mat-cell" colspan="displayedColumns.length">{{ listMessage }}</td>
        <td *ngIf="isLoading" class="mat-cell" colspan="displayedColumns.length">
            <pec-list-loading text='Carregando registros...'></pec-list-loading>
        </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator
    class="paginator pagination" 
    [pageSizeOptions]="[10, 20, 50]"
    [length]="totalRecords"
    showFirstLastButtons (page)="onPageChange($event)">
</mat-paginator>