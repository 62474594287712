import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';

import {PecLookupTypeEnum} from '../../enums/PecLookupTypeEnum';
import {PecLookupInterface} from '../../interfaces/pec-lookup';
import {PecCommonFilterModalComponent} from '../../../pec-common-filter-modal/pec-common-filter-modal.component';
import {GenericFilterResponse} from '../../../pec-common-filter/interaces/GenericFilterResponse';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'pec-lookup-dialog',
  templateUrl: './lookup-dialog.component.html',
  styleUrls: ['./lookup-dialog.component.scss']
})
export class LookupDialogComponent implements OnInit, AfterViewInit {
  loading!: boolean;
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  recordSize = 0;
  filters: GenericFilterResponse[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LookupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PecLookupInterface
  ) {
  }

  ngOnInit(): void {
    this.displayedColumns = this.data.columns.map(value => {
      return value.name;
    });
    this.displayedColumns.unshift('select');

    if (this.data.type === PecLookupTypeEnum.SERVICE) {
      this.loading = true;

      this.data.service
        .getAll(1, 5)
        .subscribe(value => {
          this.getItems(1, 5);
          this.loading = false;
        });

    } else {
      this.dataSource.data = this.data.data;
    }
  }

  ngAfterViewInit() {
    if (this.data.type === PecLookupTypeEnum.LIST) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  /** Se o numero de selecionados for igual ao numero de linhas */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  selectRow(row: any) {
    if (!this.data.multiple) {
      this.selection.clear();
    }
    this.selection.toggle(row);
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pageChange(pageEvent: any): void {
    if (this.data.type === PecLookupTypeEnum.SERVICE) {
      this.getItems(pageEvent.pageIndex + 1, pageEvent.pageSize);
    }
  }

  getItems(index: number, size: number, filter?: GenericFilterResponse[]) {
    this.loading = true;

    this.data.service.getAll(index, size, filter)
      .subscribe(value => {
        this.dataSource.data = value.items;
        this.paginator.length = value.pagination.totalRecords;
        this.loading = false;
      });
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(PecCommonFilterModalComponent, {
      width: '600px',
      data: this.data.serviceFilter
    });
    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.data.serviceFilter = this.data.serviceFilter.map(v => {
          for (const f of value) {
            if (f.fieldName === v.fieldName) {
              return {...v, fieldValue: f.fieldValue};
            }
          }
          return {...v};
        });
        this.filters = value;
        this.getItems(1, 5, this.filters);
      }
    });
  }


}
