import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericFilterResponse } from 'src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse';
import { SlaughterInterface } from 'src/app/interfaces/commercial/slaughterInterface';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../auth/auth.service';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class SlaughterService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) { }

  farmId = this.contextService.getFarm();

  create(slaughter: SlaughterInterface): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/commercial/slaughter/create`,
      { ...slaughter },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }

    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/commercial/slaughter?page=${page}&limit=${limit}${filterQueryString}&farmId=${this.farmId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/commercial/slaughter/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  getSlaughteredAnimals(operationId: string, slaughterId?: number): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/commercial/slaughter/slaughtered`,
    {
      operationId: operationId,
      slaughterId
    }, 
    {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  update(slaughter: SlaughterInterface): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/commercial/slaughter`,
      { ...slaughter },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/commercial/slaughter/delete/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  deleteByOperationId(operationId: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/commercial/slaughter/deleteByOperation/${operationId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }
}
