import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

export type TAnimalAquisitionReport = {
  initialDate: string;
  finalDate: string;
  farmId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AnimalAquisitionReportService implements ReportInterface<TAnimalAquisitionReport, Blob> {
  farmId!: number
  reportFilters: TAnimalAquisitionReport
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "initialDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data",
      fieldValue: "",
    },
    {
      fieldName: "finalDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Selecione a data",
      fieldValue: "",
    },
  ];

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private dialog: MatDialog,
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet,
  ) { }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      initialDate: '',
      finalDate: '',
      farmId: this.farmId
    }

    const data: TDataSmartReport<TAnimalAquisitionReport> = {
      title: 'Aquisição de Animais',
      description: 'Selecione as opções para gerar o relatório',
      requiredFilters: true,
      reportFilterFields: this.reportFilterFields,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel]
    }
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: 'Aquisição animais',
      subtitle: 'Informações Contidas no relatório',
      description: [
        '-> Especificação Gado',
        '-> Data',
        '-> Fazenda Compra',
        '-> Quantidade',
        '-> Valor Pago',
        '-> Frete/Cab',
        '-> Comissão',
        '-> Valor Total',
        '-> Valor Bezerro',
        '-> Valor Bez. Total',
        '-> Peso Total(kg)',
        '-> Arroba',
        '-> Custo @'
      ]
    }
    this._bottomSheet.open(BottomSheetComponent, { data })
  }

  getReport(datas: TAnimalAquisitionReport): Observable<Blob> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/report/commercial/animalAquisition`,
      datas,
      { responseType: "blob", headers: PecControlHeaderRequest.getHeader(this.authService.token) }
    );
  }
}
