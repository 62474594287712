import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-praduction-values-production-datas',
  templateUrl: './praduction-values-production-datas.component.html',
  styleUrls: ['./praduction-values-production-datas.component.scss']
})
export class PraductionValuesProductionDatasComponent implements OnInit, OnChanges {

  @Input() positionAnimalTotalWeightDifference: number
  @Input() sellWeightInArroba: number
  @Input() buyWeightInArroba: number
  @Input() area: number

  dataSource = new MatTableDataSource<any>();

  productionArea = 0;
  sum = 0;

  productionArroba: number;
  productionperArea: number;


  displayedproductionDatas: string[] = [
    'production', 'productionArea', 'productionQuantities'
  ];

  constructor() { }

  ngOnInit(): void {

  }


  ngOnChanges(changes: SimpleChanges): void {
    let area = changes.area?.currentValue

    if (changes.area?.currentValue != undefined) {

      this.sum = this.positionAnimalTotalWeightDifference + this.sellWeightInArroba - this.buyWeightInArroba

        let model = [{
          production: this.positionAnimalTotalWeightDifference + this.sellWeightInArroba - this.buyWeightInArroba,
          productionArea: area,
          productionQuantities: (this.positionAnimalTotalWeightDifference + this.sellWeightInArroba - this.buyWeightInArroba)/area
        }];
        this.dataSource.data = model
    }
  }
}
