import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PecGenericFilterFields} from '../../../interfaces/utils/PecGenericFilterFields';
import {GenericFilterResponse} from '../pec-common-filter/interaces/GenericFilterResponse';

@Component({
  selector: 'pec-common-filter-modal-estorno',
  templateUrl: './pec-common-filter-modal-estorno.component.html',
  styleUrls: ['./pec-common-filter-modal-estorno.component.scss']
})
export class PecCommonFilterModalEstornoComponent implements OnInit {

  filterFields: GenericFilterResponse[];

  constructor(
    public dialogRef: MatDialogRef<PecCommonFilterModalEstornoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PecGenericFilterFields[]
  ) {
  }

  ngOnInit(): void {
  }

  setFilterFields(fields: GenericFilterResponse[]) {
    this.filterFields = fields;
  }


}
