<section class="flex center">
  <button mat-button (click)="eventButtonBack()" [routerLink]="buttonBackLink">
    <mat-icon>{{ iconButtonBack }}</mat-icon>
    {{ buttonBackText }}
  </button>
  <span class="spacer-left"></span>
  <h3>{{ formTitle }}</h3>
  <span class="spacer-right"></span>

  <button
    mat-raised-button
    color="primary"
    (click)="eventButton()"
    [routerLink]="buttonLink"
  >
    <mat-icon>{{ iconButton }}</mat-icon>
    {{ buttonText }}
  </button>
</section>
