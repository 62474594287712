import { Injectable } from '@angular/core';
import { AnimalInputGtaReportService } from 'src/app/services/reports/operation/gta/gta-report.service';
import { InputOperationsReportService } from 'src/app/services/reports/operation/input-operations-report.service';
import { InternalOperationsReportService } from 'src/app/services/reports/operation/internal-operations-report.service';
import { OutputOperationsReportService } from 'src/app/services/reports/operation/output-operations-report.service';
import { REPORT_BUTTON_EVENT, REPORT_CATEGORY, TReportIndex, TReportsIndex } from './report-index.service'

const OPERATIONS_REPORTS_ID = {
  inputOperation: 1,
  internalOperation: 2,
  outputOperation: 3,  
  animalGTA: 4,
}

@Injectable({
  providedIn: 'root'
})
export class ReportCategoryOperationService {
  private operationsReports: TReportsIndex
  
  constructor(
    private readonly inputOperationsReportService: InputOperationsReportService,
    private readonly internalOperationsReportService: InternalOperationsReportService,
    private readonly outputOperationsReportService: OutputOperationsReportService,
    private readonly aniInputGtaReportService: AnimalInputGtaReportService
  ) { }

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    if (reportButtonEvent === REPORT_BUTTON_EVENT.EXEC) {
      this.setExec(report)
    } else if (reportButtonEvent === REPORT_BUTTON_EVENT.INFO) {
      this.setInfo(report)
    }
  }

  setExec(report: TReportIndex) {
    if (report.id === OPERATIONS_REPORTS_ID.inputOperation) {
      this.inputOperationsReportService.openSmartReport()
    }
    if (report.id === OPERATIONS_REPORTS_ID.internalOperation) {
      this.internalOperationsReportService.openSmartReport()
    }
    if (report.id === OPERATIONS_REPORTS_ID.outputOperation) {
      this.outputOperationsReportService.openSmartReport()
    }
    if (report.id === OPERATIONS_REPORTS_ID.animalGTA) {
      this.aniInputGtaReportService.openSmartReport()
    }
  }
  
  setInfo(report: TReportIndex) {  
    if (report.id === OPERATIONS_REPORTS_ID.inputOperation) {
      this.inputOperationsReportService.openInfoReport()
    }
    if (report.id === OPERATIONS_REPORTS_ID.internalOperation) {
      this.internalOperationsReportService.openInfoReport()
    }
    if (report.id === OPERATIONS_REPORTS_ID.outputOperation) {
      this.outputOperationsReportService.openInfoReport()
    }
    if (report.id === OPERATIONS_REPORTS_ID.animalGTA) {
      this.aniInputGtaReportService.openInfoReport()
    }
  }

  getReportsOperations(): TReportsIndex {
    this.operationsReports = [      
      {
        id: OPERATIONS_REPORTS_ID.inputOperation,
        title: 'Operações de Entrada',
        description: 'Gera os dados dos animais nas operações de entrada.',
        category: REPORT_CATEGORY.OPERATIONS
      },
      {
        id: OPERATIONS_REPORTS_ID.internalOperation,
        title: 'Operações Internas',
        description: 'Gera os dados dos animais nas operações internas.',
        category: REPORT_CATEGORY.OPERATIONS
      },
      {
        id: OPERATIONS_REPORTS_ID.outputOperation,
        title: 'Operações de Saída',
        description: 'Gera os dados dos animais nas operações de saída.',
        category: REPORT_CATEGORY.OPERATIONS
      },      
      {
        id: OPERATIONS_REPORTS_ID.animalGTA,
        title: 'Animais por GTA de Entrada',
        description: 'Gera os dados dos animais por GTA.',
        category: REPORT_CATEGORY.OPERATIONS
      },
    ]

    return this.operationsReports
  }


}
