import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";

@Component({
  selector: "pec-change-animal-status-modal",
  templateUrl: "./change-animal-status-modal.component.html",
  styleUrls: ["./change-animal-status-modal.component.scss"],
})
export class ChangeAnimalStatusModalComponent implements OnInit {
  title!: string;
  inactiveAnimalForm: FormGroup = this.formBuilder.group({
    animalId: ["", Validators.required],
    observation: ["", [Validators.required, Validators.minLength(15)]],
    observationDate: ["", Validators.required],
    observationAlert: ["", Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.inactiveAnimalForm.controls.animalId.setValue(this.data.animalId);
  }

  save(): void {
    const observationDate: any =
      this.inactiveAnimalForm.controls.observationDate;
    this.inactiveAnimalForm.controls.observationDate.setValue(
      moment(observationDate).format("YYYY-MM-DD")
    );
    this.dialogRef.close(this.inactiveAnimalForm.value);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
