import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { FormTypeEnum } from 'src/app/enums/FormTypeEnum';
import { AnimalTemporaryBalanceService } from 'src/app/services/animal/animal/animal-temporary-balance.service';

@Component({
  selector: 'app-animal-temporary-balance-edit',
  templateUrl: './animal-temporary-balance-edit.component.html',
  styleUrls: ['./animal-temporary-balance-edit.component.scss']
})
export class AnimalTemporaryBalanceEditComponent implements OnInit {

  formType = FormTypeEnum.EDIT;
  inputOperation: any;
  inputOperationId: any;
  animalTemporaryDatas: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private animalTemporaryBalanceService: AnimalTemporaryBalanceService,
    private snackBar: SnackbarComponent
  ) { }

  ngOnInit(): void {
    const integrationTypeGetById = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        return this.animalTemporaryBalanceService.getById(id);
      })
    );
    integrationTypeGetById.subscribe((value) => {

      this.inputOperation = value.items;
      this.inputOperationId = value.items.id;

    });
  }

  save() {
    let model = {
      id: this.inputOperationId,
      date: this.animalTemporaryDatas.date,
      maleQuantity: this.animalTemporaryDatas.animalAgeGroup[0].maleValue,
      femaleQuantity: this.animalTemporaryDatas.animalAgeGroup[0].femaleValue,
      animalAgeGroupId: this.animalTemporaryDatas.animalAgeGroup[0].animalAgeGroupId,
      operationMovement: this.animalTemporaryDatas.inputMovement,
      farmGrowerId: this.animalTemporaryDatas.farmGrower.farmId,
    }
    this.animalTemporaryBalanceService.update(model).subscribe(
      (res) => {
        this.snackBar.snackbarSuccess("Animais Pendentes editados com sucesso!");
        this.router.navigate(["../.."], { relativeTo: this.route });
      },
      (error) => {
        this.snackBar.snackbarError("Erro ao editar animais pendentes");
      }
    );
  }

  setInputOperation(event: any) {
    this.animalTemporaryDatas = event

  }
}
