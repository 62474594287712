<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="farm">
    <th mat-header-cell *matHeaderCellDef [hidden]="hiddenColumn">Fazenda</th>
    <td
      mat-cell
      *matCellDef="let element"
      [hidden]="hiddenColumn"
      style="background-color: brown; color: white"
    >
      {{ element.farm.description }}
    </td>
  </ng-container>

  <ng-container matColumnDef="identifier">
    <th mat-header-cell *matHeaderCellDef>{{ identifierLabel }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.traceability ? element.traceability.sisbovNumber : "-" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="breed">
    <th mat-header-cell *matHeaderCellDef>Raça</th>
    <td mat-cell *matCellDef="let element">
      {{ element.breed?.description }}
    </td>
  </ng-container>

  <ng-container matColumnDef="sex">
    <th mat-header-cell *matHeaderCellDef>Sexo</th>
    <td mat-cell *matCellDef="let element">
      {{ element.sex }}
    </td>
  </ng-container>

  <ng-container matColumnDef="birthDate">
    <th mat-header-cell *matHeaderCellDef>Dt. Nascimento</th>
    <td mat-cell *matCellDef="let element">
      {{ element.birthDate | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="months">
    <th mat-header-cell *matHeaderCellDef>Meses</th>
    <td mat-cell *matCellDef="let element">
      {{ element.months }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon
        matTooltip="Ativo"
        matTooltipPosition="right"
        color="primary"
        *ngIf="element.status"
        >verified_user
      </mat-icon>
      <mat-icon
        matTooltip="Bloqueado"
        matTooltipPosition="right"
        color="warn"
        *ngIf="!element.status"
        >not_interested
      </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Opções</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openResume(element.animalId)">
          <mat-icon>pageview</mat-icon>
          <span>Resumo do Animal</span>
        </button>
        <button mat-menu-item (click)="insertObservation(element)">
          <span>Inserir Observação Animal</span>
        </button>
        <button mat-menu-item (click)="disableAnimal(element)">
          <span>Ativar/Inativar Animal</span>
        </button>
        <button mat-menu-item routerLink="./edit/{{ element.animalId }}">
          <span>Editar</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <tr class="mat-row" *matNoDataRow>
    <td *ngIf="!loading" class="mat-cell" colspan="7">
      Nenhum animal encontrado
    </td>
    <td *ngIf="loading" class="mat-cell" colspan="7">
      <pec-list-loading text="Carregando animais..."></pec-list-loading>
    </td>
  </tr>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
<mat-paginator
  mPaginationItemsPerPage
  class="paginator"
  [pageSizeOptions]="[10, 25, 50, 100]"
  showFirstLastButtons
  (page)="onPageChange($event)"
  [length]="totalRecords"
  [hidePageSize]="false"
></mat-paginator>
