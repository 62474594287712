import { Injectable } from "@angular/core";
import { AnimalAquisitionReportService } from "src/app/services/reports/commercial/animal-aquisition-report.service";
import { AnimalSaleResultReportService } from "src/app/services/reports/commercial/animal-sale-result-report.service";
import { SlaughterAnimalPlanningService } from "src/app/services/reports/commercial/slaughter-animal-planning.service";
import { YieldSlaughterReportService } from "src/app/services/reports/commercial/yield-slaughter-report.service";
import {
  REPORT_BUTTON_EVENT,
  REPORT_CATEGORY,
  TReportIndex,
  TReportsIndex,
} from "./report-index.service";

const COMMERCIAL_REPORT_ID = {
  purchaseAnimal: 1,
  saleSlaughter: 2,
  animalSaleResult: 3,
  slaughterAnimalPlanning: 4,
  costAnimal: 5,
};

@Injectable({
  providedIn: "root",
})
export class ReportCategoryCommercialService {
  private commercialReports: TReportsIndex;

  constructor(
    private readonly animalAquisitionReportService: AnimalAquisitionReportService,
    private readonly yieldSlaughterReportService: YieldSlaughterReportService,
    private readonly animalSaleResultReportService: AnimalSaleResultReportService,
    private readonly slaughterAnimalPlanningService: SlaughterAnimalPlanningService
  ) {}

  setReport(report: TReportIndex, reportButtonEvent: REPORT_BUTTON_EVENT) {
    if (reportButtonEvent === REPORT_BUTTON_EVENT.EXEC) {
      this.setExec(report);
    }
    if (reportButtonEvent === REPORT_BUTTON_EVENT.INFO) {
      this.setInfo(report);
    }
  }

  setExec(report: TReportIndex) {
    if (report.id === COMMERCIAL_REPORT_ID.purchaseAnimal) {
      this.animalAquisitionReportService.openSmartReport();
    }

    if (report.id === COMMERCIAL_REPORT_ID.saleSlaughter) {
      this.yieldSlaughterReportService.openSmartReport();
    }

    if (report.id === COMMERCIAL_REPORT_ID.animalSaleResult) {
      this.animalSaleResultReportService.openSmartReport();
    }

    if (report.id === COMMERCIAL_REPORT_ID.slaughterAnimalPlanning) {
      this.slaughterAnimalPlanningService.openSmartReport();
    }
  }

  setInfo(report: TReportIndex) {
    if (report.id === COMMERCIAL_REPORT_ID.purchaseAnimal) {
      this.animalAquisitionReportService.openInfoReport();
    }
    if (report.id === COMMERCIAL_REPORT_ID.saleSlaughter) {
      this.yieldSlaughterReportService.openInfoReport();
    }
    if (report.id === COMMERCIAL_REPORT_ID.animalSaleResult) {
      this.animalSaleResultReportService.openInfoReport();
    }
    if (report.id === COMMERCIAL_REPORT_ID.slaughterAnimalPlanning) {
      this.slaughterAnimalPlanningService.openInfoReport();
    }
  }

  getReportsCommercial(): TReportsIndex {
    this.commercialReports = [
      {
        id: COMMERCIAL_REPORT_ID.purchaseAnimal,
        title: "Compra de Gado",
        description:
          "Gera os dados das compras realizadas no período informado",
        category: REPORT_CATEGORY.COMMERCIAL,
      },
      {
        id: COMMERCIAL_REPORT_ID.saleSlaughter,
        title: "Venda para abate",
        description: "Gera os dados dos animais nas vendas para abate.",
        category: REPORT_CATEGORY.COMMERCIAL,
      },
      {
        id: COMMERCIAL_REPORT_ID.animalSaleResult,
        title: "Resultado Venda Animal",
        description:
          "Gera os dados de venda dos animais, mostrando os valores custo x venda.",
        category: REPORT_CATEGORY.COMMERCIAL,
      },
      {
        id: COMMERCIAL_REPORT_ID.slaughterAnimalPlanning,
        title: "Planejamento Abate",
        description: "Gera os dados de planejamento de abate.",
        category: REPORT_CATEGORY.COMMERCIAL,
      },
    ];

    return this.commercialReports;
  }
}
