import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";

export type TConstAnimalReport = {
  farmId: number;
  status: string;
};

@Injectable({
  providedIn: "root",
})
export class CostAnimalService
  implements ReportInterface<TConstAnimalReport, Blob>
{
  farmId!: number;
  reportFilters: any;
  title = `Custo por Animal`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {}

  openSmartReport() {
    const descriptionFarm = this.contextService.getCurrentDescriptionFarm();
    this.title = `${descriptionFarm}`;

    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      status: true,
    };

    const data: TDataSmartReport<any> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: false,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Relatório com as informações dos custos dos animais",
      description: ["Custo dos animals geral"],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TConstAnimalReport): Observable<any> {
    let config: any = {
      responseType: "blob",
      timeout: 50000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    };
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/reports/cost/animal`,
      config
    );
  }
}
