import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import * as moment from "moment";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";
import { NutritionallotService } from "../../nutrition/nutritionallot/nutritionallot.service";
import { ETYPE_ORDER } from "src/app/types/TOrder";

export type TNutritionalLotInformationReport = {
  farmId: number;
  startDate: string;
  endDate: string;
  lotId?: number;
};

@Injectable({
  providedIn: "root",
})
export class NutritionalLotInformationService
  implements ReportInterface<TNutritionalLotInformationReport, Blob>
{
  farmId!: number;
  reportFilters: TNutritionalLotInformationReport;
  title!: string;
  filterLotesNutritionals: PecGenericFilterList[] = [];

  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "startDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data trato de",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
    },
    {
      fieldName: "endDate",
      type: GenericFilterTypeEnum.DATE,
      label: "Data trato até",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
    },
    {
      fieldName: "lotId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione o lote",
      fieldValue: "",
      valuesList: this.filterLotesNutritionals,
      required: false,
      hint: "",
    },
  ];

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private dialog: MatDialog,
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet,
    private lotNutritionalService: NutritionallotService
  ) {
    this.title = "Trato Diários Lotes Nutricionais";
    this.getNutritionalLots();
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };

    const data: TDataSmartReport<TNutritionalLotInformationReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  getNutritionalLots() {
    this.filterLotesNutritionals.push({
      fieldLabel: "Todas",
      fieldValue: "0",
    });

    this.lotNutritionalService
      .getAll(
        1,
        100000,
        JSON.stringify({ farmId: this.contextService.getFarm(), status: 1 }),
        JSON.stringify({ field: "areaDescription", type: ETYPE_ORDER.DESC })
      )
      .subscribe((lots) => {
        lots.data.forEach((element: any) => {
          this.filterLotesNutritionals.push({
            fieldLabel: element.areaDescription,
            fieldValue: element.nutritionalLotId,
          });
        });
      });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: "Informações Contidas no relatório",
      description: [],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(
    datas: TNutritionalLotInformationReport,
    seletecReportType: string
  ): Observable<any> {
    if (seletecReportType === SMART_TYPE_REPORT.excel) {
      return this.http.post(
        `${this.authService.getUrlBackEnd()}/api/nutritional/informationslots`,
        {
          farmId: datas.farmId,
          startDate: datas.startDate,
          endDate: datas.endDate,
          nutritionalLotId: datas.lotId,
        },
        {
          headers: PecControlHeaderRequest.getHeader(this.authService.token),
          responseType: "blob",
        }
      );
    }
  }
}
