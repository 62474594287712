import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';

export type TAnimalAgeGroupReport = {
  farmId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AnimalAgeGroupReportService implements ReportInterface<TAnimalAgeGroupReport, Blob> {
  farmId!: number
  reportFilters: TAnimalAgeGroupReport
  title!: string

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
  ) {
    this.title = 'Animais por Faixa Etária'
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
    }

    const data: TDataSmartReport<TAnimalAgeGroupReport> = {
      title: this.title,
      description: 'Selecione as opções para gerar o relatório',
      requiredFilters: false,
      reportFilters: this.reportFilters,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel]
    }
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle: 'Relatório(Impressão) de animais por faixa etária e sexo. Com assinatura',
      description: [
        'Informações Contidas no relatório',
        '- Quantidade de animais totais',
        '-> Faixa Etária',
        '-> Quantidade de Machos',
        '-> Quantidade de Fêmeas',
      ]
    }
    this._bottomSheet.open(BottomSheetComponent, { data })
  }

  getReport(datas: TAnimalAgeGroupReport): Observable<any> {
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    }
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/animalsReports/exportdata/animalAgeGroupHtml`,
      datas,
      config

    );
  }


}
