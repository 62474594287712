import { MatSort, Sort } from "@angular/material/sort";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { AnimalTemporaryBalanceService } from "src/app/services/animal/animal/animal-temporary-balance.service";
import { ContextService } from "src/app/services/context/context.service";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { ConfirmCancelModalComponent } from "src/app/components/shared/confirm-cancel-modal/confirm-cancel-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-animal-temporary-balance-list",
  templateUrl: "./animal-temporary-balance-list.component.html",
  styleUrls: ["./animal-temporary-balance-list.component.scss"],
})
export class AnimalTemporaryBalanceListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  farmId: number;
  dataSource = new MatTableDataSource<any>();
  pageIndex: number = 1;
  pageSize: number = 10;
  loading!: boolean;
  totalRecords!: number;
  columns: string[] = [
    "status",
    "date",
    "animalAgeGroup",
    "maleQuantity",
    "femaleQuantity",
    "operationMovement",
    "farmGrowerId",
    "options"
  ];

  constructor(
    private readonly contextService: ContextService,
    private _liveAnnouncer: LiveAnnouncer,
    private animalTemporaryBalanceService: AnimalTemporaryBalanceService,
    private snackBar: SnackbarComponent,
    private dialog: MatDialog,

  ) {}

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();
    this.getAnimalTemporaryBalance();

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  getAnimalTemporaryBalance() {
    this.loading = true;
    this.animalTemporaryBalanceService
      .getAll(1, 1000, this.farmId)
      .subscribe((e: any) => {

        this.dataSource.data = e.items;
      });
  }

  delete(data: any) {
    this.dialog
      .open(ConfirmCancelModalComponent, {
        width: "500px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: "Excluir Animais Pendentes",
          description: `Confirma a exclusão dos animais (Machos: ${data.maleQuantity}, Fêmeas: ${data.femaleQuantity})
            da faixa etária ${data.animalAgeGroup} mêses de ${data.operationMovement == "C" ? 'Compra' : 'Nascimento'},
            do(a) ${data.farmGrower.description} ?`,
        },
      })
      .afterClosed()
      .subscribe((confirm: any) => {
        if (confirm) {
          this.animalTemporaryBalanceService.delete(data.id).subscribe(
            (res: any) => {
              this.snackBar.snackbarSuccess("Animais pendentes excluídos com sucesso!");
              this.getAnimalTemporaryBalance()
            },
            (err: any) => {
              this.snackBar.snackbarError(
                "Não foi possível excluir a integração"
              );
            }
          );
        }
      });
  }
}
