import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";

@Component({
  selector: "app-pec-upload-file",
  templateUrl: "./pec-upload-file.component.html",
  styleUrls: ["./pec-upload-file.component.scss"],
})
export class PecUploadFileComponent implements OnInit {
  files: File[] = [];
  isDisabled = true;
  title: string = "Click na caixa ou arraste o arquivo";

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  ngOnInit(): void {
    this.title = this.data.message;
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    if (event.addedFiles.length > 0) {
      this.isDisabled = false;
      this.files.push(...event.addedFiles);
    }
  }

  onRemove(eventFile: File) {
    this.isDisabled = true;
    this.files.splice(this.files.indexOf(eventFile), 1);
  }

  confirm(): void {
    this.dialogRef.close(this.files);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
