import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormTypeEnum } from 'src/app/enums/FormTypeEnum';
import { AgeGroupService } from 'src/app/services/ageGroup/age-group.service';
import { AnimalInputMovementService } from 'src/app/services/animalMovement/animal-input-movement.service';
import { ContextService } from 'src/app/services/context/context.service';
import { FarmService } from 'src/app/services/farm/farm/farm.service';

@Component({
  selector: 'app-animal-temporary-balance-age-groups',
  templateUrl: './animal-temporary-balance-age-groups.component.html',
  styleUrls: ['./animal-temporary-balance-age-groups.component.scss']
})
export class AnimalTemporaryBalanceAgeGroupsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() formType: FormTypeEnum;



  constructor(
    
  ) { }

  ngOnInit(): void {
    
        
  }

  

}
