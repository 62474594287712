import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { SERVER_URL } from "src/app/config/env";
import { ConfigIntegration } from "../../pages/config-integration/components/config-integration-forms/config-integration-forms.component";
import { Observable } from "rxjs";
import { GenericFilterResponse } from "src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse";

@Injectable({
  providedIn: "root",
})
export class ConfigIntegrationService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  create(integrationConfig: ConfigIntegration): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/protheusParamsItems/create`,
      { ...integrationConfig },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(
    page: number,
    limit: number,
    filter?: GenericFilterResponse[]
  ): Observable<any> {
    let filterQueryString = "";
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/protheusParamsItems?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getById(integrationConfigId: number): Observable<any> {
    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/protheusParamsItems/${integrationConfigId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  update(integrationConfig: ConfigIntegration): Observable<any> {
    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/protheusParamsItems/`,
      { ...integrationConfig },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  delete(integrationConfigId: number): Observable<any> {
    return this.http.delete(
      `${this.authService.getUrlBackEnd()}/api/protheusParamsItems/delete/${integrationConfigId}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }
}
