import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { FormTypeEnum } from "../../../../../enums/FormTypeEnum";
import { AnimalInterface } from "../../../../../interfaces/animal/AnimalInterface";
import { LotInterface } from "../../../../../interfaces/farm/LotInterface";
import { FarmAreaInterface } from "../../../../../interfaces/farm/FarmArea";
import { CharacteristicInterface } from "../../../../../interfaces/animal/CharacteristicInterface";
import { AnimalService } from "../../../../../services/animal/animal/animal.service";
import { BreedService } from "../../../../../services/animal/breed/breed.service";
import { LotService } from "../../../../../services/farm/lot/lot.service";
import { FarmAreaService } from "../../../../../services/farm/farmArea/farm-area.service";
import { CharacteristicService } from "../../../../../services/animal/characteristic/characteristic.service";
import { BreedInterface } from "../../../../../interfaces/animal/BreedInterface";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "pec-animal-form",
  templateUrl: "./animal-form.component.html",
  styleUrls: ["./animal-form.component.scss"],
})
export class AnimalFormComponent implements OnInit, OnChanges {
  // binds
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("getAnimal") animalOutput: EventEmitter<AnimalInterface> =
    new EventEmitter<AnimalInterface>();
  @Input("animal") animalInput: AnimalInterface;
  @Input() formType: FormTypeEnum;

  weighings: any[] = [];
  columns: string[] = ["weight", "date", "options"];

  // variaveis
  animalFormGroup = new FormGroup({
    animalId: new FormControl(),
    breed: new FormGroup({
      breedId: new FormControl(),
    }),
    farmArea: new FormGroup({
      farmAreaId: new FormControl(),
    }),
    lot: new FormGroup({
      lotId: new FormControl(),
    }),
    farm: new FormGroup({
      farmId: new FormControl(),
    }),
    farmGrower: new FormGroup({
      farmGrowerId: new FormControl(),
    }),
    inputDate: new FormControl(),
    animalCharacteristic: this.formBuider.array([]),
    weight: new FormControl(),
    sex: new FormControl(),
    status: new FormControl("1"),
    birthDate: new FormControl(),
    createdAt: new FormControl(),
    updatedAt: new FormControl(),
  });

  animal: AnimalInterface = {
    farmGrower: null,
    farmArea: null,
    breed: null,
    lot: null,
    weight: null,
    status: null,
    birthDate: null,
    sex: null,
    animalCharacteristic: [],
    weighing: [],
  };

  breeds: BreedInterface[];
  lots: LotInterface[];
  farmAreas: FarmAreaInterface[];
  characteristics: CharacteristicInterface[];

  constructor(
    private animalService: AnimalService,
    private breedService: BreedService,
    private formBuider: FormBuilder,
    private lotService: LotService,
    private farmAreaService: FarmAreaService,
    private characteristicService: CharacteristicService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loadSelectBreed();
    this.loadCharacteristics();
    this.loadFarmAreas();
    this.loadLots();

    // passa o valor informado pelo input para o formulário. usado para os modos de edição e visualização.
    if (this.formType === FormTypeEnum.VIEW) {
      this.animalFormGroup.disable();
      this.setAnimal();
    } else if (
      this.formType === FormTypeEnum.CREATE ||
      this.formType === FormTypeEnum.EDIT
    ) {
      this.animalFormGroup.valueChanges.subscribe((value) => {
        if (this.animalFormGroup.valid) {
          this.isValid.emit(this.animalFormGroup.valid);
          this.animalOutput.emit({
            ...value,
          });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("animalInput")) {
      this.animal = changes.animalInput.currentValue;
      this.setAnimal();
      if (this.formType === FormTypeEnum.VIEW) {
        this.animalFormGroup.disable();
      }
    }
  }

  public get animalCharacteristic() {
    return this.animalFormGroup.get("animalCharacteristic") as FormArray;
  }

  delete_weight(weight: any) {
    this.animalService.deleteWeight(weight.weighingId).subscribe((value) => {
      this.router.navigate(["../../"], { relativeTo: this.route });
    });
  }

  addCharacteristic() {
    const characteristics = this.animalCharacteristic;
    characteristics.push(
      new FormGroup({
        animalCharacteristicId: new FormControl(),
        characteristicId: new FormControl(),
        animalId: new FormControl(),
        value: new FormControl(),
      })
    );
  }

  setAnimal() {
    if (this.animal) {
      if ("animalId" in this.animal) {
        this.animalFormGroup.controls.animalId.setValue(this.animal.animalId);
      }
      if ("sex" in this.animal) {
        this.animalFormGroup.controls.sex.setValue(String(this.animal.sex));
      }
      if ("weighing" in this.animal) {
        this.weighings = this.animal.weighing;
        this.animalFormGroup.controls.weight.setValue(
          this.findMostRecentWeight(this.animal.weighing)
        );
      }
      if ("birthDate" in this.animal) {
        this.animalFormGroup.controls.birthDate.setValue(this.animal.birthDate);
      }
      if ("status" in this.animal) {
        this.animalFormGroup.controls.status.setValue(
          String(this.animal.status)
        );
      }
      if ("farmArea" in this.animal) {
        this.animalFormGroup
          .get("farmArea")
          .get("farmAreaId")
          .setValue(this.animal.farmArea.farmAreaId);
      }
      if ("lot" in this.animal) {
        // @ts-ignore
        this.animalFormGroup.controls.lot.controls.lotId.setValue(
          this.animal.lot.lotId
        );
      }
      if ("breed" in this.animal) {
        // @ts-ignore
        this.animalFormGroup.controls.breed.controls.breedId.setValue(
          this.animal.breed.breedId
        );
      }

      if (Array.isArray(this.animal.animalCharacteristic)) {
        for (const characteristic of this.animal.animalCharacteristic) {
          const characteristics = this.animalCharacteristic;
          characteristics.push(
            new FormGroup({
              animalCharacteristicId: new FormControl(
                characteristic.animalCharacteristicId
              ),
              characteristicId: new FormControl(
                characteristic.characteristicId
              ),
              animalId: new FormControl(characteristic.animalId),
              value: new FormControl(characteristic.value),
            })
          );
        }
      }
    }
  }

  loadSelectBreed() {
    this.breedService.getAll(1, 1000).subscribe((value) => {
      this.breeds = value.items;
    });
  }

  loadFarmAreas() {
    this.farmAreaService.getAll(1, 1000).subscribe((value) => {
      this.farmAreas = value.items;
    });
  }

  loadLots() {
    this.lotService.getAll(1, 1000).subscribe((value) => {
      this.lots = value.items;
    });
  }

  loadCharacteristics() {
    this.characteristicService.getAll(1, 1000).subscribe((value) => {
      this.characteristics = value.items;
    });
  }

  findMostRecentWeight(operations: any[]): number | null {
    if (operations.length === 0) {
      return null;
    }

    let mostRecentWeight: number | null = null;
    let mostRecentDate: Date | null = null;

    operations.forEach(
      (operation: { date: string | number | Date; weight: number }) => {
        const currentDate = new Date(operation.date);
        if (!mostRecentDate || currentDate > mostRecentDate) {
          mostRecentDate = currentDate;
          mostRecentWeight = operation.weight;
        }
      }
    );

    return mostRecentWeight;
  }

  /**
   * @description deleta o item relacionado a caracteristica no formulário
   */
  deleteCharacteristic(item: any) {
    this.animalCharacteristic.removeAt(item);
  }
}
