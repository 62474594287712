<mat-grid-list rowHeight="100" cols="3" [formGroup]="formGroup">
  <mat-grid-tile colspan="1">
    <mat-form-field>
      <mat-label>Característica</mat-label>
      <mat-select (valueChange)="changeCharacteristic($event)" formControlName="characteristicId">
        <mat-option *ngFor="let characteristic of characteristics"
                    [value]="characteristic.characteristicId">{{characteristic.description}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>
  <div
    *ngIf="characteristic?.type === 4 ; then characteristicEnum else characteristicField"></div>
  <ng-template #characteristicField>
    <mat-grid-tile colspan="2">
      <mat-form-field>
        <mat-label>Valor</mat-label>
        <input matInput formControlName="value">
      </mat-form-field>
    </mat-grid-tile>
  </ng-template>
  <ng-template #characteristicEnum>
    <mat-grid-tile colspan="2">
      <mat-form-field>
        <mat-label>Valor</mat-label>
        <mat-select formControlName="value">
          <mat-option *ngFor="let enum of characteristic.characteristicEnum"
                      [value]="enum.value">{{enum.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
  </ng-template>
</mat-grid-list>


