import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar.component';
import { PaginationService } from 'src/app/directives/Services-for-directives/Pagination.service';
import { ContextService } from 'src/app/services/context/context.service';
import { PastureManegementService } from 'src/app/services/pastureManegement/pasture-manegement.service';

@Component({
  selector: 'app-pasture-manegement-list',
  templateUrl: './pasture-manegement-list.component.html',
  styleUrls: ['./pasture-manegement-list.component.scss']
})
export class PastureManegementListComponent implements OnInit {

  farmId: number;
  dataSource = new MatTableDataSource<any>();
  pageIndex: number = 1;
  pageSize: number = 10;
  loading!: boolean;
  totalRecords!: number;
  columns: string[] = ['area', 'size', 'startDate', 'animalQUantity', 'averageWeightInKg', 'estimatedWeight', 'animalUnit', 'animalUnitPerHa']


  constructor(
    private readonly contextService: ContextService,
    private snackBar: SnackbarComponent,
    private paginationService: PaginationService,
    private pastureManegementService: PastureManegementService

  ) { }

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();

    this.getAreaUaInfos()

  }
  
  getAreaUaInfos() {
    this.loading = true;
    this.pastureManegementService.areaUaInfo().subscribe((result: any) => {
      
      this.dataSource.data = result.items;

    })
  }



}
