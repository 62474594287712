import {Component, OnInit} from '@angular/core';
import {FormTypeEnum} from '../../../../enums/FormTypeEnum';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {AnimalInterface} from '../../../../interfaces/animal/AnimalInterface';
import {AnimalService} from '../../../../services/animal/animal/animal.service';

@Component({
  selector: 'pec-animal-view',
  templateUrl: './animal-view.component.html',
  styleUrls: ['./animal-view.component.scss']
})
export class AnimalViewComponent implements OnInit {

  formType: FormTypeEnum = FormTypeEnum.VIEW;

  animal: AnimalInterface;

  constructor(
    private route: ActivatedRoute,
    private animalService: AnimalService
  ) {
  }

  ngOnInit() {
    const animal = this.route.paramMap.pipe(
      switchMap(params => {
        const id = params.get('id');
        return this.animalService.getById(id);
      })
    );

    animal.subscribe(value => {
      this.animal = value.items;
    });
  }

}
