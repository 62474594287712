import {Component, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PecLookupInterface} from './interfaces/pec-lookup';
import {MatDialog} from '@angular/material/dialog';
import {LookupDialogComponent} from './components/lookup-dialog/lookup-dialog.component';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {PecLookupDataInterface} from './interfaces/pec-lookup-data';

@Component({
    selector: 'pec-lookup',
    templateUrl: './pec-lookup.component.html',
    styleUrls: ['./pec-lookup.component.scss']
})
export class PecLookupComponent implements OnInit, OnChanges {

    @Input() label: string;
    @Input() lookup: PecLookupInterface;
    // caso seja multiplo, permite a seleção de mais de um valor no modal
    @Input() lookupFormControl: FormControl;
    // permiti iniciar o lookup com um valor selecionado
    @Input() currentValues: PecLookupDataInterface[];
    @Input() disabled = false;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    lookupResponse: PecLookupDataInterface[] = [];
    selectable = true;
    removable = true;

    constructor(
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('currentValues' in changes) {
            if (changes.currentValues.currentValue.length > 0) {
                this.lookupResponse = changes.currentValues.currentValue;
            }
        }
    }

    openDialog(): void {
        if (this.disabled) {
            return
        }
        const dialogRef = this.dialog.open(LookupDialogComponent, {
            width: '60vw',
            height: '70vh',
            data: this.lookup
        });

        dialogRef.afterClosed().subscribe(data => {
            this.lookupResponse = [];
            if (this.lookup.multiple) {
                let value = '';
                for (const d of data) {
                    this.lookupResponse.push({
                        value: d[this.lookup.selectedFieldValue],
                        label: d[this.lookup.selectedFieldLabel]
                    });
                    value += (d[this.lookup.selectedFieldValue] + `,`);
                }
                if (value.length > 0) {
                    this.lookupFormControl.setValue(value);
                }
            } else {
                if (data) {
                    if (data.length > 0) {
                        this.lookupResponse.push({
                            value: data[0][this.lookup.selectedFieldValue],
                            label: data[0][this.lookup.selectedFieldLabel]
                        });
                        this.lookupFormControl.setValue(data[0][this.lookup.selectedFieldValue]);
                    }
                }
            }
        });

    }

    remove(data: PecLookupDataInterface): void {
        const index = this.lookupResponse.indexOf(data);

        if (index >= 0) {
            this.lookupResponse.splice(index, 1);
        }
    }

    onKeyDown(value: any) {
    }

}
