import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { ConfirmCancelModalComponent } from "src/app/components/shared/confirm-cancel-modal/confirm-cancel-modal.component";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { ParamsInterface } from "src/app/interfaces/params/ParamsInterface";
import { FarmIntegrationService } from "src/app/services/farm/farmIntegration/farm-integration.service";
import { PaginationService } from "../../../../directives/Services-for-directives/Pagination.service";

@Component({
  selector: "app-integration-list",
  templateUrl: "./integration-list.component.html",
  styleUrls: ["./integration-list.component.scss"],
})
export class IntegrationListComponent implements OnInit {
  loading!: boolean;
  totalRecords = 0;
  status = false;

  columns: string[] = [
    "status",
    "classeinicial",
    "classefinal",
    "mes",
    "excecaocldebito",
    "excecaoclcredito",
    "options",
  ];
  dataSource = new MatTableDataSource<ParamsInterface>();

  constructor(
    private integrationService: FarmIntegrationService,
    private snackBar: SnackbarComponent,
    private dialog: MatDialog,
    private paginationService: PaginationService
  ) {}

  ngOnInit() {
    let pageSize = this.paginationService.getPageSize();
    pageSize > 0 ? this.getPages(1, pageSize) : this.getPages(1, 10);
  }

  onPageChange(e: any) {
    this.getPages(e.pageIndex + 1, e.pageSize);
  }

  getPages(index: number, limit: number) {
    this.loading = true;
    this.integrationService.getAll(index, limit).subscribe((value) => {
      this.loading = false
      this.dataSource.data = value.items;
      this.totalRecords = value.pagination.totalRecords;
    }, error => {
      this.loading = false;
    });
  }

  delete(integration: ParamsInterface) {
    this.dialog
      .open(ConfirmCancelModalComponent, {
        width: "250px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: "Excluir Integração",
          description: "Confirma exclusão da integração?",
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.integrationService.delete(integration.id).subscribe(
            (res) => {
              this.snackBar.snackbarSuccess("Integração excluída com sucesso!");
              this.getPages(1, 10);
            },
            (err) => {
              this.snackBar.snackbarError(
                "Não foi possível excluir a integração"
              );
            }
          );
        }
      });
  }

  carryCosts(integration: ParamsInterface) {
    this.dialog
      .open(ConfirmCancelModalComponent, {
        width: "250px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: "Custos Fixos",
          description: "Confirma em buscar custos?",
        },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          integration.status === "1" &&
            Object.defineProperty(integration, "status", {
              value: "2",
              writable: false,
            });
            this.loading = true
            integration.loading = true;
            this.integrationService.closeMonth(integration).subscribe(
              (res) => {
              integration.loading = false;
              this.snackBar.snackbarSuccess("Custos carregados com sucesso!");
            },
            (e) => {
              integration.loading = false;
              this.snackBar.snackbarError(
                "Ocorreu um erro ao buscar os custos!"
              );
            }
          );
        }
      });
  }

  generateExcel(id: number) {
    this.integrationService.generateIntegrationExcel(id).subscribe(
      (value) => {
        const url = window.URL.createObjectURL(value);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display:none");
        a.href = url;
        a.download = `Relatório Integração ID:${id} ${moment().format(
          "DD/MM/YYYY HH:MM"
        )}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (err) => {
        this.snackBar.snackbarError("Erro ao gerar relatório");
      }
    );
  }
}
