import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BottomSheetComponent, HtmlData } from 'src/app/components/shared/bottom-sheet/bottom-sheet.component';
import { CONFIG_SMART_REPORT, SMART_TYPE_REPORT, SmartReportModalComponent, TDataSmartReport } from 'src/app/components/shared/smart-report-modal/smart-report-modal.component';
import { GenericFilterTypeEnum } from 'src/app/enums/GenericFilterTypeEnum';
import { ReportInterface } from 'src/app/interfaces/report/report-interface';
import { PecGenericFilterFields } from 'src/app/interfaces/utils/PecGenericFilterFields';
import { AuthService } from '../../auth/auth.service';
import { ContextService } from '../../context/context.service';
import { PecGenericFilterList } from 'src/app/interfaces/utils/PecGenericFilterList';
import { ProductionphaseService } from '../../nutrition/productionphase/productionphase.service';
import * as moment from 'moment';

export type TNutritionalLotActiveReport = {
    farmId: number;
    date: string;
    productionPhaseId: number;
}

@Injectable({
    providedIn: 'root'
})
export class NutritionalLotActiveReportService implements ReportInterface<TNutritionalLotActiveReport, Blob> {
    farmId!: number
    reportFilters: TNutritionalLotActiveReport
    title!: string
    filterProductionPhase: PecGenericFilterList[] = []
    reportFilterFields: PecGenericFilterFields[] = [        
        {
            fieldName: "productionPhaseId",
            type: GenericFilterTypeEnum.SELECT,
            label: "Selecione a fase produtiva",
            fieldValue: "",
            valuesList: this.filterProductionPhase,
            required: true,
            hint: 'Campo obrigatório',
        },
    ];

    constructor(
        private http: HttpClient,
        private contextService: ContextService,
        private dialog: MatDialog,
        private authService: AuthService,
        private _bottomSheet: MatBottomSheet,
        private productionPhaseService: ProductionphaseService
    ) {
        this.title = 'Lotes Nutricionais'
        this.getProductionPhase()
    }

    getProductionPhase() {
        this.productionPhaseService.getAll(1, 100).subscribe(productionPhase => {
            this.filterProductionPhase.push({
                fieldLabel: 'Todas',
                fieldValue: '0',
            })
            productionPhase.items.forEach((element: any) => {
                this.filterProductionPhase.push({
                    fieldLabel: element.description,
                    fieldValue: element.productionPhaseId,
                })
            })
        })
    }

    openSmartReport() {
        this.farmId = this.contextService.getFarm();
        this.reportFilters = {
            productionPhaseId: null,
            date: moment().format('YYYY-MM-DD'),
            farmId: this.farmId,
        }

        const data: TDataSmartReport<TNutritionalLotActiveReport> = {
            title: 'Lotes Nutricionais',
            description: 'Selecione as opções para gerar o relatório',
            requiredFilters: true,
            reportFilterFields: this.reportFilterFields,
            reportFilters: this.reportFilters,
            service: this,
            reportTypes: [SMART_TYPE_REPORT.pdf]
        }
        this.dialog.open(SmartReportModalComponent, {
            width: CONFIG_SMART_REPORT.width,
            height: CONFIG_SMART_REPORT.height,
            data,
        });
    }

    openInfoReport() {
        const data: HtmlData = {
            title: 'Lotes Nutricionaos',
            subtitle: 'Informações Contidas no relatório',
            description: [
                '-> Descrição do Lote',
                '-> Quantidade de animais',
                '-> Data de Entrada',
                '-> Quantidade de dias',
                '-> Peso Entrada',
                '-> Peso Estimado',
                '-> Dieta Ativa',
            ]
        }
        this._bottomSheet.open(BottomSheetComponent, { data })
    }

    getReport(datas: TNutritionalLotActiveReport, seletecReportType: string): Observable<Blob> {        
        if (seletecReportType === SMART_TYPE_REPORT.pdf) {
            return this.http.post(
                `${this.authService.getUrlBackEnd()}/api/report/activeLots/activeNutritionalLotReport`,
                datas,
                { responseType: "blob" }
            );
        }
        if (seletecReportType === SMART_TYPE_REPORT.excel) {
            return this.http.post(
                `${this.authService.getUrlBackEnd()}/api/report/nutrition/infoDaily`,
                { farmId: datas.farmId, date: datas.date },
                { responseType: "blob" }
              );
        }
    }
}
