<div class="container scroll">
  <section class="flex center">
    <span class="spacer-left"></span>
    <h3>Animais Pendentes de Entrada</h3>
    <span class="spacer-right"></span>

    <button mat-raised-button color="primary" routerLink="./create">
      <mat-icon>add</mat-icon>
      Adicionar Operação
    </button>
  </section>

  <div class="container mat-elevation-z3">
    <app-animal-temporary-balance-list></app-animal-temporary-balance-list>
  </div>
</div>
