import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginationItemsPerPageDirective } from "./PaginationItemsPerPageDirective.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [PaginationItemsPerPageDirective],
  exports: [PaginationItemsPerPageDirective],
})
export class DirectivesModule {}
