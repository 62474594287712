import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductionValuesIndexComponent } from './production-values-index/production-values-index.component';

const routes: Routes = [
  {
    path: '',
    component: ProductionValuesIndexComponent
  },
  // {
  //   path: 'view/:id',
  //   component: CommercialSellingViewComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionValuesRoutingModule { }
