<section class="flex center">
  <button mat-raised-button color="primary" (click)="close()">
    Fechar
  </button>
  <span class="spacer-left"></span>
  <h3 *ngIf="animal">Fazenda Animal - {{ animal.farm?.description }}</h3>
  <span class="spacer-right"></span>
</section>
<mat-divider></mat-divider>
<div class="container scroll" style="padding-top: 10px; padding-left: 15px; background-color: #eeeeee;">
  <div class="grid">
    <!--Gráfico Peso Animal-->
    <mat-card >
      <div *ngIf="loadingChart">
        <pec-list-loading text="Carregando histórico de peso"></pec-list-loading>
      </div>
      <div *ngIf="!loadingChart">
        <p class="info-title">Histórico de Peso do Animal</p>
        <mat-card-content >
          <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [plugins]="barChartPlugins" [colors]="barChartColors"
            [chartType]="barChartType"></canvas>
        </mat-card-content>
      </div>
    </mat-card>

    <!--Dados do Animal-->
    <mat-card >
      <p class="info-title">Animal</p>
      <mat-card-content >
        <div class="aligned" *ngIf="animal">
          <h2 class="set" *ngIf="animal?.sex == 'M'; else female">
            Sexo: <span class="set">Macho</span>
          </h2>
          <h2 class="set">
            Data de nascimento:
            <span class="set">{{
              animal?.birthDate | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set" *ngIf="animal?.status == 1; else inative">
            Status: <span class="set">Ativo</span>
          </h2>
          <h2 class="set">
            Raça: <span class="set">{{ animal?.breed?.description }}</span>
          </h2>
          <h2 class="set">
            Data de Entrada:
            <span class="set">{{
              animal?.inputDate | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            Idade:
            <span class="set">{{ months }} meses</span>
          </h2>
        </div>
      </mat-card-content>
    </mat-card>

    <ng-template #inative>
      <h2 class="set">Status: <span class="set">Inativo</span></h2>
    </ng-template>
    <ng-template #female>
      <h2 class="set">Sexo: <span class="set">Fêmea</span></h2>
    </ng-template>

    <!--Dados Peso Animal-->
    <mat-card >
      <p class="info-title">Sanitário</p>
      <mat-card-content >
        <div class="aligned">
          <h2 class="set">
            Protocolo:
            <span class="set">
              {{
              animal?.animalCurrentData.healthProtocol?.description
              }}</span>
          </h2>
          <h2 class="set">
            Data Aplicação:
            <span class="set">
              {{
              animal?.animalCurrentData?.healthProtocolDate
              | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            Data Carência:
            <span class="set">
              {{
              animal?.animalCurrentData?.quarantineDate
              | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            Medicamentos Último
            <span class="set"> {{ currentProtocolMedications }}</span>
          </h2>
        </div>
      </mat-card-content>
    </mat-card>

    <!--Dados Gerais do Animal-->
    <mat-card >
      <p class="info-title">Gerais</p>
      <mat-card-content >
        <div class="aligned">
          <h2 class="set">
            IDE:
            <span class="set"> {{ animal?.IDE?.ideId }}</span>
          </h2>
          <h2 class="set">
            Data do Lote:
            <span class="set">
              {{
              animal?.animalCurrentData?.lotDate | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            Área:
            <span class="set"> {{ areaDescriptionText }}</span>
          </h2>
          <h2 class="set" *ngIf="animal?.animalCurrentData?.picketId != null">
            Piquete:
            <span class="set">
              {{ animal?.animalCurrentData?.picketId }}</span>
          </h2>
          <h2 class="set">
            Frame:<span class="set"> {{ characteristics.frame }} </span>
          </h2>
          <h2 class="set">
            Umbigo:<span class="set"> {{ characteristics.umbigo }} </span>
          </h2>
          <h2 class="set" *ngIf="characteristics.altura">
            Altura(CM):<span class="set"> {{ characteristics.altura }} </span>
          </h2>
        </div>
      </mat-card-content>
    </mat-card>

    <!--Dados Rastreabilidade-->
    <mat-card >
      <p class="info-title">Rastreabilidade</p>
      <mat-card-content >
        <div class="aligned">
          <h2 class="set">
            Data de Inclusão:
            <span class="set">{{
              animal?.traceability?.inclusionDate | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            Data de liberação abate:
            <span class="set">
              {{
              animal?.traceability?.slaughterReleaseDate
              | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            SISBOV:
            <span class="set">
              {{ animal?.traceability?.sisbovNumber }} -
              {{ animal?.traceability?.manejo }}</span>
          </h2>
          <h2 class="set">
            Limite Cota Hilton:
            <span class="set">
              {{
              animal?.traceability?.cotaHiltonDate | date : "dd/MM/yyyy"
              }}</span>
          </h2>
          <h2 class="set">
            Data Identificação:
            <span class="set">
              {{
              animal?.traceability?.identificationDate | date : "dd/MM/yyyy"
              }}</span>
          </h2>
        </div>
      </mat-card-content>
    </mat-card>

    <!--Medicamentos-->
    <!-- <mat-card>
      <p class="info-title">Medicamentos</p>
      <mat-card-content >
        <div class="aligned">
          <h2 class="set">
            Medicamentos Último Protocolo:
            <span class="set"> {{ currentProtocolMedications }}</span>
          </h2>
        </div>
      </mat-card-content>
    </mat-card> -->

    <!--Custo do Animal-->
    <mat-card>
      <p class="info-title">Custo</p>
      <mat-card-content >
        <div class="aligned">
          <h2 class="set">
            Nutricional:
            <span class="set" >{{ animalCost?.nutritionalCost | currency: 'BRL':true }}</span>
          </h2>
          <h2 class="set">
            Aquisição:
            <span class="set">{{ animalCost?.inputOpCost | currency: 'BRL':true}}</span>
          </h2>
          <h2 class="set" *ngIf="animalCost?.internalOpCost">
            Mov. Interna:
            <span class="set">{{ animalCost?.internalOpCost | currency: 'BRL':true }}</span>
          </h2>
          <h2 class="set" *ngIf="animalCost?.outputOpCost">
            Venda: <span class="set">{{ animalCost?.outputOpCost | currency: 'BRL':true }}</span>
          </h2>
          <h2 class="set" *ngIf="animalCost?.healthProtocolCost">
            Sanitário:
            <span class="set">{{ animalCost?.healthProtocolCost | currency: 'BRL':true }}</span>
          </h2>
          <h2 class="set" *ngIf="animalCost?.otherCost">
            Integração ERP:
            <span class="set">{{ animalCost?.otherCost | currency: 'BRL':true }}</span>
          </h2>
          <h2 class="set">
            TOTAL:
            <span class="set">{{ animalCost?.sumAll | currency: 'BRL':true }}</span>
          </h2>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
