import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductionValueService } from 'src/app/services/commercial/production-value.service';
import { ContextService } from 'src/app/services/context/context.service';

@Component({
  selector: 'app-production-values-contribuition-margin',
  templateUrl: './production-values-contribuition-margin.component.html',
  styleUrls: ['./production-values-contribuition-margin.component.scss']
})
export class ProductionValuesContribuitionMarginComponent implements OnInit, OnChanges {

  @Input() params: any;

  @Input() initialDate: any;
  @Input() finalDate: any;

  @Input() positionAnimalTotalWeightDifference: number;
  @Input() atualCattle: number;
  @Input() sellWeightInArroba: number;
  @Input() revenueSelling: number;
  @Input() buyWeightInArroba: number;

  sellWeight: number;
  buyWeight: number;
  revenue: number;

  formParams: any;

  farmId: number;

  mc2: number;
  mc3: number;
  production: number;
  area: number;
  arrobaCost: number;


  dsObj  = {
    variableCost: {value1 : 0, value2: 0},
    aquisitionCost : {value1 : 0, value2: 0},
    revenueSelling : {value1 : 0, value2: 0},
    revenueStock : {value1 : 0, value2: 0},
    MC1 : {value1 : 0, value2: 0},
    fixedCost : {value1 : 0, value2: 0},
    MC2 : {value1 : 0, value2: 0},
    financialExpense : {value1 : 0, value2: 0},
    MC3 : {value1 : 0, value2: 0},
  }

  displayedProcessDatas: string[] = [
    'variableCost' ,'aquisitionCost', 'revenueSelling', 'revenueStock', 'MC1', 'fixedCost', 'MC2', 'financialExpense', 'MC3'
  ];
  displayedArrobaCost: string[] = [
    'variableCostArroba' ,'totalCostArroba', 'totalCostAndFinancialArroba'
  ];

  dataSource = new MatTableDataSource<any>();
  dataSourceCosts = new MatTableDataSource<any>();


  constructor(
    private productionValueService:ProductionValueService,
    public contextService: ContextService,

  ) { }

  ngOnInit(): void {
    this.farmId = this.contextService.getFarm();

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.sellWeight = changes.sellWeightInArroba?.currentValue;
    this.buyWeight = changes.buyWeightInArroba?.currentValue;
    this.revenue = changes.revenueSelling?.currentValue;
    this.formParams = changes.params?.currentValue;


    this.dsObj.variableCost.value1 = this.formParams.variableCost;
    this.dsObj.variableCost.value2 = +this.formParams.variableCost / (+this.positionAnimalTotalWeightDifference + +this.sellWeight - +this.buyWeight);

    this.dsObj.aquisitionCost.value1 = +this.formParams.aquisitionCost;
    this.dsObj.aquisitionCost.value2 = +this.formParams.aquisitionCost/+this.buyWeight;

    this.dsObj.revenueSelling.value1 = +this.formParams.sellRevenue;
    this.dsObj.revenueSelling.value2 = +this.formParams.sellRevenue/+this.sellWeight;

    this.dsObj.revenueStock.value1 = +this.positionAnimalTotalWeightDifference *this.formParams?.arrobaCostStock;
    this.dsObj.revenueStock.value2 = this.formParams?.arrobaCostStock;

    this.dsObj.MC1.value1 = (+this.positionAnimalTotalWeightDifference  * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue - +this.formParams.aquisitionCost - +this.formParams.variableCost;
    this.dsObj.MC1.value2 = (((+this.positionAnimalTotalWeightDifference  * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue - +this.formParams.aquisitionCost - +this.formParams.variableCost)/((+this.positionAnimalTotalWeightDifference  * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue))*100;

    this.dsObj.fixedCost.value1 = this.formParams.fixedCost;
    this.dsObj.fixedCost.value2 = null;

    this.dsObj.MC2.value1 = ((+this.positionAnimalTotalWeightDifference  * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue - +this.formParams.aquisitionCost - +this.formParams.variableCost) - +this.formParams.fixedCost;
    this.dsObj.MC2.value2 = ((((+this.positionAnimalTotalWeightDifference  * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue - +this.formParams.aquisitionCost - +this.formParams.variableCost) - +this.formParams.fixedCost)/((+this.positionAnimalTotalWeightDifference * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue))*100;

    this.dsObj.financialExpense.value1 = (+this.formParams.variableCost+ +this.formParams.aquisitionCost)*(+this.formParams?.financialExpense/100);
    this.dsObj.financialExpense.value2 = this.formParams?.financialExpense;

    this.dsObj.MC3.value1 = (((+this.positionAnimalTotalWeightDifference * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue - +this.formParams.aquisitionCost -+this.formParams.variableCost) - +this.formParams.fixedCost) - (+this.formParams.variableCost+ +this.formParams.aquisitionCost)*(+this.formParams?.financialExpense/100);
    this.dsObj.MC3.value2 = (((((+this.positionAnimalTotalWeightDifference * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue - +this.formParams.aquisitionCost - +this.formParams.variableCost) - +this.formParams.fixedCost) - (+this.formParams.variableCost+ +this.formParams.aquisitionCost)*(+this.formParams?.financialExpense/100))/((+this.positionAnimalTotalWeightDifference * +this.formParams?.arrobaCostStock) + +this.formParams.sellRevenue))*100;


    this.dataSource.data = [{
      variableCost: this.dsObj.variableCost.value1,
      aquisitionCost: this.dsObj.aquisitionCost.value1,
      revenueSelling: this.dsObj.revenueSelling.value1,
      revenueStock: this.dsObj.revenueStock.value1,
      MC1: this.dsObj.MC1.value1,
      fixedCost: this.dsObj.fixedCost.value1,
      MC2: this.dsObj.MC2.value1,
      financialExpense: this.dsObj.financialExpense.value1,
      MC3: this.dsObj.MC3.value1,
    }, {
      variableCost: this.dsObj.variableCost.value2,
      aquisitionCost: this.dsObj.aquisitionCost.value2,
      revenueSelling: this.dsObj.revenueSelling.value2,
      revenueStock: this.dsObj.revenueStock.value2,
      MC1: this.dsObj.MC1.value2,
      fixedCost: this.dsObj.fixedCost.value2,
      MC2: this.dsObj.MC2.value2,
      financialExpense: this.dsObj.financialExpense.value2,
      MC3: this.dsObj.MC3.value2,
    }];

    this.dataSourceCosts.data = [{
      variableCostArroba: (+this.dsObj.variableCost.value1 + +this.dsObj.aquisitionCost.value1)/(+this.sellWeight + +this.positionAnimalTotalWeightDifference),
      totalCostArroba: (+this.dsObj.variableCost.value1 + +this.dsObj.aquisitionCost.value1 + +this.dsObj.fixedCost.value1)/(+this.sellWeight ),
      totalCostAndFinancialArroba: (+this.dsObj.variableCost.value1 + +this.dsObj.aquisitionCost.value1 + +this.dsObj.fixedCost.value1 + +this.dsObj.financialExpense.value1)/(+this.sellWeight),
    }];

    this.mc3 = this.dsObj.MC3.value1;
    this.mc2 = this.dsObj.MC2.value1;
    this.area = this.formParams.productionArea;
    this.production = +this.positionAnimalTotalWeightDifference + +this.sellWeight - +this.buyWeight;
    this.arrobaCost = this.formParams.arrobaCostStock;

  }



}
