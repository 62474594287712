import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericFilterResponse } from 'src/app/components/shared/pec-common-filter/interaces/GenericFilterResponse';
import PecControlHeaderRequest from 'src/app/utils/PecControlHeaderRequest';
import { AuthService } from '../auth/auth.service';
import { ContextService } from '../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class SlaughterInputParamsService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
  ) { }

  farmId = this.contextService.getFarm();

  create(slaughter: any): Observable<any> {
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/commercial/slaughterInputParams/create`,
      { ...slaughter },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getAll(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {
      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }

    return this.http.get(
      `${this.authService.getUrlBackEnd()}/api/commercial/slaughterInputParams?page=${page}&limit=${limit}${filterQueryString}`,
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  getBySlaughterId(id: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/commercial/slaughterInputParams/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }

  updateOrCreate(slaughter: any): Observable<any> {

    return this.http.put(
      `${this.authService.getUrlBackEnd()}/api/commercial/slaughterInputParams`,
      { ...slaughter },
      {
        headers: PecControlHeaderRequest.getHeader(this.authService.token),
      }
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/commercial/slaughterInputParams/delete/${id}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    });
  }
}
