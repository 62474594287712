import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnimalTemporaryBalanceIndexComponent } from './animal-temporary-balance-index/animal-temporary-balance-index.component';
import { AnimalTemporaryBalanceCreateComponent } from './components/animal-temporary-balance-create/animal-temporary-balance-create.component';
import { AnimalTemporaryBalanceEditComponent } from './components/animal-temporary-balance-edit/animal-temporary-balance-edit.component';

const routes: Routes = [
  {
    path: '',
    component: AnimalTemporaryBalanceIndexComponent
  },
  {
    path: 'create',
    component: AnimalTemporaryBalanceCreateComponent
  },
  {
    path: 'edit/:id',
    component: AnimalTemporaryBalanceEditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnimalTemporaryBalanceRoutingModule { }
