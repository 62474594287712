import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";
import {
  HtmlData,
  BottomSheetComponent,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  TDataSmartReport,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  CONFIG_SMART_REPORT,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { environment } from "src/environments/environment";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";

export type TAnimalBirthReport = {
  seasonId: string;
};

@Injectable({
  providedIn: "root",
})
export class AniBirthReportService
  implements ReportInterface<TAnimalBirthReport, any>
{
  farmId!: number;
  reportFilters: TAnimalBirthReport;
  filterBirth: PecGenericFilterList[] = [
    {
      fieldLabel: "-Selecione a Estação de Monta-",
      fieldValue: "0",
    },
  ];

  reportFilterFields: any[] = [
    {
      fieldName: "seasonId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione a estação",
      fieldValue: "",
      required: true,
      hint: "Campo obrigatório",
      valuesList: this.filterBirth,
    },
  ];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.getSeasons();
  }

  openSmartReport() {
    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      seasonId: "",
    };
    const descriptionFarm = this.contextService.getCurrentDescriptionFarm();

    const data: TDataSmartReport<TAnimalBirthReport> = {
      title: `Nascimentos`,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  getSeasons() {
    console.log(this.contextService.getFarm());

    return this.http
      .get(
        `${
          environment.serverCriaUrlBackend
        }/season/getAllSeason?farmId=${this.contextService.getFarm()}`,
        {
          headers: PecControlHeaderRequest.getHeader(this.authService.token),
        }
      )
      .subscribe((seasons: any) => {
        if (seasons.isError === false) {
          seasons.data.forEach((element: any) => {
            console.log(element);

            this.reportFilterFields[0].valuesList.push({
              fieldLabel: element.seasonDescription,
              fieldValue: String(element.seasonId),
            });
          });
        }
      });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: "Nascimentos",
      subtitle: "Informações Contidas no relatório",
      description: [
        "-> Animal",
        "-> Data de Nascimento",
        "-> Área Nascimento",
        "-> Identificações",
        "-> Mãe",
        "-> Raça",
        "-> Reprodutor",
        "-> Peso",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(data: TAnimalBirthReport): any {
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    };
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/reports/cria/birth`,
      {
        seasonId: data.seasonId,
      },
      config
    );
  }
}
