import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommercialSellingIndexComponent } from './commercial-selling-index/commercial-selling-index.component';
import { CommercialSellingViewComponent } from './components/commercial-selling-view/commercial-selling-view.component';

const routes: Routes = [
  {
    path: '',
    component: CommercialSellingIndexComponent
  },
  {
    path: 'view/:id',
    component: CommercialSellingViewComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommercielSellingRoutingModule { }
