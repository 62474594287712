<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button [routerLink]="'../../'" style="margin-right: 60px">
      <mat-icon>keyboard_backspace</mat-icon>
      Voltar
    </button>
    <span class="toolbar-spacer"></span>
    <span>Animal - {{ animal?.traceability.sisbovNumber }}</span>
    <span class="toolbar-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
<div class="container">
  <button mat-raised-button color="primary" (click)="saveAnimal()">
    <mat-icon>check</mat-icon>
    Salvar
  </button>
</div>
<div class="container">
  <pec-animal-form (getAnimal)="setAnimal($event)" (isValid)="setFormValid($event)" [animal]="animal" [formType]="formType"></pec-animal-form>
</div>
