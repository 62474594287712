import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { SnackbarComponent } from "src/app/components/shared/snackbar/snackbar.component";
import { FormTypeEnum } from "src/app/enums/FormTypeEnum";
import { AnimalInterface } from "src/app/interfaces/animal/AnimalInterface";
import { AnimalService } from "src/app/services/animal/animal/animal.service";
import { ContextService } from "src/app/services/context/context.service";

@Component({
  selector: "app-animal-edit",
  templateUrl: "./animal-edit.component.html",
  styleUrls: ["./animal-edit.component.scss"],
})
export class AnimalEditComponent implements OnInit {
  newAnimal: any = {
    farm: undefined,
    sex: undefined,
    weight: undefined,
    breed: undefined,
    birthDate: undefined,
    farmGrower: undefined,
    farmArea: undefined,
    animalCharacteristic: [],
    lot: undefined,
    status: undefined,
  };

  formType: FormTypeEnum = FormTypeEnum.EDIT;

  animal: AnimalInterface;
  isFormValid: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private animalService: AnimalService,
    private snackBar: SnackbarComponent
  ) {}

  ngOnInit() {
    const animal = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = params.get("id");
        return this.animalService.getById(id);
      })
    );

    animal.subscribe((value) => {
      this.animal = value.items;
    });
  }

  setFormValid(valid: boolean) {
    this.isFormValid = valid;
  }

  setAnimal(animal: AnimalInterface) {
    this.newAnimal = animal;
  }

  saveAnimal() {
    if (typeof this.newAnimal.weight !== "string") {
      this.newAnimal.weight = null;
    }
    this.animalService
      .updateAnimal({
        ...this.newAnimal,
        farmId: Number(this.animal.farm.farmId),
      })
      .subscribe((value) => {
        this.snackBar.snackbarAlert(value.message);
        this.router.navigate(["/animal"]);
      });
  }
}
