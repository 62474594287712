import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {AnimalImportByXlsxService} from '../../../../services/animal/import-by-xlsx/animal-import-by-xlsx.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'pec-animal-import-xlsx',
  templateUrl: './animal-import-xlsx.component.html',
  styleUrls: ['./animal-import-xlsx.component.scss']
})
export class AnimalImportXlsxComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  isLinear = true;
  files: File[] = [];
  displayedColumns: string[] = ['sisbovNumber', 'status', 'manejo'];
  dataSource: any = [];
  dataSourceNaoImportados: any = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private animalImportByXlsxService: AnimalImportByXlsxService
  ) {
  }

  ngOnInit() {
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('animals', this.files[0]);
    this.animalImportByXlsxService
      .import(this.files[0]).subscribe(value => {
      const naoImportados: any[] = [];
      const importados: any[] = [];
      // tslint:disable-next-line:no-shadowed-variable
      value.map((value: { status: string; }) => {
        if (value.status === '3') {
          naoImportados.push(value);
        } else {
          importados.push(value);
        }
      });
      this.dataSource = importados;
      this.dataSourceNaoImportados = naoImportados;
      this.stepper.next();
    }, error => {
    });
  }


  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async finalizar() {
    await this.router.navigate(['../'], {relativeTo: this.route});
  }

}
